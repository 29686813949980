<div class="generative-fill-wrapper">
    <div>Use the brush to fill in or replace specified areas of the image</div>
    <div class="section brush-size">
        <div class="brush-size-label">Brush</div>

        @let brushSize = brushSizeSignal();
        <studio-ui-slider
            [config]="{ min: 1, max: 100 }"
            [value]="brushSize"
            [disabled]="isRequestingInpaint()"
            (valueChange)="setGenerativeFillBrushSize($event)" />

        <ui-number-input
            unitLabel="px"
            [keyboardEmit]="true"
            [max]="100"
            [min]="1"
            [value]="brushSize"
            [disabled]="isRequestingInpaint()"
            (valueChange)="setGenerativeFillBrushSize($event)" />
    </div>

    <expandable-prompt
        (promptChange)="onPromptChange($event)"
        [disabled]="isRequestingInpaint()"
        [canExpand]="false"
        [type]="ExpandablePromptInputType.GenerativeFillPrompt" />

    <expandable-prompt
        [disabled]="isRequestingInpaint()"
        (promptChange)="onNegativePromptChange($event)" />

    <div class="section generate-actions">
        <ui-button
            id="interaction-generative-fill"
            type="primary"
            text="Generative fill"
            [disabled]="actionsDisabled()"
            [loading]="isRequestingInpaint()"
            (click)="inpaint()" />
    </div>
</div>
