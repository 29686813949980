import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { isTextDataElement } from '@creative/nodes/helpers';
import { CreativeMode } from '@domain/creative/environment';
import { IElement } from '@domain/creativeset';
import { IBfFeed } from '@domain/feed';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { BrandService } from '@studio/common/brand/brand.service';
import { CreativesetDataService } from '@studio/common/creativeSet/creativeset.data.service';
import { EnvironmentService } from '@studio/common/services/environment.service';
import * as UserSettingsActions from '@studio/common/user-settings/user-settings.actions';
import { UserSettingsService } from '@studio/common/user-settings/user-settings.service';
import { CreativeToDirtyCharacterStyling } from '@studio/domain/components/translation-page';
import { filter, map, merge, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as AnimationControlActions from '../../../shared/animation-control/state/animation-control.actions';
import { FeedService } from '../../../shared/components/feeds/feed.service';
import { CreativesService } from '../../../shared/creatives/state/creatives.service';
import { FiltersService } from '../../../shared/filters/state/filters.service';
import { VersionsActions } from '../../../shared/versions/state/versions.actions';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { getTranslationPageTitle } from '../../page-title-util';
import { FeedStoreSingleton } from '../singleton/feed-store-singleton';
import { TranslationPageService } from '../state/translation-page.service';
import { getFeedIdsFromVersions } from '../utils/feed.utils';
import {
    getEditableElements,
    getUsedColors,
    groupElements,
    mergeCreativesWithDirtyCharacterStyles,
    mergeDirtyPropertiesWithVersions
} from '../utils/tp.utils';
import * as TranslationPageActions from './translation-page.actions';

@Injectable()
export class TranslationPageEffects {
    private actions$ = inject(Actions);
    private brandService = inject(BrandService);
    private creativeService = inject(CreativesService);
    private creativesetDataService = inject(CreativesetDataService);
    private environmentService = inject(EnvironmentService);
    private feedService = inject(FeedService);
    private filtersService = inject(FiltersService);
    private titleService = inject(Title);
    private translationPageService = inject(TranslationPageService);
    private userSettingsService = inject(UserSettingsService);
    private versionsService = inject(VersionsService);

    isTranslationPageActive$ = this.actions$.pipe(
        ofType(TranslationPageActions.loadTranslationPage, TranslationPageActions.exitTranslationPage),
        map(action => action.type === TranslationPageActions.loadTranslationPage.type)
    );

    loadTranslationPages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TranslationPageActions.initializeFeedStoreSingleton),
            concatLatestFrom(() => this.filtersService.sizes$),
            map(([_, sizes]) => this.getEditableElements(sizes)),
            concatLatestFrom(() => [
                this.versionsService.sortedSelectedVersions$,
                this.versionsService.defaultVersion$,
                this.userSettingsService.translationPageShowAllElements$
            ]),
            map(([editableElements, sortedSelectedVersions, defaultVersion, showAllElements]) => ({
                elements: editableElements,
                groupedElements: groupElements(
                    editableElements,
                    sortedSelectedVersions,
                    defaultVersion
                ),
                showAllElements
            })),
            map(({ elements, groupedElements, showAllElements }) =>
                TranslationPageActions.loadTranslationPageSuccess({
                    elements,
                    groups: groupedElements,
                    showAllElements
                })
            )
        );
    });

    initializeFeedStoreSingleton$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TranslationPageActions.loadTranslationPage),
            concatLatestFrom(() => [
                this.brandService.brandId$,
                this.versionsService.versions$,
                this.environmentService.inShowcaseMode$
            ]),
            switchMap(async ([_, brandId, versions, inShowcaseMode]) => {
                if (inShowcaseMode) {
                    // Do not load feeds when in ShowcaseMode
                    return of([]);
                }
                FeedStoreSingleton.initialize(brandId, {
                    ...this.environmentService.env,
                    MODE: CreativeMode.TranslationPanel
                });

                const feedIdsSet = getFeedIdsFromVersions(versions);
                const feedIds = Array.from(feedIdsSet);
                if (!feedIds.length) {
                    return;
                }
                // Loads feed data
                await FeedStoreSingleton.getInstance().preloadFeeds(feedIds);
                // Loads feed metadata
                const bfFeeds = await Promise.all(
                    feedIds.map(feedId => this.feedService.getNestedFeed(feedId))
                );
                const bfFeedsFiltered = bfFeeds.filter(Boolean) as IBfFeed[];
                this.translationPageService.setFeeds(bfFeedsFiltered);
            }),
            switchMap(_ => {
                return of(TranslationPageActions.initializeFeedStoreSingleton());
            })
        );
    });

    updatePageTitle$ = createEffect(
        () => {
            return merge(
                this.versionsService.selectedVersions$,
                this.actions$.pipe(ofType(TranslationPageActions.loadTranslationPageSuccess))
            ).pipe(
                filter(() => this.environmentService.isPage('TP')),
                concatLatestFrom(() => this.versionsService.selectedVersions$),
                tap(([_, selectedVersions]) => {
                    const creativesetName = this.creativesetDataService.creativeset?.name;
                    if (!creativesetName) {
                        return;
                    }
                    const versionName =
                        selectedVersions.length === 1 ? selectedVersions[0].name : undefined;
                    this.titleService.setTitle(getTranslationPageTitle(versionName, creativesetName));
                })
            );
        },
        { dispatch: false }
    );

    updateElementsOnFilterChange$ = createEffect(() => {
        return merge(this.filtersService.sizes$, this.versionsService.sortedSelectedVersions$).pipe(
            withLatestFrom(this.isTranslationPageActive$),
            filter(([, isTranslationPageActive]) => isTranslationPageActive),
            concatLatestFrom(() => [
                this.filtersService.sizes$,
                this.versionsService.sortedSelectedVersions$,
                this.versionsService.defaultVersion$
            ]),
            map(([_, sizes, sortedSelectedVersions, defaultVersion]) => ({
                editableElements: this.getEditableElements(sizes),
                sortedSelectedVersions,
                defaultVersion
            })),
            map(({ editableElements, sortedSelectedVersions, defaultVersion }) => ({
                elements: editableElements,
                groupedElements: groupElements(editableElements, sortedSelectedVersions, defaultVersion)
            })),
            map(({ elements, groupedElements }) =>
                TranslationPageActions.updateElementsOnFilterChangeSuccess({
                    elements,
                    groups: groupedElements
                })
            )
        );
    });

    updateVersionsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.updateVersionsSuccess),
            map(() => {
                return TranslationPageActions.saveDirtyVersionsSuccess();
            })
        );
    });

    updateVersionsFailure = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.updateVersionsFailure),
            map(({ error }) => {
                return TranslationPageActions.saveDirtyVersionsFailure({ error });
            })
        );
    });

    pushChangePreSaving = createEffect(() => {
        return this.actions$.pipe(
            ofType(TranslationPageActions.saveDirtyVersions),
            concatLatestFrom(() => this.versionsService.versions$),
            switchMap(([_, versions]) => {
                return of(TranslationPageActions.changeHistoryPreSave({ versions }));
            })
        );
    });

    saveTranslationPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TranslationPageActions.saveDirtyVersions),
            concatLatestFrom(() => [
                this.versionsService.versions$,
                this.translationPageService.dirtyProperties$,
                this.translationPageService.dirtyCharacterStyling$,
                this.creativeService.filteredCreatives$
            ]),
            map(([_, versions, dirtyProperties, dirtyCharacterStyling, creatives]) => {
                try {
                    const dirtyVersions = mergeDirtyPropertiesWithVersions(versions, dirtyProperties);
                    if (!dirtyVersions.length) {
                        throw new Error('No versions to save');
                    }
                    if (Object.keys(dirtyCharacterStyling).length) {
                        const dirtyCreatives = mergeCreativesWithDirtyCharacterStyles(
                            creatives,
                            dirtyCharacterStyling
                        );
                        return VersionsActions.updateVersionsAndCreatives({
                            versions: dirtyVersions,
                            creatives: dirtyCreatives
                        });
                    }
                    return VersionsActions.updateVersions({ versions: dirtyVersions });
                } catch (err: unknown) {
                    let error = new Error(err as string);
                    if (err instanceof Error) {
                        error = err;
                    }
                    return VersionsActions.updateVersionsFailure({ error });
                }
            })
        );
    });

    showElement$ = createEffect(() => {
        return this.translationPageService.selectedElement$.pipe(
            filter((selectedElement): selectedElement is IElement => !!selectedElement),
            map(selectedElement => {
                return AnimationControlActions.controlActions.showElement({
                    elementId: selectedElement.id
                });
            })
        );
    });

    cancelTranslations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TranslationPageActions.cancelTranslations),
            concatLatestFrom(() => this.translationPageService.dirtyCharacterStyling$),
            tap(([_, oldCharacterStyles]) => {
                this.removeOldCharacterStyles(oldCharacterStyles);
            }),
            map(() => {
                return TranslationPageActions.cancelStyleEditing();
            })
        );
    });

    usedColors$ = createEffect(() => {
        return this.creativesetDataService.creativeset$.pipe(
            map(creativeset => {
                const colors = getUsedColors(creativeset);
                return TranslationPageActions.setUsedColors({ colors });
            })
        );
    });

    toggleShowAllElements = createEffect(() => {
        return this.actions$.pipe(
            ofType(TranslationPageActions.hideAll, TranslationPageActions.showAll),
            map(() => {
                return UserSettingsActions.toggleTranslationPageShowAllElements();
            })
        );
    });

    constructor() {
        this.versionsService.dirtyProperties$ = this.translationPageService.dirtyProperties$;
    }

    private getEditableElements(filteredSizes: string[]): IElement[] {
        const allCreatives = this.creativesetDataService.creativeset?.creatives;
        if (!allCreatives) {
            return [];
        }
        const allElements = this.creativesetDataService.creativeset?.elements ?? [];
        return getEditableElements(allElements, allCreatives, filteredSizes);
    }

    private removeOldCharacterStyles(oldChanges: CreativeToDirtyCharacterStyling): void {
        for (const creative of this.creativesetDataService.creativeset.creatives) {
            const oldDirtyCharacterStyles = oldChanges[creative.id];
            if (!creative.design || !oldDirtyCharacterStyles) {
                continue;
            }
            for (const documentElement of creative.design.document.elements) {
                if (!isTextDataElement(documentElement)) {
                    continue;
                }
                const dirtyCharacterStyleForElement = oldDirtyCharacterStyles[documentElement.id];
                if (!dirtyCharacterStyleForElement) {
                    continue;
                }
                for (const styleId of Object.keys(dirtyCharacterStyleForElement)) {
                    documentElement.characterStyles.delete(styleId);
                }
            }
        }
    }
}
