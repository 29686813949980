import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppComponent } from '../../app.component';

@Component({
    imports: [RouterOutlet],
    selector: 'creativeset-showcase-component',
    templateUrl: './creativeset.component.html'
})
export class CreativesetShowcaseComponent {
    private app = inject(AppComponent);

    constructor() {
        this.app.finishInit();
    }
}
