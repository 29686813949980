import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { sortVersions } from '@studio/common';
import { getBrandLocalizations } from '@studio/common/brand';
import { cloneDeep } from '@studio/utils/clone';
import { getSelectedVersionsIds } from '../../filters/state/filters.selectors';
import { VersionEntity } from './version.entity';
import { adapter, VERSIONS_FEATURE_KEY, VersionsState } from './versions.reducer';

export const getVersionsState = createFeatureSelector<VersionsState>(VERSIONS_FEATURE_KEY);

const { selectAll, selectEntities } = adapter.getSelectors(getVersionsState);

// default state selectors

export const getNewVersionPlaceholder = createSelector(
    getVersionsState,
    state => state.newVersionPlaceholder
);

export const getCreativesetId = createSelector(getVersionsState, state => state.creativesetId);

export const getLoaded = createSelector(getVersionsState, state => state.loaded);

export const getLoading = createSelector(getVersionsState, state => state.loading);

export const getUpdatedSuccess = createSelector(getVersionsState, state => state.updatedSuccess);

export const getError = createSelector(getVersionsState, state => state.error);

export const getDefaultVersionId = createSelector(getVersionsState, state => state.defaultVersionId);

export const getShouldCheckPristine = createSelector(
    getVersionsState,
    state => state.shouldCheckPristine
);

export const getTranslationSaved = createSelector(getVersionsState, state => state.translationSaved);

export const getNewVersionPropertiesIds = createSelector(
    getVersionsState,
    state => state.newVersionPropertiesIds
);

// combined selectors

export const getDefaultVersion = createSelector(
    selectEntities,
    getDefaultVersionId,
    (entities, defaultVersionId) => entities[defaultVersionId!]!
);

export const getDefaultVersionProperties = createSelector(
    getDefaultVersion,
    defaultVersion => defaultVersion.properties
);

export const getVersions = createSelector(selectAll, versionEntities => versionEntities);
export const getVersionIds = createSelector(getVersionsState, state => state.ids as string[]);

export const getVersionByID = (id: string): MemoizedSelector<object, VersionEntity | undefined> =>
    createSelector(getVersions, versions => versions.find(version => version.id === id));

export const getSelectableVersions = createSelector(
    getBrandLocalizations,
    getVersionsState,
    getVersions,
    (localizations, state, versions) => {
        const selectableVersions = state.selectableIds
            ? versions.filter(({ id }) =>
                  state.selectableIds!.find(selectableId => selectableId === id)
              )
            : versions;
        const defaultVersionId = state.defaultVersionId || selectableVersions[0]?.id;

        return selectableVersions.sort((a, b) => sortVersions(a, b, defaultVersionId, localizations));
    }
);

export const getIsShowingAllVersions = createSelector(
    getSelectableVersions,
    getSelectedVersionsIds,
    (selectableVersions, selectedVersionIds) =>
        selectedVersionIds.length === 0 ||
        (selectableVersions.length === selectedVersionIds.length && selectedVersionIds.length !== 1)
);

export const getIsShowingMultipleVersions = createSelector(
    getSelectedVersionsIds,
    getIsShowingAllVersions,
    (selectedVersionIds, isShowingAllVersions) => isShowingAllVersions || selectedVersionIds.length > 1
);

export const getSelectedVersions = createSelector(
    selectEntities,
    getSelectedVersionsIds,
    getIsShowingAllVersions,
    getDefaultVersion,
    (versionEntities, selectedVersionIds, isShowingAllVersions, defaultVersion) => {
        if (isShowingAllVersions) {
            return Object.values(versionEntities) as VersionEntity[];
        }
        let selectedVersions = selectedVersionIds.reduce(
            (acc, id) => [...acc, cloneDeep(versionEntities[id]!)], // clone because immutability sucks
            [] as VersionEntity[]
        );
        if (selectedVersions[0] === undefined) {
            selectedVersions = [defaultVersion];
        }
        return selectedVersions.filter(selectedVersion => selectedVersion !== undefined);
    }
);
export const getSortedSelectedVersions = createSelector(
    getSelectedVersions,
    getDefaultVersionId,
    getBrandLocalizations,
    (selectedVersions, defaultVersionId, brandLocalizations) =>
        selectedVersions.sort((a, b) =>
            sortVersions(a, b, defaultVersionId ?? selectedVersions[0]?.id ?? '', brandLocalizations)
        )
);

export const getSelectedVersion = createSelector(getSelectedVersions, versions => versions[0]!);

export const getSelectedVersionProperties = createSelector(
    getSelectedVersion,
    selectedVersion => selectedVersion.properties
);
