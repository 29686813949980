import { CommonModule } from '@angular/common';
import { Component, computed, ElementRef, inject, OnDestroy, OnInit, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { ISize } from '@domain/dimension';
import { GenAIOption } from '@studio/domain/components/ai-studio.types';
import { DescriptiveLoaderType } from '@studio/domain/components/descriptive-loader.types';
import { ZoomControlService } from '../../../pages/design-view/workspace/zoom-control/zoom-control.service';
import { DescriptiveLoaderComponent } from '../../../shared/components/descriptive-loader/descriptive-loader.component';
import { HotkeyBetterService } from '../../../shared/services/hotkeys/hotkey.better.service';
import { GenAIService } from '../state/gen-ai.service';
import { getScaledAssetDimensions } from '../utils';
import { EraseCanvasComponent } from './erase-canvas/erase-canvas.component';
import { GenerativeFillCanvasComponent } from './generative-fill-canvas/generative-fill-canvas.component';
import { OutpaintCanvasComponent } from './outpaint-canvas/outpaint-canvas.component';
import { RemoveBackgroundCanvasComponent } from './remove-background-canvas/remove-background-canvas.component';

@Component({
    selector: 'ai-studio-canvas',
    imports: [
        CommonModule,
        UIModule,
        GenerativeFillCanvasComponent,
        EraseCanvasComponent,
        DescriptiveLoaderComponent,
        OutpaintCanvasComponent,
        RemoveBackgroundCanvasComponent
    ],
    templateUrl: './ai-studio-canvas.component.html',
    styleUrls: ['./ai-studio-canvas.component.scss'],
    providers: [ZoomControlService]
})
export class AIStudioCanvasComponent implements OnInit, OnDestroy {
    wrapperElement = viewChild<ElementRef<HTMLDivElement>>('wrapper');

    private genAIService = inject(GenAIService);
    private host = inject(ElementRef<HTMLElement>);
    private hotkeyBetterService = inject(HotkeyBetterService);
    private zoomControlService = inject(ZoomControlService);

    GenAIOption = GenAIOption;
    readonly loaderType = DescriptiveLoaderType.AIStudioGen;

    asset = toSignal(this.genAIService.currentImageAsset$);
    currentImage = toSignal(this.genAIService.currentImage$);
    currentRequestAnything = toSignal(this.genAIService.isRequestingAnything$);
    currentSelectedOption = toSignal(this.genAIService.currentOptionSelected$);
    private currentImageSize = toSignal(this.genAIService.currentImageSize$);
    size = computed(() => this.computeSize());

    private zoomLevel = toSignal(this.genAIService.zoomLevel$);
    scale = computed(() => (this.zoomLevel() ?? 100) / 100);

    ngOnInit(): void {
        this.addEventListeners();
    }

    ngOnDestroy(): void {
        this.removeEventListeners();
    }

    private addEventListeners(): void {
        this.host.nativeElement.addEventListener('wheel', this.scroll, {
            capture: true,
            passive: false
        });
        this.hotkeyBetterService.on('ZoomIn', this.zoomIn);
        this.hotkeyBetterService.on('ZoomOut', this.zoomOut);
    }

    private removeEventListeners(): void {
        this.host.nativeElement.removeEventListener('wheel', this.scroll);
        this.hotkeyBetterService.off('ZoomIn', this.zoomIn);
        this.hotkeyBetterService.off('ZoomOut', this.zoomOut);
    }

    private computeSize(): ISize {
        const currentImageSize = this.currentImageSize();
        const scaledSize = getScaledAssetDimensions(currentImageSize);
        return scaledSize;
    }

    private scroll = (event: WheelEvent): void => {
        const wrapperElement = this.wrapperElement()?.nativeElement;

        if (!wrapperElement || !(event.ctrlKey || event.metaKey)) {
            return;
        }
        event.preventDefault();
        const mousePosition = {
            x: event.clientX,
            y: event.clientY
        };
        const boundingBox = wrapperElement.getBoundingClientRect();
        const canvasPosition = {
            x: boundingBox.x,
            y: boundingBox.y
        };
        const canvasSize = {
            width: wrapperElement.clientWidth,
            height: wrapperElement.clientHeight
        };
        const canvasPositionAndSize = { ...canvasPosition, ...canvasSize };

        const currentZoom = this.scale();
        const zoomAndPosition = this.zoomControlService.calculateZoomToMousePosition(
            event,
            mousePosition,
            canvasPositionAndSize,
            currentZoom
        );
        const newZoomLevel = zoomAndPosition.zoom * 100;

        this.genAIService.setZoom(newZoomLevel);
    };

    private zoomIn = (): void => {
        this.genAIService.zoomIn();
    };

    private zoomOut = (): void => {
        this.genAIService.zoomOut();
    };
}
