// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { userAgent, vendor } from './navigator';

// https://stackoverflow.com/a/9851769/5161346
// AVOID BROWSER DETECTION as much as possible. Use feature detection instead.
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#avoiding_user_agent_detection

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Any apple browser including safari, wkWebview, uiWebView.
export const isAppleBrowser = vendor.indexOf('Apple') > -1;

// Chrome
export const isChrome = userAgent.includes('chrome') || userAgent.includes('crios');

// Safari
export const isSafari =
    isAppleBrowser && userAgent.indexOf('crios') === -1 && userAgent.indexOf('fxios') === -1;

// Internet Explorer 6-11
export const isIE = !!document.documentMode;

// Edge 15-18
export const isEdge = !isIE && !!window.StyleMedia;

export const isIpad =
    userAgent.match(/(iPad)/i) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); /* iPad OS 13 */

/**
 * Extract iOS version from User agent.
 * Note that this won't find iPads with version 13 and above
 * because Apple are idiots
 */
export const preciseIOSVersion = (function (): {
    simple: number;
    full: { patch: number; major: number; minor: number };
} {
    const defaultResult = { simple: 0, full: { major: 0, minor: 0, patch: 0 } };

    try {
        if (!(userAgent.includes('iphone') || userAgent.includes('ipad'))) {
            return defaultResult;
        }

        const match = userAgent.match(/os (\d+[._]\d+[._]?\d*)/);
        if (!match) {
            return defaultResult;
        }

        // Get the version string and clean it up
        const versionString = match[1].replace(/_/g, '.');
        const versions = versionString.split('.').map(v => parseInt(v, 10));

        // Ensure we have valid numbers
        if (versions.some(isNaN)) {
            return defaultResult;
        }

        // Calculate simple version (e.g., 14.2.0 becomes 14.2)
        const simple = versions.length >= 2 ? parseFloat(`${versions[0]}.${versions[1]}`) : versions[0];

        return {
            simple,
            full: {
                major: versions[0] || 0,
                minor: versions[1] || 0,
                patch: versions[2] || 0
            }
        };
    } catch (e) {
        console.warn('Could not get iOS version:', e);
        return defaultResult;
    }
})();

/** Note probably not accurate but need it for version
 * @deprecated - this is not accurate at all
 */
export function getBrowserVersion(): number {
    const ua = userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return parseFloat(tem[1]) || -1;
    }
    if (M[1] === 'chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/i);
        if (tem != null) {
            return parseFloat(tem[2]) || -1;
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return parseFloat(M[1]) || -1;
}

export function isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|CriOS|SamsungBrowser|IEMobile|Opera Mini/i.test(
        userAgent
    );
}
