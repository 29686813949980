import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig, isProductionEnv } from './app/app.config';

parseURLParams();

let hasInitiated = false;

function bootstrap(): void {
    hasInitiated = true;

    if (isProductionEnv) {
        enableProdMode();
    }

    bootstrapApplication(AppComponent, appConfig).catch(err =>
        // eslint-disable-next-line no-console
        window.onerror ? window.onerror(err) : console.error(err)
    );
}

const handleVisibilityChange = (): void => {
    if (!document.hidden && !hasInitiated) {
        bootstrap();
        document.removeEventListener('visibilitychange', handleVisibilityChange, false);
    }
};

// bootstrap page when document is not hidden in order to boost performance when user cmd + click open new tabs
if (!document.hidden && !hasInitiated) {
    bootstrap();
} else {
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
}

function parseURLParams(): void {
    const url = new URL(window.location.href);
    // setting page title to title if search params for title is provided in cmd + click open new tabs
    const title = url.searchParams.get('title');
    document.title = title || 'Studio';
    window.app = 'bannerflow';
}
