<div class="sketch-wrapper">
    <div>
        Use a sketch to generate a refined output. Use non-sketch to leveraging the contour lines and
        edges within the image
    </div>
    <expandable-prompt
        (promptChange)="onPromptChange($event)"
        [disabled]="isRequestingSketch()"
        [canExpand]="false"
        [type]="ExpandablePromptInputType.SketchPrompt" />

    <expandable-prompt
        [disabled]="isRequestingSketch()"
        (promptChange)="onNegativePromptChange($event)" />

    <div class="slider-text">
        <div>AI Control Strength:</div>
        <ui-icon
            class="help-icon"
            icon="help"
            uiTooltipPosition="bottom"
            uiTooltip="How much influence, or control, the image has on the generation" />
    </div>
    <div class="slider-control">
        <studio-ui-slider
            [config]="{ min: 0, max: 10 }"
            [value]="controlStrength || 0"
            (valueChange)="adjustControlStrength($event)">
        </studio-ui-slider>

        <ui-number-input
            type="number"
            [value]="controlStrength"
            (valueChange)="adjustControlStrength($event)" />
    </div>

    <div class="section generate-actions">
        <ui-button
            id="interaction-generate-from-sketch"
            type="primary"
            text="Generate from sketch"
            [disabled]="actionsDisabled()"
            [loading]="isRequestingSketch()"
            (click)="sketch()" />
    </div>
</div>
