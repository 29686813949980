import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IBfFeed } from '@domain/feed';
import { CreativesetDataService } from '@studio/common';
import { EnvironmentService } from '@studio/common/services/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeedService {
    private environmentService = inject(EnvironmentService);
    private feeds: IBfFeed[];
    timestamp: number;
    constructor(
        private http: HttpClient,
        private creativesetDataService: CreativesetDataService
    ) {}

    async getFeeds(): Promise<IBfFeed[]> {
        // Cache feeds for 2 minutes
        if (this.feeds && Date.now() - this.timestamp < 1000 * 60 * 2) {
            return this.feeds;
        }
        const { sapi } = this.environmentService.origins;
        this.feeds = await lastValueFrom(
            this.http.get<IBfFeed[]>(`${sapi}/feeds/${this.creativesetDataService.brand.id}`)
        );
        this.timestamp = Date.now();
        return this.feeds;
    }

    async getNestedFeed(id: string, feeds?: IBfFeed[]): Promise<IBfFeed | undefined> {
        if (!feeds) {
            feeds = await this.getFeeds();
        }

        for (const feed of feeds) {
            if (feed.id === id) {
                return feed;
            }
            if (feed.children) {
                const feedItem = await this.getNestedFeed(id, feed.children);
                if (feedItem) {
                    return feedItem;
                }
            }
        }
    }

    openFeed(id: string): void {
        const accountSlug = this.creativesetDataService.getAccountSlug();
        const brandSlug = this.creativesetDataService.getBrandSlug();
        const { b2 } = this.environmentService.origins;
        const url = `${b2}/${accountSlug}/${brandSlug}/Feeds/c/${id}`;

        window.open(url, '_blank');
    }
}
