import {
    ElementOverrideArrayProperties,
    ElementOverrideDtoKeys,
    ElementOverrideDtoValues,
    OneOfCustomPropertyDtos,
    OneOfCustomPropertyOverrideDtos,
    OneOfElementDtos,
    OneOfElementOverrideDtos,
    OneOfVisualElementDtos
} from '@domain/api/design-api.interop';
import {
    CreativeDto,
    CreativeSetDto,
    CustomPropertyFeedDto,
    CustomPropertyFeedOverrideDto,
    CustomPropertyTextDto,
    CustomPropertyTextOverrideDto,
    EllipseElementDto,
    GroupNodeDto,
    GroupNodeOverrideDto,
    ImageElementDto,
    RectangleElementDto,
    TextLikeElementDto,
    VideoElementDto,
    WidgetElementDto,
    WidgetElementOverrideDto
} from '@domain/api/generated/design-api';
import { ElementKind } from '@domain/elements';
import { OneOfDapiMaskableNodeDtos } from '@domain/mask';
import { RequiredKeys } from '@domain/utils/types';
import { cloneDeep } from '@studio/utils/clone';
import { omit } from '@studio/utils/utils';
import {
    CompileElementOptions,
    InferredCustomPropertyOverride,
    InferredElementOverride,
    OverrideOptions,
    RemovablePropertyValues,
    RemovedProperty
} from './design-api.types';

const ignoreFlatOverrideProperties = ['imageAsset', 'videoAsset', 'widgetAsset'];

export function getPoolElement<ElementDto extends OneOfElementDtos>(
    creativeSetDto: CreativeSetDto,
    elementId: string
): ElementDto {
    const poolElement = creativeSetDto.elementsPool.find(({ id }) => id === elementId);

    if (!poolElement) {
        throw new Error(`Element with id ${elementId} does not exist in Creativeset.ElementPool.`);
    }

    return cloneDeep(poolElement as ElementDto);
}

export function compileDesignApiElements(
    creativeSetDto: CreativeSetDto,
    creative: CreativeDto
): OneOfElementDtos[] {
    const size = creativeSetDto.sizes.find(({ id }) => id === creative.sizeId)!;

    const sortedElementIds = Object.keys(size.elements).sort((a, b) => {
        const sortIndexA = size.elements[a]?.sortIndex ?? 0;
        const sortIndexB = size.elements[b]?.sortIndex ?? 0;
        return sortIndexA - sortIndexB;
    });

    return sortedElementIds.map(elementId =>
        compileDesignApiElement(creativeSetDto, { elementId, creative, size })
    );
}

function compileDesignApiElement(
    creativeSetDto: CreativeSetDto,
    { elementId, creative, size }: CompileElementOptions
): OneOfElementDtos {
    const versionId = creative.versionId;
    const version = creativeSetDto.versions.find(({ id }) => id === versionId);

    if (!version) {
        throw new Error();
    }

    const poolElement = getPoolElement(creativeSetDto, elementId);

    if (isDapiWidgetNodeDto(poolElement)) {
        return getCompiledWidgetElement(poolElement, { size, creative, version });
    }

    const override = getElementOverride(poolElement, { size, creative, version });

    return mergePoolElementWithOverride(poolElement, override);
}

function convertCustomPropertyNonOverrideToOverride(
    customProperty: OneOfCustomPropertyDtos
): OneOfCustomPropertyOverrideDtos {
    switch (customProperty.$type) {
        case 'CustomPropertyBooleanDto':
            return {
                $type: 'CustomPropertyBooleanOverrideDto',
                label: customProperty.label ?? '',
                name: customProperty.name,
                value: {
                    value: customProperty.value
                }
            };
        case 'CustomPropertyColorDto':
            return {
                $type: 'CustomPropertyColorOverrideDto',
                label: customProperty.label ?? '',
                name: customProperty.name,
                value: {
                    value: customProperty.value
                }
            };
        case 'CustomPropertyFeedDto':
            return {
                $type: 'CustomPropertyFeedOverrideDto',
                legacy_VersionPropertyId: customProperty.legacy_VersionPropertyId,
                legacy_VersionPropertyName: customProperty.legacy_VersionPropertyName,
                label: customProperty.label ?? '',
                name: customProperty.name,
                value: {
                    value: customProperty.value
                }
            };
        case 'CustomPropertyFontDto':
            return {
                $type: 'CustomPropertyFontOverrideDto',
                label: customProperty.label ?? '',
                name: customProperty.name,
                value: {
                    value: customProperty.value
                }
            };
        case 'CustomPropertyImageDto':
            return {
                $type: 'CustomPropertyImageOverrideDto',
                label: customProperty.label ?? '',
                name: customProperty.name,
                value: {
                    value: customProperty.value
                }
            };
        case 'CustomPropertyNumberDto':
            return {
                $type: 'CustomPropertyNumberOverrideDto',
                label: customProperty.label ?? '',
                name: customProperty.name,
                value: {
                    value: customProperty.value
                }
            };
        case 'CustomPropertySelectDto':
            return {
                $type: 'CustomPropertySelectOverrideDto',
                label: customProperty.label ?? '',
                name: customProperty.name,
                value: customProperty.value
            };
        case 'CustomPropertyTextDto':
            return {
                $type: 'CustomPropertyTextOverrideDto',
                legacy_VersionPropertyId: customProperty.legacy_VersionPropertyId,
                legacy_VersionPropertyName: customProperty.legacy_VersionPropertyName,
                label: customProperty.label ?? '',
                name: customProperty.name,
                value: {
                    value: customProperty.value
                }
            };
        default:
            throw new Error('No matching $type');
    }
}

function convertCustomPropertyToNonOverride(
    customPropertyOverride: OneOfCustomPropertyOverrideDtos
): OneOfCustomPropertyDtos {
    switch (customPropertyOverride.$type) {
        case 'CustomPropertyBooleanOverrideDto':
            return {
                $type: 'CustomPropertyBooleanDto',
                label: customPropertyOverride.label ?? '',
                name: customPropertyOverride.name,
                value: customPropertyOverride.value?.value
            };
        case 'CustomPropertyColorOverrideDto':
            return {
                $type: 'CustomPropertyColorDto',
                label: customPropertyOverride.label ?? '',
                name: customPropertyOverride.name,
                value: customPropertyOverride.value?.value
            };
        case 'CustomPropertyFeedOverrideDto':
            return {
                $type: 'CustomPropertyFeedDto',
                label: customPropertyOverride.label ?? '',
                name: customPropertyOverride.name,
                value: customPropertyOverride.value?.value,
                legacy_VersionPropertyId: customPropertyOverride.legacy_VersionPropertyId,
                legacy_VersionPropertyName: customPropertyOverride.legacy_VersionPropertyName
            };
        case 'CustomPropertyFontOverrideDto':
            return {
                $type: 'CustomPropertyFontDto',
                label: customPropertyOverride.label ?? '',
                name: customPropertyOverride.name,
                value: customPropertyOverride.value?.value
            };
        case 'CustomPropertyImageOverrideDto':
            return {
                $type: 'CustomPropertyImageDto',
                label: customPropertyOverride.label ?? '',
                name: customPropertyOverride.name,
                value: customPropertyOverride.value?.value
            };
        case 'CustomPropertyNumberOverrideDto':
            return {
                $type: 'CustomPropertyNumberDto',
                label: customPropertyOverride.label ?? '',
                name: customPropertyOverride.name,
                value: customPropertyOverride.value?.value
            };
        case 'CustomPropertySelectOverrideDto':
            return {
                $type: 'CustomPropertySelectDto',
                label: customPropertyOverride.label ?? '',
                name: customPropertyOverride.name,
                value: customPropertyOverride.value
            };
        case 'CustomPropertyTextOverrideDto':
            return {
                $type: 'CustomPropertyTextDto',
                label: customPropertyOverride.label ?? '',
                name: customPropertyOverride.name,
                value: customPropertyOverride.value?.value,
                legacy_VersionPropertyId: customPropertyOverride.legacy_VersionPropertyId,
                legacy_VersionPropertyName: customPropertyOverride.legacy_VersionPropertyName
            };
        default:
            throw new Error('No matching $type');
    }
}

function getCompiledWidgetElement(
    poolWidgetElement: WidgetElementDto,
    { creative, size, version }: OverrideOptions
): WidgetElementDto {
    const override = getElementOverride(poolWidgetElement, { size, creative, version });

    const cloneOverride = cloneDeep(override);
    const customProperties = poolWidgetElement.customProperties.map(customProperty => {
        return getCustomPropertyOverride(customProperty, cloneOverride);
    });

    const ignoredRemovedCustomProperties = customProperties?.filter(
        customProperty =>
            !isRemovableObject(customProperty.value) ||
            (isRemovableObject(customProperty.value) && !customProperty.value.isRemoved)
    );

    const addedOverrideCustomProperties = (
        cloneOverride.customProperties?.filter(
            customProperty =>
                !isRemovableObject(customProperty.value) ||
                (isRemovableObject(customProperty.value) && !customProperty.value.isRemoved)
        ) ?? []
    )?.filter(
        overrideProperty =>
            !ignoredRemovedCustomProperties.some(
                ignoredProperty => ignoredProperty.name === overrideProperty.name
            )
    );

    const overriddenCustomPropertiesThatDontExistInPool = [
        ...ignoredRemovedCustomProperties,
        ...addedOverrideCustomProperties
    ].map(convertCustomPropertyToNonOverride);

    cloneOverride.customProperties = [];
    poolWidgetElement.customProperties = overriddenCustomPropertiesThatDontExistInPool;

    return mergePoolElementWithOverride(poolWidgetElement, cloneOverride);
}

export function getElementOverride<Element extends OneOfElementDtos>(
    poolElement: Element,
    { creative, size, version }: OverrideOptions
): InferredElementOverride<Element> {
    const sizeElement: undefined | OneOfElementOverrideDtos = size?.elements[poolElement.id];
    const versionElement: undefined | OneOfElementOverrideDtos = version?.elements[poolElement.id];
    const creativeElement: undefined | OneOfElementOverrideDtos = creative?.elements[poolElement.id];

    // extra logic for inheritance of custom properties
    const customProperties: OneOfCustomPropertyOverrideDtos[] = [];
    if (versionElement && isDapiWidgetElementOverrideDto(versionElement)) {
        versionElement.customProperties?.forEach(customProperty => {
            customProperties.push(customProperty);
        });
    }

    if (sizeElement && isDapiWidgetElementOverrideDto(sizeElement)) {
        sizeElement.customProperties?.forEach(customProperty => {
            const existingCustomPropertyIndex = customProperties.findIndex(
                cp => cp.name === customProperty.name
            );

            if (existingCustomPropertyIndex !== -1) {
                customProperties[existingCustomPropertyIndex] = {
                    ...customProperties[existingCustomPropertyIndex],
                    ...customProperty
                } as OneOfCustomPropertyOverrideDtos;
            } else {
                customProperties.push(customProperty);
            }
        });

        if (customProperties.length) {
            sizeElement.customProperties = customProperties;
        }
    }

    if (creativeElement && isDapiWidgetElementOverrideDto(creativeElement)) {
        creativeElement.customProperties?.forEach(customProperty => {
            const existingCustomPropertyIndex = customProperties.findIndex(
                cp => cp.name === customProperty.name
            );
            if (existingCustomPropertyIndex !== -1) {
                customProperties[existingCustomPropertyIndex] = {
                    ...customProperties[existingCustomPropertyIndex],
                    ...customProperty
                } as OneOfCustomPropertyOverrideDtos;
            } else {
                customProperties.push(customProperty);
            }
        });
        if (customProperties.length) {
            creativeElement.customProperties = customProperties;
        }
    }

    const inferredElementOverride = {
        ...versionElement,
        ...sizeElement,
        ...creativeElement
    } as InferredElementOverride<Element>;
    return inferredElementOverride;
}

export function getCustomPropertyOverride<CustomProperty extends OneOfCustomPropertyDtos>(
    customProperty: CustomProperty,
    override: WidgetElementOverrideDto
): InferredCustomPropertyOverride<CustomProperty> {
    const propertyOverride = override.customProperties?.find(
        ({ name }) => name === customProperty.name
    );

    if (
        propertyOverride &&
        isRemovableObject(propertyOverride.value) &&
        propertyOverride.value.isRemoved
    ) {
        return {
            ...customProperty,
            $type: propertyOverride.$type,
            value: {
                isRemoved: true
            }
        } as InferredCustomPropertyOverride<CustomProperty>;
    }

    const propertyValue = propertyOverride?.value ?? customProperty.value;

    const mergeCustomProperty: CustomProperty = {
        ...customProperty,
        ...(propertyOverride ? omit(propertyOverride, '$type') : {}),
        value:
            propertyOverride && isRemovableObject(propertyOverride.value)
                ? propertyOverride?.value.value
                : propertyValue
    };

    return convertCustomPropertyNonOverrideToOverride(
        mergeCustomProperty
    ) as InferredCustomPropertyOverride<CustomProperty>;
}

export function mergePoolElementWithOverride<ElementDto extends OneOfElementDtos>(
    poolElement: ElementDto,
    override: OneOfElementOverrideDtos
): ElementDto {
    return applyFlatOverride(poolElement, override);
}

function isRemovableProperty(property: ElementOverrideDtoValues): property is RemovablePropertyValues {
    if (typeof property === 'object' && 'isRemoved' in property) {
        return true;
    }

    return false;
}

function isRemovedProperty(override: object): override is RemovedProperty {
    if (isRemovableProperty(override)) {
        return !!override.isRemoved;
    }

    return false;
}

function applyFlatOverride<ElementDto extends OneOfElementDtos>(
    element: ElementDto,
    override: OneOfElementOverrideDtos
): ElementDto {
    for (const field in override) {
        const key = field as ElementOverrideDtoKeys;

        if (key === '$type') {
            continue;
        }

        if (isArrayProperty(override, key)) {
            if (isDapiWidgetNodeDto(element)) {
                if ((key as keyof WidgetElementDto) === 'customProperties') {
                    continue;
                }
                element[key] = flatRemovableArrayOverride(override[key]);
                continue;
            }

            element[key] = flatRemovableArrayOverride(override[key]);
            continue;
        }

        if (
            typeof override[key] === 'object' &&
            !isRemovableObject(override[key] as OneOfElementOverrideDtos)
        ) {
            element[key] = applyFlatOverride(
                element[key] || {},
                override[key] as OneOfElementOverrideDtos
            );
            continue;
        }

        if (isRemovedProperty(override[key])) {
            element[key] = undefined;
            continue;
        }

        const value = isRemovableProperty(override[key]) ? override[key].value : override[key];
        if (value === undefined) {
            continue;
        }

        if (typeof value === 'object' && !ignoreFlatOverrideProperties.includes(field)) {
            element[key] = applyFlatOverride(element[key] || {}, value);
        } else {
            element[key] = value;
        }
    }

    return element;
}

function isArrayProperty(
    override: OneOfElementOverrideDtos,
    propertyKey: ElementOverrideDtoKeys
): propertyKey is ElementOverrideArrayProperties {
    switch (propertyKey as ElementOverrideArrayProperties) {
        case 'states':
        case 'animations':
        case 'actions':
        case 'shadows':
        case 'customProperties':
        case 'textSegments':
        case 'textShadows':
            return Array.isArray(override[propertyKey]);

        default:
            return false;
    }
}

function isRemovableObject(object: unknown): object is RemovablePropertyValues {
    if (object && typeof object === 'object' && 'isRemoved' in object) {
        return true;
    }

    return false;
}

function flatRemovableObjectOverride(override: object): object {
    for (const overrideKey in override) {
        const overrideValue = override[overrideKey];
        if (Array.isArray(overrideValue)) {
            override[overrideKey] = flatRemovableArrayOverride(overrideValue);
            continue;
        }

        if (typeof overrideValue === 'object' && !isRemovableObject(overrideValue)) {
            override[overrideKey] = flatRemovableObjectOverride(overrideValue);
            continue;
        }

        if (isRemovedProperty(override[overrideKey])) {
            override[overrideKey] = undefined;
            continue;
        }

        if (isRemovableProperty(overrideValue)) {
            override[overrideKey] = overrideValue.value;
        }
    }

    return override;
}

function flatRemovableArrayOverride(overrideArray: object[]): object[] {
    return overrideArray.map(override => {
        if (typeof override === 'object' && !isRemovableObject(override)) {
            return flatRemovableObjectOverride(override);
        }

        return isRemovedProperty(override) ? override.value : override;
    });
}

export function getKindFromDesignApiElement(
    element: OneOfElementDtos
): Exclude<
    ElementKind,
    ElementKind.BannerflowLibraryWidget | ElementKind.BannerflowLibraryWidgetInstance
> {
    switch (element.$type) {
        case 'WidgetElementDto':
            return ElementKind.Widget;

        case 'TextLikeElementDto':
            return element.isButton ? ElementKind.Button : ElementKind.Text;

        case 'EllipseElementDto':
            return ElementKind.Ellipse;

        case 'GroupNodeDto':
            return ElementKind.Group;

        case 'RectangleElementDto':
            return ElementKind.Rectangle;

        case 'ImageElementDto':
            return ElementKind.Image;

        case 'VideoElementDto':
            return ElementKind.Video;
    }
}

function isDapiNodeDtoType(node: OneOfElementDtos, type: OneOfElementDtos['$type']): boolean {
    return Boolean(node.$type === type);
}

function isDapiNodeOverrideDtoType(
    node: OneOfElementOverrideDtos,
    type: OneOfElementOverrideDtos['$type']
): boolean {
    return Boolean(node.$type === type);
}

export function isDapiImageNodeDto(node: OneOfElementDtos): node is ImageElementDto {
    return isDapiNodeDtoType(node, 'ImageElementDto');
}
export function isDapiTextLikeNodeDto(node: OneOfElementDtos): node is TextLikeElementDto {
    return isDapiNodeDtoType(node, 'TextLikeElementDto');
}
export function isDapiVideoNodeDto(node: OneOfElementDtos): node is VideoElementDto {
    return isDapiNodeDtoType(node, 'VideoElementDto');
}
export function isDapiRectangleNodeDto(node: OneOfElementDtos): node is RectangleElementDto {
    return isDapiNodeDtoType(node, 'RectangleElementDto');
}
export function isDapiEllipseNodeDto(node: OneOfElementDtos): node is EllipseElementDto {
    return isDapiNodeDtoType(node, 'EllipseElementDto');
}
export function isDapiWidgetNodeDto(node: OneOfElementDtos): node is WidgetElementDto {
    return isDapiNodeDtoType(node, 'WidgetElementDto');
}
export function isDapiWidgetWithAssetNodeDto(
    node: OneOfElementDtos
): node is RequiredKeys<WidgetElementDto, 'widgetAsset'> {
    return isDapiWidgetNodeDto(node) && !!node.widgetAsset;
}
export function isDapiGroupNodeDto(node: OneOfElementDtos): node is GroupNodeDto {
    return isDapiNodeDtoType(node, 'GroupNodeDto');
}

export function isDapiGroupNodeOverrideDto(
    node: OneOfElementOverrideDtos
): node is GroupNodeOverrideDto {
    return isDapiNodeOverrideDtoType(node, 'GroupNodeOverrideDto');
}

export function isDapiWidgetElementOverrideDto(
    node: OneOfElementOverrideDtos
): node is WidgetElementOverrideDto {
    return isDapiNodeOverrideDtoType(node, 'WidgetElementOverrideDto');
}

function isCustomPropertyDtoType(
    customProperty: OneOfCustomPropertyDtos,
    type: OneOfCustomPropertyDtos['$type']
): boolean {
    return Boolean(customProperty.$type === type);
}
function isCustomPropertyOverrideDtoType(
    customProperty: OneOfCustomPropertyOverrideDtos,
    type: OneOfCustomPropertyOverrideDtos['$type']
): boolean {
    return Boolean(customProperty.$type === type);
}
export function isCustomTextPropertyOverrideDto(
    customProperty: OneOfCustomPropertyOverrideDtos
): customProperty is CustomPropertyTextOverrideDto {
    return isCustomPropertyOverrideDtoType(customProperty, 'CustomPropertyTextOverrideDto');
}
export function isCustomFeedPropertyOverrideDto(
    customProperty: OneOfCustomPropertyOverrideDtos
): customProperty is CustomPropertyFeedOverrideDto {
    return isCustomPropertyOverrideDtoType(customProperty, 'CustomPropertyFeedOverrideDto');
}
export function isCustomTextPropertyDto(
    customProperty: OneOfCustomPropertyDtos
): customProperty is CustomPropertyTextDto {
    return isCustomPropertyDtoType(customProperty, 'CustomPropertyTextDto');
}
export function isCustomFeedPropertyDto(
    customProperty: OneOfCustomPropertyDtos
): customProperty is CustomPropertyFeedDto {
    return isCustomPropertyDtoType(customProperty, 'CustomPropertyFeedDto');
}

export function isVersionableCustomPropertyDto(
    customProperty: OneOfCustomPropertyDtos
): customProperty is CustomPropertyTextDto | CustomPropertyFeedDto {
    return isCustomTextPropertyDto(customProperty) || isCustomFeedPropertyDto(customProperty);
}

export function isMaskingSupportedDto(node: OneOfVisualElementDtos): node is OneOfDapiMaskableNodeDtos {
    return (
        isDapiRectangleNodeDto(node) ||
        isDapiEllipseNodeDto(node) ||
        isDapiImageNodeDto(node) ||
        isDapiVideoNodeDto(node)
    );
}
