import { ComponentType } from '@angular/cdk/portal';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import { Component, computed, inject, signal, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UIInputComponent, UIModule } from '@bannerflow/ui';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';
import { GenAIOption } from '@studio/domain/components/ai-studio.types';
import { BrandLibraryElementEditService } from '../../../pages/design-view/media-library/brandlibrary-element-edit.service';
import { StudioUISectionComponent } from '../../components/section/studio-ui-section/studio-ui-section.component';
import { BrandLibraryDataService } from '../../media-library/brand-library.data.service';
import { AIStudioService } from '../ai-studio.service';
import { GenAIService } from '../state/gen-ai.service';
import { AIStudioActionsComponent } from './ai-studio-actions/ai-studio-actions.component';
import { EraseOptionsComponent } from './erase-options/erase-options.component';
import { GenerativeFillOptionsComponent } from './generative-fill-options/generative-fill-options.component';
import { OutpaintOptionsComponent } from './outpaint-options/outpaint-options.component';
import { RemoveBackgroundOptionsComponent } from './remove-background-options/remove-background-options.component';
import { SearchAndReplaceOptionsComponent } from './search-and-replace-options/search-and-replace-options.component';
import { SketchOptionsComponent } from './sketch-options/sketch-options.component';
import { StructureOptionsComponent } from './structure-options/structure-options.component';
import { StyleOptionsComponent } from './style-options/style-options.component';
import { UpscaleOptionsComponent } from './upscale-options/upscale-options.component';

interface Section {
    option: GenAIOption;
    label: string;
    component: ComponentType<unknown>;
}

@Component({
    imports: [
        AIStudioActionsComponent,
        CommonModule,
        NgComponentOutlet,
        StudioUISectionComponent,
        UIModule
    ],
    selector: 'ai-studio-sidebar',
    templateUrl: './ai-studio-sidebar.component.html',
    styleUrls: ['./ai-studio-sidebar.component.scss']
})
export class AiStudioSidebarComponent {
    private aiStudioService = inject(AIStudioService);
    private brandLibraryDataService = inject(BrandLibraryDataService);
    private brandLibraryElementEditService = inject(BrandLibraryElementEditService);
    private genAIService = inject(GenAIService);

    nameInput = viewChild.required<UIInputComponent>('name');

    GenAiOption = GenAIOption;

    assetName = toSignal(this.genAIService.assetName$, { initialValue: '' });
    currentOptionSelected = toSignal(this.genAIService.currentOptionSelected$);
    isRequestingAnything = toSignal(this.genAIService.isRequestingAnything$, { initialValue: false });
    nameValidation = new UntypedFormControl('', [Validators.required]);
    validName = signal(true);

    featureSupport = computed(() => this.computeFeatureSupport());
    selectedOption = computed(() => this.computeSelectedOption());

    sections: Section[] = [
        {
            option: GenAIOption.GenerativeFill,
            label: 'Generative fill',
            component: GenerativeFillOptionsComponent
        },
        { option: GenAIOption.Erase, label: 'Erase', component: EraseOptionsComponent },
        {
            option: GenAIOption.SearchAndReplace,
            label: 'Search and replace',
            component: SearchAndReplaceOptionsComponent
        },
        { option: GenAIOption.Outpaint, label: 'Expand image', component: OutpaintOptionsComponent },
        {
            option: GenAIOption.RemoveBackground,
            label: 'Remove background',
            component: RemoveBackgroundOptionsComponent
        },
        { option: GenAIOption.Sketch, label: 'Sketch', component: SketchOptionsComponent },
        {
            option: GenAIOption.Structure,
            label: 'Structure',
            component: StructureOptionsComponent
        },
        { option: GenAIOption.Style, label: 'Style', component: StyleOptionsComponent },
        { option: GenAIOption.Upscale, label: 'Upscale', component: UpscaleOptionsComponent }
    ];

    private elementId = toSignal(this.genAIService.openedElementId$);
    private imageSize = toSignal(this.genAIService.currentImageSize$);

    onNameChange(newName: string): void {
        this.nameValidation.setValue(newName);
        this.nameValidation.markAsTouched();

        const elementId = this.elementId();
        const brandLibraryElement = this.brandLibraryDataService.getElementById(elementId);

        const result = this.brandLibraryElementEditService.validateName({
            generateName: false,
            inputComponent: this.nameInput(),
            nameValidation: this.nameValidation,
            brandLibraryElement
        });
        const isValidName = result !== 'invalid' && result !== 'duplicate';

        this.validName.set(isValidName);
        if (isValidName) {
            this.genAIService.updateAssetName(newName);
        }
    }

    handleOptionSelect(option: GenAIOption): void {
        const support = this.featureSupport();
        if (support[option]) {
            this.genAIService.selectOption(option);
        }
    }

    getHeadlineIcon(option: GenAIOption): Icon | undefined {
        return this.featureSupport()[option] ? undefined : 'warning';
    }

    private computeFeatureSupport(): Record<GenAIOption, boolean> {
        const imageSize = this.imageSize();

        if (!imageSize) {
            return {
                [GenAIOption.GenerativeFill]: false,
                [GenAIOption.Erase]: false,
                [GenAIOption.SearchAndReplace]: false,
                [GenAIOption.Outpaint]: false,
                [GenAIOption.RemoveBackground]: false,
                [GenAIOption.Sketch]: false,
                [GenAIOption.Structure]: false,
                [GenAIOption.Style]: false,
                [GenAIOption.Upscale]: false
            };
        }

        const isSupported = (option: GenAIOption): boolean =>
            this.aiStudioService.isFeatureSupported(imageSize, option);

        return {
            [GenAIOption.GenerativeFill]: isSupported(GenAIOption.GenerativeFill),
            [GenAIOption.Erase]: isSupported(GenAIOption.Erase),
            [GenAIOption.SearchAndReplace]: isSupported(GenAIOption.SearchAndReplace),
            [GenAIOption.Outpaint]: isSupported(GenAIOption.Outpaint),
            [GenAIOption.RemoveBackground]: isSupported(GenAIOption.RemoveBackground),
            [GenAIOption.Sketch]: isSupported(GenAIOption.Sketch),
            [GenAIOption.Structure]: isSupported(GenAIOption.Structure),
            [GenAIOption.Style]: isSupported(GenAIOption.Style),
            [GenAIOption.Upscale]: isSupported(GenAIOption.Upscale)
        };
    }

    private computeSelectedOption(): GenAIOption | undefined {
        const supportedFeatures = this.featureSupport();
        const current = this.currentOptionSelected();

        if (current && supportedFeatures[current]) {
            return current;
        }

        for (const key in supportedFeatures) {
            if (supportedFeatures[key]) {
                return key as GenAIOption;
            }
        }

        return undefined;
    }
}
