import { CreativeSetDto } from '@domain/api/generated/design-api';
import { CreativeSetDtoV2 } from '@domain/api/generated/sapi';
import { CreativeSize, ICreativeset, IDesign } from '@domain/creativeset';
import { deserializeCreativesetFontFamilyDto } from '../creativeset';
import {
    convertPoolElementToElement,
    convertVersion,
    getConvertedCreativesAndAssets
} from './sapi-conversion-helpers';

export function isDapiCreativeset(
    creativeSetDto: CreativeSetDto | CreativeSetDtoV2
): creativeSetDto is CreativeSetDto {
    return 'elementsPool' in creativeSetDto;
}

export function convertDesignApiCreativesetToOldModel(creativeSetDto: CreativeSetDto): ICreativeset {
    const elements = creativeSetDto.elementsPool.map(convertPoolElementToElement);
    const versions = creativeSetDto.versions.map(version =>
        convertVersion(version, elements, creativeSetDto)
    );

    const creativeSizes: CreativeSize[] = creativeSetDto.sizes.map(({ id, width, height, name }) => ({
        id: `${id}`,
        width,
        height,
        name
    }));

    // we need to process creatives for default version first
    // to populate design elements with correct values
    creativeSetDto.creatives.sort((a, b) =>
        sortCreativesWithDefaultVersionFirst(a.versionId, b.versionId, creativeSetDto.defaultVersionId)
    );

    const designs: IDesign[] = [];
    const { creatives, imageAssets, videoAssets, widgetAssets } = getConvertedCreativesAndAssets(
        creativeSetDto,
        versions,
        elements,
        designs,
        creativeSizes
    );

    const defaultVersion = versions.find(({ id }) => id === `${creativeSetDto.defaultVersionId}`);

    if (!defaultVersion) {
        throw new Error(`Could not find default version.`);
    }
    const fonts = deserializeCreativesetFontFamilyDto(creativeSetDto.fonts);

    return {
        id: `${creativeSetDto.id}`,
        brandId: creativeSetDto.brandId,
        name: creativeSetDto.name,
        stateId: creativeSetDto.stateId,
        elements,
        designs,
        sizes: creativeSizes,
        images: imageAssets,
        videos: videoAssets,
        widgets: widgetAssets,
        creatives,
        versions,
        defaultVersion,
        fonts
    };
}
function sortCreativesWithDefaultVersionFirst(a: number, b: number, defaultVersionId: number): number {
    if (a === b) {
        return 0;
    }
    if (a === defaultVersionId) {
        return -1;
    }
    return 1;
}
