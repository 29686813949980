import { Injectable, inject } from '@angular/core';
import { CreativesetDataService } from '@studio/common/creativeSet/creativeset.data.service';
import { EnvironmentService } from '@studio/common/services/environment.service';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private creativesetDataService = inject(CreativesetDataService);
    private environmentService = inject(EnvironmentService);

    openCampaign(campaignId: string): void {
        const url = `${this.getDisplayCampaignOrigin()}/campaign/${campaignId}/list`;
        window.open(url, '_blank');
    }

    openCreateCampaign(): void {
        const creativesetId = this.creativesetDataService.creativeset.id;
        const url = `${this.getDisplayCampaignOrigin()}/creativeset/${creativesetId}`;
        window.open(url, '_blank');
    }

    openSocialCampaigns(): void {
        const { socialCampaignManager } = this.environmentService.origins;
        const accountSlug = this.creativesetDataService.getAccountSlug();
        const brandSlug = this.creativesetDataService.getBrandSlug();
        const creativesetId = this.creativesetDataService.creativeset.id;
        const url = `${socialCampaignManager}/${accountSlug}/${brandSlug}/creativeset/${creativesetId}`;
        window.open(url, '_blank');
    }

    openAddCreativesToCampaign(campaignId: string, creatives: string[]): void {
        const creativeSetId = this.creativesetDataService.creativeset.id;
        const cmWindow = window.open(
            `${this.getDisplayCampaignOrigin()}/campaign/${campaignId}/add-creatives`
        );
        if (cmWindow === null) {
            throw new Error('CM Window has failed to open.');
        }

        const eventListener = (event: MessageEvent<string>): void => {
            const { campaignManager } = this.environmentService.origins;
            if (event.origin === campaignManager && event.data === 'CM_LOADED') {
                cmWindow.postMessage({ creatives, creativeSetId }, campaignManager);
                window.removeEventListener('message', eventListener);
            }
        };

        window.addEventListener('message', eventListener);
    }

    private getDisplayCampaignOrigin(): string {
        const { campaignManager } = this.environmentService.origins;
        const accountSlug = this.creativesetDataService.getAccountSlug();
        const brandSlug = this.creativesetDataService.getBrandSlug();
        return `${campaignManager}/${accountSlug}/${brandSlug}`;
    }
}
