import { mergeFontFamilies } from '@creative/font-families.utils';
import { IFontFamily } from '@domain/font-families';
import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { getBrandId } from '../brand/brand.selectors';
import { FONT_FAMILIES_FEATURE_KEY, FontFamiliesState } from './font-families.reducer';

export const getFontFamiliesState = createFeatureSelector<FontFamiliesState>(FONT_FAMILIES_FEATURE_KEY);

export const getFontFamiliesLoaded = createSelector(
    getFontFamiliesState,
    (state: FontFamiliesState) => state.loaded
);

export const getFontFamiliesError = createSelector(
    getFontFamiliesState,
    (state: FontFamiliesState) => state.error
);

export const getFontFamiliesWhenLoaded = pipe(
    select(getFontFamiliesState),
    map(({ loaded, fontFamiliesFromBrand, fontFamiliesFromCreativeSet }) => {
        if (!loaded) {
            if (!fontFamiliesFromCreativeSet.length) {
                return undefined;
            }
            return fontFamiliesFromCreativeSet;
        }
        return mergeFontFamilies(fontFamiliesFromBrand, fontFamiliesFromCreativeSet);
    }),
    filter((result): result is IFontFamily[] => !!result)
);

export const getCreativesetFontFamilies = pipe(
    select(getFontFamiliesState),
    filter(state => state.loaded),
    map(({ fontFamiliesFromCreativeSet }) => fontFamiliesFromCreativeSet)
);

export const getVisibleBrandFontFamilies = createSelector(
    select(getBrandId),
    getFontFamiliesWhenLoaded,
    (brandId$, fontFamilies$) =>
        brandId$.pipe(
            switchMap(brandId =>
                fontFamilies$.pipe(
                    map(fontFamilies =>
                        fontFamilies.filter(
                            fontFamily =>
                                !fontFamily.visibleBrandIds ||
                                fontFamily.visibleBrandIds.includes(brandId)
                        )
                    )
                )
            )
        )
);

export const getNonDeletedBrandFontFamilies = createSelector(
    getVisibleBrandFontFamilies,
    fontFamilies$ =>
        fontFamilies$.pipe(
            map(fontFamilies => fontFamilies.filter(fontFamily => !fontFamily.deletedAt))
        )
);

export const getExternalFonts = createSelector(
    getFontFamiliesState,
    ({ externalFontsResult }) => externalFontsResult
);

export const getImportedExternalFonts = createSelector(
    getFontFamiliesState,
    ({ importedExternalFonts }) => importedExternalFonts
);

export const getFontManagerState = createSelector(
    getFontFamiliesState,
    ({ fontManagerState }) => fontManagerState
);

export const getFailedToLoadFontStyleIds = createSelector(
    getFontFamiliesState,
    state => state.failedToLoadFontStyleIds
);
