import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { sortFontFamilies } from '@creative/font-families.utils';
import { deserializeFontFamilies } from '@data/deserialization/font-families';
import { IFontFamily, IFontFamilyDto } from '@domain/font-families';
import {
    ExternalFontFamilyDto,
    ExternalFontResponse,
    ExternalImportBody,
    ExternalImportResponse
} from '@studio/domain/api/font-manager.types';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NGRX_STORES_CONFIG } from '../ngrx.config';

@Injectable({
    providedIn: 'root'
})
export class FontFamiliesDataService {
    private httpClient = inject(HttpClient);
    private ngrxConfig = inject(NGRX_STORES_CONFIG);
    private readonly API_ROUTE = `${this.ngrxConfig.origins.fontManagerApi}/api`;

    private _creativesetFontFamilies: IFontFamily[];
    get creativesetFontFamilies(): IFontFamily[] {
        return this._creativesetFontFamilies;
    }

    getFontFamiliesByBrandId(brandId: string): Observable<IFontFamily[]> {
        const url = `${this.API_ROUTE}/font/brand/${brandId}?includeDeleted=true&includeGlyphs=false`;

        return this.httpClient
            .get<IFontFamilyDto[]>(url)
            .pipe(map(fontFamiliesDto => this.processFontFamilies(fontFamiliesDto)));
    }

    getFontFamiliesByStyleIds(
        fontStyleIds: string[],
        includeGlyphs = false
    ): Observable<IFontFamily[]> {
        const url = `${this.API_ROUTE}/font?fontStyleIds=${fontStyleIds.join(
            '&fontStyleIds='
        )}&includeGlyphs=${includeGlyphs}`;

        return this.httpClient.get<IFontFamilyDto[]>(url).pipe(
            map(fontFamiliesDto => this.processFontFamilies(fontFamiliesDto)),
            tap(fontFamilies => (this._creativesetFontFamilies = fontFamilies))
        );
    }

    searchExternalFont(searchTerm: string): Observable<ExternalFontResponse> {
        const url = `${this.API_ROUTE}/integrations/googleFonts/find?searchTerm=${searchTerm}&page=1&itemsPerPage=50`;
        return this.httpClient.get<ExternalFontResponse>(url);
    }

    importExternalFont(
        brandId: string,
        items: ExternalFontFamilyDto[]
    ): Observable<ExternalImportResponse> {
        const url = `${this.API_ROUTE}/integrations/googleFonts/import`;
        const body: ExternalImportBody = {
            brandId,
            items
        };
        return this.httpClient.post<ExternalImportResponse>(url, body);
    }

    private processFontFamilies(fontFamiliesDto: IFontFamilyDto[]): IFontFamily[] {
        const deserializedFontFamilies = deserializeFontFamilies(fontFamiliesDto);
        return sortFontFamilies(deserializedFontFamilies);
    }
}
