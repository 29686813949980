import { CommonModule } from '@angular/common';
import { Component, DestroyRef, effect, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NS_CONFIG_TOKEN } from '@bannerflow/notification';
import { UIAdBlockerDetectionService, UILoaderComponent } from '@bannerflow/ui';
import { registerDependency } from '@di/di';
import { Token } from '@di/di.token';
import { BrandService } from '@studio/common/brand/brand.service';
import { EnvironmentService } from '@studio/common/services/environment.service';
import { UserService } from '@studio/common/user/user.service';
import { ActivityLoggerService } from '@studio/monitoring/activity-logger.service';
import { getPrimaryError } from '@studio/utils/errors/apps-errors';
import { interval, map, merge, of, skipWhile, switchMap } from 'rxjs';
import { HotkeyBetterService } from './shared/services/hotkeys/hotkey.better.service';

@Component({
    imports: [RouterOutlet, UILoaderComponent, CommonModule],
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private authService = inject(AuthService);
    private environmentService = inject(EnvironmentService);
    private destroyRef = inject(DestroyRef);
    private nsConfigToken = inject(NS_CONFIG_TOKEN);
    private uiAdBlockerDetectionService = inject(UIAdBlockerDetectionService);

    private user = toSignal(this.authService.user$);

    loaded = false;

    constructor(
        private hotkeyService: HotkeyBetterService,
        private activityLoggerService: ActivityLoggerService,
        private brandService: BrandService,
        private userService: UserService,
        protected router: Router
    ) {
        registerDependency(Token.HOTKEY_SERVICE, this.hotkeyService, 'studio');
        registerDependency(Token.ACTIVITY_LOGGER_SERVICE, this.activityLoggerService, 'studio');

        merge(this.brandService.error$, this.userService.error$)
            .pipe(
                skipWhile(error => !error),
                map(getPrimaryError),
                takeUntilDestroyed()
            )
            .subscribe(error => {
                throw error;
            });

        // Pass acess token to lib, needed for SignalR
        effect(() => {
            const { enabled } = this.environmentService.appEnvironment.nsSignalR;
            if (!enabled) {
                return;
            }
            this.provideAccessTokenToNS();
        });
        this.checkAdBlocker();
    }

    ngOnInit(): void {
        this.environmentService.setGlobals();
    }

    private checkAdBlocker(): void {
        if (this.environmentService.appEnvironment.stage !== 'test') {
            this.uiAdBlockerDetectionService.checkAndNotifiy().subscribe();
        }
    }

    private provideAccessTokenToNS(): void {
        const user = this.user();

        if (!user) {
            return;
        }

        const intervalTime = 30 * 60 * 1000; // 30 mins
        merge(interval(intervalTime), of(undefined))
            .pipe(
                switchMap(() => this.authService.getAccessTokenSilently({ cacheMode: 'on' })),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(accessToken => {
                this.nsConfigToken.accessToken$.next(accessToken);
            });
    }

    finishInit(): void {
        this.loaded = true;
    }
}
