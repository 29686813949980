import { formatDate } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { StudioServerToClientEvent, UserActivity, UsersUpdate } from '@domain/signalr';
import { HubConnectionState } from '@microsoft/signalr';
import { isFiniteNumber } from '@studio/utils/utils';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BrandService } from '../brand/brand.service';
import { UserService } from '../user/user.service';
import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';
import { SignalRHub } from './signalr-hub';

const BACKGROUND_COLORS = [
    'var(--studio-color-additional-purple)',
    'var(--studio-color-additional-sky)',
    'var(--studio-color-additional-coral)',
    'var(--studio-color-additional-olive)',
    'var(--studio-color-additional-almond)'
];

/*
 Usage:
 Sending:    this.signalrService.send('SendMessage', 'test', 'a');
 Listening:  this.signalrService.message$.subscribe(message...);
 or:      this.signalrService.on<UsersUpdate>('UserUpdated');
 */
@Injectable({ providedIn: 'root' })
export class StudioHubService extends SignalRHub<StudioServerToClientEvent> {
    private signalRConfig = inject(EnvironmentService).appEnvironment.signalR;
    userActivity$: Observable<UserActivity> = new Observable<UserActivity>();
    usersUpdated$: Observable<UsersUpdate> = new Observable<UsersUpdate>();
    private creativesetId: string; // needed for handshake, to group this connection by creativeset
    private inShowcaseMode = location.pathname.startsWith('/share/');

    constructor(
        protected userService: UserService,
        protected brandService: BrandService,
        protected authService: AuthService,
        private environmentService: EnvironmentService
    ) {
        super(userService, environmentService.appEnvironment.signalR.url, brandService, authService);
    }

    protected onConnectionReady(): void {
        if (
            this.signalRConfig.enabled &&
            !this.inShowcaseMode &&
            this.environmentService.appEnvironment.stage !== 'test'
        ) {
            this.logger.verbose('is enabled');

            if (this.connection.state === HubConnectionState.Connected) {
                throw new Error(
                    'SignalR connection was already established! There should be only one instance of this service!'
                );
            }
            /*
             tmp custom creativeset id parsing due to the service being injected for the apollo clients before anything was bootstraped
             once the custom apollo client is fully gone and we use the proper Angular DI for it,
             we can use the angular router to get that information or even the creativesetdata service.
             Injecting the creativesetdata service wasnt possible, because the SignalRService is created even before we bootstrap the app.
             This is the easiest way of grabbing the creativeset ID, which is always on the 4th url segment
             */
            this.authService
                .getAppState()
                .pipe(take(1))
                .subscribe(({ target }) => {
                    if (!target) {
                        throw new Error(
                            'SignalR connection was not established! Missing target in auth0 app state!'
                        );
                    }

                    const targetURL = new URL(target, location.origin);
                    const targetSegments = targetURL.pathname.split('/');
                    this.creativesetId = targetSegments[4];

                    if (!isFiniteNumber(Number(this.creativesetId))) {
                        throw new Error(
                            `SignalR connection was not established! Invalid creativeset id: ${this.creativesetId}`
                        );
                    }

                    this.registerListeners();

                    this.startConnection().then(() => {
                        this.subscribeToCreativeSet();
                    });
                });
        }
    }

    private async subscribeToCreativeSet(): Promise<void> {
        if (this.connection.state === HubConnectionState.Connected) {
            await this.connection.invoke('SubscribeToCreativeSet', {
                creativesetId: this.creativesetId
            });
        } else {
            this.logger.warn('SignalR connection is not established yet!');
        }
    }

    private registerListeners(): void {
        this.logger.verbose('registering listeners');

        this.userActivity$ = this.on('UserActivity').pipe(
            map(activity => {
                activity.timestamp = formatDate(activity.timestamp, 'dd/MM/yyyy hh:mm', 'en-GB');
                return activity;
            })
        );

        this.usersUpdated$ = this.on('UsersUpdated').pipe(
            map(update => {
                update.users.forEach((user, index) => (user.color = BACKGROUND_COLORS[index % 5]));
                return update;
            })
        );
    }
}
