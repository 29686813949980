import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FFFeatureFlagsService } from '@bannerflow/feature-flags';
import { Logger } from '@bannerflow/sentinel-logger';
import { getFontStyleIdsFromElements } from '@creative/font-families.utils';
import { ImageOptimizerUrlBuilder } from '@creative/image-optimizer';
import { initValidator } from '@creative/serialization/document-validator';
import { ICreativeset } from '@domain/creativeset';
import {
    BrandService,
    CreativesetDataService,
    FontFamiliesDataService,
    FontFamiliesService
} from '@studio/common';
import { EnvironmentService } from '@studio/common/services/environment.service';
import { SafeEval } from '@studio/utils/safe-eval';
import { catchError, filter, Observable, of, switchMap, take, tap, timeout } from 'rxjs';
import { VersionsService } from '../shared/versions/state/versions.service';

export const creativesetResolverFn: ResolveFn<Observable<ICreativeset>> = activatedRoute => {
    const { creativesetId } = activatedRoute.params;
    const brandService = inject(BrandService);
    const creativesetDataService = inject(CreativesetDataService);
    const environmentService = inject(EnvironmentService);
    const fontFamiliesDataService = inject(FontFamiliesDataService);
    const fontFamiliesService = inject(FontFamiliesService);
    const versionsService = inject(VersionsService);
    const ffFeatureFlagsService = inject(FFFeatureFlagsService);

    return brandService.brand$.pipe(
        filter(({ loaded }) => loaded),
        switchMap(brand => {
            if (!environmentService.appEnvironment.featureFlags.enabled) {
                return of(true);
            }

            ffFeatureFlagsService.setContextField('brandId', brand.id);
            ffFeatureFlagsService.setContextField('accountSlug', brand.accountSlug);

            const update$ = ffFeatureFlagsService.update$.pipe(
                take(1),
                timeout(3000),
                catchError(error => {
                    const logMessage =
                        error instanceof Error && error.name === 'TimeoutError'
                            ? 'Feature Flag Service took to long to be ready.'
                            : error;

                    Logger.warn(logMessage);
                    return of(true);
                })
            );

            ffFeatureFlagsService.start();

            return update$;
        }),
        switchMap(() => brandService.error$),
        switchMap(() => creativesetDataService.getCreativeset(creativesetId)),
        switchMap(async creativeset => {
            await Promise.all([
                ImageOptimizerUrlBuilder.init_m(environmentService.origins.imageOptimizer),
                SafeEval.init(),
                initValidator()
            ]);
            return creativeset;
        }),
        tap(creativeset => {
            const versions = creativeset.versions;
            const verionsParamProvided = 'version' in activatedRoute.queryParams;

            const versionParamExists = (activatedRoute.queryParams['version'] || '')
                .split(',')
                .filter(Boolean);

            versionsService.init(
                creativeset.id,
                versions,
                versionParamExists,
                verionsParamProvided,
                versions.find(({ id }) => id === creativeset.defaultVersion.id)!
            );
        }),
        switchMap(creativeset => {
            // Load fonts for brand
            fontFamiliesService.loadFontFamilies();

            // Check if there're missing fonts from SAPI
            const elements = creativeset.designs.flatMap(design => design.document.elements);
            const fontStyleIds = getFontStyleIdsFromElements(elements);

            const missingFont = !fontStyleIds.every(
                fontStyleId =>
                    !!creativeset.fonts?.some(({ fontStyles }) =>
                        fontStyles.some(({ id }) => id === fontStyleId)
                    )
            );
            if (!missingFont) {
                return of(creativeset);
            }

            return fontFamiliesDataService.getFontFamiliesByStyleIds(fontStyleIds).pipe(
                take(1),
                switchMap(fontFamilies => creativesetDataService.syncFonts(fontFamilies)),
                switchMap(() => of(creativeset))
            );
        })
    );
};
