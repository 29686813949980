import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@studio/common/services/environment.service';
import {
    DuplicateCreativeSetRequest,
    ICLVList,
    ICLVListItem,
    IDuplicationResponse
} from '@studio/domain/api/duplicate-creatives.types';
import { Sort } from '@studio/domain/components/duplicate-creatives/duplicate-creatives.types';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DuplicateCreativesDataService {
    private environmentService = inject(EnvironmentService);
    private httpClient = inject(HttpClient);

    readonly PAGE_SIZE = 200;

    getCreativeSetsWithFolders({
        brandId,
        pageIndex,
        folderId,
        searchTerm,
        sort
    }: {
        brandId: string;
        pageIndex: number;
        folderId?: string;
        searchTerm?: string;
        sort?: Sort;
    }): Observable<ICLVList> {
        const { listService } = this.environmentService.origins;
        const url = `${listService}/api/${brandId}/creativesets/${
            searchTerm ? 'FilterCreativeSetsAndFolders' : 'GetCreativeSetAndFolders'
        }`;

        let sortWithCorrectedFieldNames: Sort | undefined;
        if (sort) {
            sortWithCorrectedFieldNames = {
                ...sort,
                fieldName: sort.fieldName === 'created' ? 'createdAt' : sort.fieldName
            };
        }

        const requestBody: {
            paging: { pageNumber: number; pageSize: number };
            folderId?: string;
            filters?: { name: string };
            sort?: Sort;
        } = {
            paging: { pageNumber: pageIndex, pageSize: this.PAGE_SIZE },
            sort: sortWithCorrectedFieldNames
        };

        if (folderId) {
            requestBody.folderId = folderId;
        }

        if (searchTerm) {
            requestBody.filters = { name: searchTerm };
        }

        return this.httpClient.post<ICLVList>(url, requestBody);
    }

    duplicateToExisting(
        brandId: string,
        creativeset: string,
        sourceSizeIds: string[],
        sourceVersionIds: string[],
        targetCreativesetId: string
    ): Observable<IDuplicationResponse> {
        const { sapi } = this.environmentService.origins;
        const url = `${sapi}/creative-sets/${creativeset}/duplicate-to-existing/${targetCreativesetId}`;

        return this.httpClient
            .post<IDuplicationResponse>(url, { sourceVersionIds, sourceSizeIds })
            .pipe(map(() => ({ brandId, creativeSetId: targetCreativesetId })));
    }

    duplicateToNew(
        duplicateCreativeSetRequest: DuplicateCreativeSetRequest
    ): Observable<IDuplicationResponse> {
        const { listService } = this.environmentService.origins;
        const url = `${listService}/api/${duplicateCreativeSetRequest.brandId}/creativesets/duplicateSizesAndVersions`;
        return this.httpClient.post<IDuplicationResponse>(
            url,
            {
                externalId: duplicateCreativeSetRequest.creativesetId,
                targetParentFolderId: duplicateCreativeSetRequest.targetFolderId,
                sizeIds: duplicateCreativeSetRequest.sourceSizeIds,
                versionIds: duplicateCreativeSetRequest.sourceVersionIds
            },
            {
                headers: new HttpHeaders().set(
                    'actionCorrelationId',
                    duplicateCreativeSetRequest.actionCorrelationId
                )
            }
        );
    }

    createNewFolder(brandId: string, name: string, parentFolderId?: string): Observable<ICLVListItem> {
        const { listService } = this.environmentService.origins;
        const url = `${listService}/api/${brandId}/creativesets/folders/create`;
        return this.httpClient.post<ICLVListItem>(url, { name, parentFolderId });
    }
}
