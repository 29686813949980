import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as UserSettingsActions from './user-settings.actions';
import {
    IDesignViewAnimationSettings,
    IDesignViewSettings,
    IIgnoredWarnings,
    IManageViewSettings,
    ISharedSettings,
    KeyOfAnimationSettings
} from './user-settings.model';
import * as UserSettingsSelectors from './user-settings.selectors';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
    private store = inject(Store);

    manageViewSettings$ = this.store.select(UserSettingsSelectors.getManageViewSettings);
    designViewSettings$ = this.store.select(UserSettingsSelectors.getDesignViewSettings);
    sharedSettings$ = this.store.select(UserSettingsSelectors.getSharedSettings);
    layout$ = this.store.select(UserSettingsSelectors.getTranslationPageLayout);
    isLibraryPinned$ = this.store.select(UserSettingsSelectors.getIsLibraryPinned);
    libraryKind$ = this.store.select(UserSettingsSelectors.getLibraryKind);
    selectedFeedId$ = this.store.select(UserSettingsSelectors.getSelectedFeedId);
    dimOutsideCanvas$ = this.store.select(UserSettingsSelectors.getDimOutsideCanvas);
    snapping$ = this.store.select(UserSettingsSelectors.getSnapping);
    outlineVisible$ = this.store.select(UserSettingsSelectors.getOutlineVisible);
    guidelineVisible$ = this.store.select(UserSettingsSelectors.getGuidelineVisible);
    guidelineColor$ = this.store.select(UserSettingsSelectors.getGuidelineColor);
    grid$ = this.store.select(UserSettingsSelectors.getGrid);
    gridColor$ = this.store.select(UserSettingsSelectors.getGridColor);
    timelineHeight$ = this.store.select(UserSettingsSelectors.getTimelineHeight);
    displayGrid$ = this.store.select(UserSettingsSelectors.getDisplayGrid);
    animation$ = this.store.select(UserSettingsSelectors.getAnimation);

    translationPageTab$ = this.store.select(UserSettingsSelectors.getTranslationPageTabs);
    translationPageShowAllElements$ = this.store.select(
        UserSettingsSelectors.getTranslationPageShowAllElements
    );

    fontValidationWarnings$ = this.store.select(UserSettingsSelectors.getFontValidationWarnings);
    videoValidationWarnings$ = this.store.select(UserSettingsSelectors.getVideoValidationWarnings);

    init(creativeSetId: string): void {
        this.store.dispatch(UserSettingsActions.init({ creativeSetId }));
    }

    setDesignViewSetting<Key extends keyof IDesignViewSettings, Value extends IDesignViewSettings[Key]>(
        key: Key,
        value: Value
    ): void {
        this.store.dispatch(UserSettingsActions.setDesignViewSetting({ key, value }));
    }

    setManageViewSetting<Key extends keyof IManageViewSettings, Value extends IManageViewSettings[Key]>(
        key: Key,
        value: Value
    ): void {
        this.store.dispatch(UserSettingsActions.setManageViewSetting({ key, value }));
    }

    setIgnoreWarnings<T extends IIgnoredWarnings[keyof IIgnoredWarnings]>(
        key: keyof IIgnoredWarnings,
        value: T
    ): void {
        this.store.dispatch(UserSettingsActions.setIgnoreWarnings({ key, value }));
    }

    toggleTranslationPageLayout(direction?: 'left' | 'right'): void {
        this.store.dispatch(UserSettingsActions.toggleTranslationPageLayout({ direction }));
    }

    toggleTranslationPageShowAllElements(): void {
        this.store.dispatch(UserSettingsActions.toggleTranslationPageShowAllElements());
    }

    setAnimationSetting(key: keyof IDesignViewAnimationSettings, value: KeyOfAnimationSettings): void {
        this.store.dispatch(UserSettingsActions.setAnimationSetting({ key, value }));
    }

    setSharedSetting<Key extends keyof ISharedSettings, Value extends ISharedSettings[Key]>(
        key: Key,
        value: Value
    ): void {
        this.store.dispatch(UserSettingsActions.setSharedSetting({ key, value }));
    }
}
