import { inject, Injectable } from '@angular/core';
import { DuplicateCreativeResult, ListHubServerToClientEvent } from '@domain/signalr';
import { BrandService } from '@studio/common/brand/brand.service';
import { AuthService } from '@studio/common/services/auth.service';
import { EnvironmentService } from '@studio/common/services/environment.service';
import { SignalRHub } from '@studio/common/services/signalr-hub';
import { UserService } from '@studio/common/user/user.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ListHubService extends SignalRHub<ListHubServerToClientEvent> {
    duplicationResult$: Observable<DuplicateCreativeResult>;

    constructor(
        protected readonly userService: UserService,
        protected readonly brandService: BrandService,
        protected readonly authService: AuthService
    ) {
        const environmentService = inject(EnvironmentService);
        super(
            userService,
            `${environmentService.origins.listService}/hubs/creativeSets`,
            brandService,
            authService
        );
    }

    destroy(): void {
        this.connection.stop();
    }

    subscribeToNotification(actionCorrelationId: string, brandId: string): Promise<void> {
        return this.connection.invoke<void>('SubscribeToNotification', {
            brandId,
            actionCorrelationId
        });
    }

    protected onConnectionReady(): void {
        this.duplicationResult$ = this.on('SizesAndVersionsIntoNewDuplicationCompleted');
    }
}
