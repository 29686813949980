import { inject, Injectable } from '@angular/core';
import { deserializeCreativeSetFont } from '@data/deserialization/font-families';
import { ICreativeset } from '@domain/creativeset';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, combineLatestWith, delay, filter, map, of, switchMap } from 'rxjs';
import { BrandService } from '../brand/brand.service';
import { CreativesetDataService } from '../creativeSet';
import * as FontFamiliesActions from './font-families.actions';
import { FontFamiliesDataService } from './font-families.data.service';

const BACKOFF_LIMIT = 5;

@Injectable()
export class FontFamiliesEffects {
    private actions$ = inject(Actions);
    private brandService = inject(BrandService);
    private creativeSetDataService = inject(CreativesetDataService);
    private fontFamiliesDataService = inject(FontFamiliesDataService);
    private backoffIndex = 0;

    fontsFromCreativeSet$ = createEffect(() => {
        return this.creativeSetDataService.creativeset$.pipe(
            filter((creativeset): creativeset is ICreativeset => !!creativeset),
            switchMap(creativeset => {
                const fontFamilies = deserializeCreativeSetFont(creativeset.fonts);
                return of(FontFamiliesActions.setFontFamiliesOfCreativeSet({ fontFamilies }));
            })
        );
    });

    loadFontFamilies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.loadFontFamiliesOfBrand),
            combineLatestWith(this.brandService.brandId$),
            switchMap(([_, brandId]) =>
                this.fontFamiliesDataService.getFontFamiliesByBrandId(brandId).pipe(
                    map(fontFamilies => {
                        this.backoffIndex = 0;
                        return FontFamiliesActions.loadFontFamiliesOfBrandSuccess({ fontFamilies });
                    }),
                    catchError(error =>
                        of(FontFamiliesActions.loadFontFamiliesOfBrandFailure({ error }))
                    )
                )
            )
        );
    });

    loadFontByStyleId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.FontByStyleId.load),
            switchMap(({ fontStyleId }) => {
                return this.fontFamiliesDataService
                    .getFontFamiliesByStyleIds([fontStyleId], false)
                    .pipe(
                        switchMap(fontFamily => {
                            if (!fontFamily.length) {
                                return of(
                                    FontFamiliesActions.FontByStyleId.loadFailure({
                                        fontStyleId,
                                        error: new Error('Font not found')
                                    })
                                );
                            }
                            return of(
                                FontFamiliesActions.FontByStyleId.loadSuccess({
                                    fontStyleId,
                                    fontFamily: fontFamily[0]
                                })
                            );
                        }),
                        catchError(error => {
                            return of(
                                FontFamiliesActions.FontByStyleId.loadFailure({ error, fontStyleId })
                            );
                        })
                    );
            })
        );
    });

    searchExternalFont$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.searchExternalFonts),
            switchMap(({ searchTerm }) => {
                return this.fontFamiliesDataService.searchExternalFont(searchTerm).pipe(
                    map(response => FontFamiliesActions.searchExternalFontsSuccess({ response })),
                    catchError(error => of(FontFamiliesActions.searchExternalFontsFailure({ error })))
                );
            })
        );
    });

    importExternalFonts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.importExternalFonts),
            concatLatestFrom(() => this.brandService.brandId$),
            switchMap(([{ externalFontFamilies }, brandId]) => {
                return this.fontFamiliesDataService
                    .importExternalFont(brandId, externalFontFamilies)
                    .pipe(
                        map(response => FontFamiliesActions.importExternalFontsSuccess({ response })),
                        catchError(error =>
                            of(FontFamiliesActions.importExternalFontsFailure({ error }))
                        )
                    );
            })
        );
    });

    retryLoadingFonts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.loadFontFamiliesOfBrandFailure),
            switchMap(() => {
                this.backoffIndex = Math.min(this.backoffIndex + 1, BACKOFF_LIMIT);
                return of(FontFamiliesActions.loadFontFamiliesOfBrand()).pipe(
                    delay(Math.pow(2, this.backoffIndex) * 1000)
                );
            })
        );
    });
}
