import {
    AssetDto,
    CreativeDto,
    DocumentDto,
    HeadDto,
    ImageAssetDataDto,
    PreloadImageDto,
    VideoAssetDataDto
} from '@domain/api/generated/sapi';
import { ElementKind } from '@domain/elements';
import {
    IButtonElementDataNode,
    ICreativeDataNode,
    IImageElementDataNode,
    ITextElementDataNode,
    IVideoElementDataNode,
    OneOfDataNodes,
    OneOfGroupDataNodes
} from '@domain/nodes';
import { OneOfNodesDto } from '@domain/serialization';
import { IText } from '@domain/text';
import { IWidgetCustomProperty, IWidgetElementDataNode } from '@domain/widget';
import { cloneDeep } from '@studio/utils/clone';
import { parseColor } from '../color.utils';
import { GroupDataNode } from '../nodes/';
import { CreativeDataNode } from '../nodes/base-data-node';
import {
    isButtonNodeDto,
    isEllipseNodeDto,
    isGroupDataNode,
    isGroupNodeDto,
    isImageNode,
    isImageNodeDto,
    isRectangleNodeDto,
    isTextDataElement,
    isTextNodeDto,
    isVideoNode,
    isVideoNodeDto,
    isWidgetNodeDto
} from '../nodes/helpers';
import { serializeRichText } from './character-properties-serializer';
import {
    convertNodeToDto,
    deserializeDataNode,
    deserializeTextLikeDataElement
} from './data-element-serializer';
import { validateDocument } from './document-validator';
import {
    convertGuidelinesToDtos,
    deserializeGuidelines,
    deserializeSocialGuide
} from './guidelines-serializer';
import { deserializePreloadImage } from './preload-image-serializer';
import { convertImageAssetToDto, convertVideoAssetToDto } from './property-serializer';
import { serializeNumber } from './serialization.utils';
import { ValidationError } from './validator.utils';

// make these methods available inside Studio
if (typeof window !== 'undefined') {
    // @ts-expect-error: Not available in ACG
    window.deserializeDesignDocument = deserializeDesignDocument || {};
    // @ts-expect-error: Not available in ACG
    window.serializeDesignDocument = stringifyDesignDocument || {};
    // @ts-expect-error: Not available in ACG
    window.documentValidator = validateDocument || {};
}

export function deserializeDesignDocument(document: string | DocumentDto): CreativeDataNode {
    const documentDto = typeof document === 'string' ? (JSON.parse(document) as DocumentDto) : document;
    const creativeDto = documentDto.body.creative;
    const creative = new CreativeDataNode({
        id: creativeDto.id,
        width: creativeDto.width,
        height: creativeDto.height,
        loops: creativeDto.loops,
        audio: creativeDto.audio,
        startTime: creativeDto.startTime,
        stopTime: creativeDto.stopTime,
        guidelines: deserializeGuidelines(creativeDto.guidelines),
        fill: parseColor(creativeDto.fill),
        preloadImage: deserializePreloadImage(creativeDto.preloadImage),
        gifExport: creativeDto.gifExport,
        socialGuide: deserializeSocialGuide(creativeDto.socialGuide)
    });

    deserializeCreative(documentDto, creative);

    return creative;
}

export function deserializeCreative(documentDto: DocumentDto, creative: CreativeDataNode): void {
    function addToParentOrCreative(node: OneOfDataNodes, parentNodeId?: string): void {
        if (parentNodeId) {
            const parent = creative.findNodeById_m(parentNodeId, true) as OneOfGroupDataNodes;
            parent?.addNode_m(node);
        } else {
            creative.addNode_m(node);
        }
    }

    (function deserializeElements(elements: OneOfNodesDto[]): void {
        for (const elementDto of elements) {
            const node = deserializeOneOfNodes(elementDto, documentDto);
            node.__rootNode = creative;

            if (creative.findNodeById_m(node.id, true)) {
                continue;
            }

            if (isGroupDataNode(node)) {
                const groupNode = new GroupDataNode(node);
                addToParentOrCreative(groupNode, elementDto.parentNodeId);
                deserializeElements(elements.filter(element => element.id !== node.id));
            } else {
                addToParentOrCreative(node, elementDto.parentNodeId);
            }
        }
    })(documentDto.body.creative.elements);
}

export function deserializeOneOfNodes(
    nodeDto: OneOfNodesDto,
    documentDto: DocumentDto
): OneOfDataNodes {
    const node = {} as { -readonly [P in keyof OneOfDataNodes]: OneOfDataNodes[P] };

    if (isTextNodeDto(nodeDto)) {
        node.kind = ElementKind.Text;
        deserializeTextLikeDataElement(node as ITextElementDataNode, nodeDto);
    } else if (isButtonNodeDto(nodeDto)) {
        node.kind = ElementKind.Button;
        deserializeTextLikeDataElement(node as IButtonElementDataNode, nodeDto);
    } else if (isRectangleNodeDto(nodeDto)) {
        node.kind = ElementKind.Rectangle;
    } else if (isEllipseNodeDto(nodeDto)) {
        node.kind = ElementKind.Ellipse;
    } else if (isImageNodeDto(nodeDto)) {
        node.kind = ElementKind.Image;
        const imageAsset = documentDto.head.asset.images.find(i => i.id === nodeDto.imageAssetId)!;
        if (imageAsset) {
            (node as IImageElementDataNode).imageAsset = {
                id: imageAsset.id,
                url: imageAsset.src,
                name: imageAsset.name,
                width: imageAsset.width,
                height: imageAsset.height
            };
        }
        (node as IImageElementDataNode).feed = nodeDto.feed;
    } else if (isVideoNodeDto(nodeDto)) {
        node.kind = ElementKind.Video;
        const videoAsset = documentDto.head.asset.videos.find(i => i.id === nodeDto.videoAssetId)!;
        if (videoAsset) {
            (node as IVideoElementDataNode).videoAsset = {
                ...videoAsset
            };
        }
        (node as IVideoElementDataNode).feed = nodeDto.feed;
        (node as IVideoElementDataNode).checksum = nodeDto.checksum;
    } else if (isWidgetNodeDto(nodeDto)) {
        node.kind = ElementKind.Widget;
        const widget = node as IWidgetElementDataNode;
        widget.customProperties = nodeDto.customProperties.map(property => {
            let value = (
                property.versionPropertyId ? undefined : property.value
            ) as IWidgetCustomProperty['value'];

            if (property.unit === 'color') {
                value = parseColor(property.value as string);
            }

            return {
                label: property.label,
                name: property.name,
                unit: property.unit,
                value,
                versionPropertyId: property.versionPropertyId
            };
        });
    } else if (isGroupNodeDto(nodeDto)) {
        node.kind = ElementKind.Group;
    }

    deserializeDataNode(node as OneOfDataNodes, nodeDto);

    return node as OneOfDataNodes;
}

function creativeToDocument(creative: ICreativeDataNode): DocumentDto {
    const elements: OneOfNodesDto[] = [];
    const preloadImage: PreloadImageDto = {
        quality: creative.preloadImage.quality,
        format: creative.preloadImage.format,
        frames: creative.preloadImage.frames.map(frame => serializeNumber(frame))
    };
    const creativeDto: CreativeDto = {
        id: creative.id,
        width: creative.width,
        height: creative.height,
        fill: creative.fill.toString(),
        loops: creative.loops,
        audio: creative.audio,
        guidelines: convertGuidelinesToDtos(creative.guidelines),
        gifExport: creative.gifExport!,
        preloadImage,
        startTime: creative.startTime,
        stopTime: creative.stopTime,
        socialGuide: creative.socialGuide,
        elements
    };
    const images: ImageAssetDataDto[] = [];
    const videos: VideoAssetDataDto[] = [];
    const asset: AssetDto = {
        images,
        videos
    };
    const head: HeadDto = {
        asset,
        elements: []
    };
    const doc: DocumentDto = {
        head,
        body: {
            creative: creativeDto
        }
    };

    for (const node of creative.nodeIterator_m(true)) {
        head.elements.push({ id: node.id, type: node.kind });
        const nodeDto = convertNodeToDto(node, creative.elements);
        elements.push(nodeDto);
        if (isImageNode(node) && node.imageAsset) {
            const imageAsset = convertImageAssetToDto(node.imageAsset);
            if (imageAsset) {
                images.push(imageAsset);
            }
        } else if (isVideoNode(node) && node.videoAsset) {
            const video = convertVideoAssetToDto(node.videoAsset);
            if (video) {
                videos.push(video);
            }
        }
    }

    return doc;
}

export function stringifyDesignDocument(creative: ICreativeDataNode, shouldValidate?: boolean): string {
    creative = cloneDeep(creative);

    const doc = creativeToDocument(creative);

    if (shouldValidate) {
        const validation = validateDocument(doc);
        if (typeof validation !== 'boolean') {
            throw new ValidationError(validation);
        }
    }
    return JSON.stringify(doc, null);
}

export function convertCreativeToDocumentDto(
    creative: ICreativeDataNode,
    shouldValidate?: boolean
): DocumentDto {
    creative = cloneDeep(creative);

    const doc = creativeToDocument(creative);

    if (shouldValidate) {
        const validation = validateDocument(doc);
        if (typeof validation !== 'boolean') {
            throw new ValidationError(validation);
        }
    }
    return doc;
}

export function cloneCreativeDocument(
    document: ICreativeDataNode,
    cloneDirtyContent?: boolean
): ICreativeDataNode {
    const dirtyContents = new Map<string, IText>();

    if (cloneDirtyContent) {
        document.elements.forEach(element => {
            if (isTextDataElement(element) && element.__dirtyContent) {
                dirtyContents.set(element.id, serializeRichText(element.__dirtyContent));
            }
        });
    }

    const serialized = stringifyDesignDocument(document);
    const result = deserializeDesignDocument(serialized);
    // Remove the social guide when scaling out to other sizes
    result.socialGuide = undefined;

    if (cloneDirtyContent) {
        result.elements.forEach(element => {
            if (isTextDataElement(element)) {
                const dirtyContent = dirtyContents.get(element.id);
                if (dirtyContent) {
                    element.__dirtyContent = dirtyContent;
                }
            }
        });
    }

    return result;
}
