import { FontMetadataDto, FontStyleDto } from '@domain/api/generated/sapi';
import {
    IFontFamilyDto,
    IFontFamily,
    IFontStyleDto,
    IFontFamilyStyle,
    CreativeSetFontFamily
} from '@domain/font-families';

export function deserializeFontFamilies(
    fontFamilies: IFontFamilyDto[] | FontMetadataDto[]
): IFontFamily[] {
    return fontFamilies.map(fontFamily => ({
        id: fontFamily.id,
        name: fontFamily.name,
        visibleBrandIds: fontFamily.brandIds,
        createdAt: new Date(fontFamily.createdAt),
        deletedAt: fontFamily.deletedAt ? new Date(fontFamily.deletedAt) : undefined,
        fontStyles: fontFamily.fontStyles.map(style => deserializeFontStyle(style, fontFamily.id))
    }));
}

function deserializeFontStyle(
    fontStyleDto: IFontStyleDto | FontStyleDto,
    fontFamilyId: string
): IFontFamilyStyle {
    return {
        id: fontStyleDto.id,
        name: fontStyleDto.name,
        fontUrl: fontStyleDto.fontUrl,
        unicodeGlyphs: fontStyleDto.unicodeGlyphs,
        weight: fontStyleDto.weight,
        italic: fontStyleDto.italic,
        createdAt: new Date(fontStyleDto.createdAt),
        deletedAt: fontStyleDto.deletedAt ? new Date(fontStyleDto.deletedAt) : undefined,
        fontFamilyId
    };
}

export function deserializeCreativeSetFont(fonts: CreativeSetFontFamily[]): IFontFamily[] {
    return fonts.map(font => ({
        ...font,
        deletedAt: new Date(),
        fontStyles: deserializeFontFamilyStyle(font.fontStyles, font.id)
    }));
}

function deserializeFontFamilyStyle(
    fontFamilyStyles: CreativeSetFontFamily['fontStyles'],
    fontFamilyId: string
): IFontFamilyStyle[] {
    return fontFamilyStyles.map(
        (fontStyle): IFontFamilyStyle => ({
            ...fontStyle,
            italic: false,
            weight: 400,
            deletedAt: new Date(),
            unicodeGlyphs: [],
            createdAt: new Date(),
            fontFamilyId
        })
    );
}
