<div class="options">
    <div>Removes the detected background of the image</div>
    <div class="options_toggle_original-bg">
        <span>Show original background</span>
        <ui-toggle-switch
            [disabled]="!backgroundRemoved() || isRequestingAnything()"
            [selected]="showOriginalBackground()"
            (selectedChange)="onToggleOriginalBackground($event)" />
    </div>

    <ui-button
        id="interaction-remove-background"
        text="Remove background"
        type="primary"
        (click)="onRemoveBackground()"
        [loading]="isRequestingBackgroundRemoval()"
        [disabled]="actionsDisabled()" />
</div>
