import { distinctCharsFromString, fixedEncodeURIComponent } from '@studio/utils/utils';

export const FONT_SERVICE_TEXT_PARAMETER = '&t=';

export interface IFontServiceParams {
    textParams: string;
    distinctText: string;
}

/**
 * Gets unique characters from a string and returns the encoded string in textParams for the font service
 * @param text a string to get unique characters from
 */
export const getFontServiceParamsFromString = (text: string): IFontServiceParams => {
    const distinctChars = distinctCharsFromString(text);
    const encodedChars = distinctChars.map(char => fixedEncodeURIComponent(char)).join('');

    return {
        textParams: FONT_SERVICE_TEXT_PARAMETER + encodedChars,
        distinctText: distinctChars.join('')
    };
};
