import { Injectable, inject } from '@angular/core';
import { UIConfirmDialogResult, UIDialogService, UINotificationService } from '@bannerflow/ui';
import { isVideoElement } from '@creative/nodes/helpers';
import { IBrandLibraryElement } from '@domain/brand/brand-library';
import { UserService } from '@studio/common';
import { determineIsHeavyVideo, isMP4File, isTooHeavyVideo, isVideoFile } from '@studio/utils/media';
import { Observable, firstValueFrom, map } from 'rxjs';
import { HeavyVideoWarningDialogComponent } from '../../components/heavy-video-warning-dialog/heavy-video-warning-dialog.component';
import { DisplayCampaignService } from '../../display-campaign/state/display-campaign.service';
import { BrandLibraryDataService } from '../../media-library/brand-library.data.service';

@Injectable({ providedIn: 'root' })
export class HeavyVideoService {
    private displayCampaignService = inject(DisplayCampaignService);

    private hasAllowHeavyVideosPermission = false;
    private hasImpactVideos10MbPermission = false;
    private hasImpactVideos25MbPermission = false;
    private hasStudioVideoLibraryPermission = false;
    private permissionsLoaded = false;
    private creativeId?: string;

    private uiDialogService = inject(UIDialogService);
    private userService = inject(UserService);
    private uiNotificationService = inject(UINotificationService);
    private brandLibraryDataService = inject(BrandLibraryDataService);

    constructor() {
        this.initializePermissions();
    }

    private async initializePermissions(): Promise<void> {
        this.hasAllowHeavyVideosPermission = await this.userService.hasPermission('AllowHeavyVideos');
        this.hasImpactVideos10MbPermission = await this.userService.hasPermission('ImpactVideos10Mb');
        this.hasImpactVideos25MbPermission = await this.userService.hasPermission('ImpactVideos25Mb');
        this.hasStudioVideoLibraryPermission =
            await this.userService.hasPermission('StudioVideoLibrary');
        this.permissionsLoaded = true;
    }

    setActiveCreativeId(id?: string): void {
        this.creativeId = id;
    }

    async promptRemoveVideoUploads(files: File[], isDirectCanvasUpload = false): Promise<File[]> {
        if (!this.permissionsLoaded) {
            throw new Error('Permissions not loaded');
        }

        if (!this.hasStudioVideoLibraryPermission) {
            return files.filter(file => !isVideoFile(file));
        }

        const VIDEO_SIZE_LIMIT = this.hasAllowHeavyVideosPermission ? 50 : 2;
        const heavyVideos = files.filter(file => isMP4File(file) && this.isHeavyVideo(file.size));
        const tooHeavyVideos = files.filter(
            file => isMP4File(file) && isTooHeavyVideo(file.size, VIDEO_SIZE_LIMIT)
        );
        const unsupportedVideos = files.filter(file => isVideoFile(file) && !isMP4File(file));

        if (tooHeavyVideos.length) {
            this.uiNotificationService.open(`Video can not be larger than ${VIDEO_SIZE_LIMIT}MB.`, {
                type: 'error',
                placement: 'top',
                autoCloseDelay: 5000
            });

            files = files.filter(file => !tooHeavyVideos.includes(file));
        }

        if (heavyVideos.length && isDirectCanvasUpload) {
            const allowVideos = this.hasAllowHeavyVideosPermission
                ? await this.promptHeavyVideo()
                : false;
            if (!allowVideos) {
                files = files.filter(file => !heavyVideos.includes(file));
            }
        } else if (heavyVideos.length) {
            this.showHeavyVideoWarningNotification();
        }

        if (unsupportedVideos.length) {
            this.uiNotificationService.open(`Only MP4 video files can be uploaded.`, {
                type: 'warning',
                placement: 'top',
                autoCloseDelay: 5000
            });

            files = files.filter(file => !unsupportedVideos.includes(file));
        }

        return files;
    }

    /**
     * Determines what is considered a heavy video based on the user's permissions
     */
    isHeavyVideo(fileSize?: number): boolean {
        if (!this.permissionsLoaded) {
            throw new Error('Permissions not loaded');
        }

        if (!fileSize) {
            return false;
        }

        if (this.hasImpactVideos25MbPermission) {
            return determineIsHeavyVideo(fileSize, 25);
        }

        if (this.hasImpactVideos10MbPermission) {
            return determineIsHeavyVideo(fileSize, 10);
        }

        return determineIsHeavyVideo(fileSize, 2);
    }

    async promptRemoveVideoElements(elements: IBrandLibraryElement[]): Promise<IBrandLibraryElement[]> {
        const heavyVideos = elements.filter(element => {
            if (!isVideoElement(element)) {
                return false;
            }

            const asset = this.brandLibraryDataService.getAssetByElement(element);
            return this.isHeavyVideo(asset?.fileSize);
        });

        if (heavyVideos.length) {
            const allowVideos = await this.promptHeavyVideo();

            if (!allowVideos) {
                elements = elements.filter(element => !heavyVideos.includes(element));
            }
        }

        return elements;
    }

    async promptVideoElement(element: IBrandLibraryElement): Promise<boolean> {
        if (!isVideoElement(element)) {
            return true;
        }

        const asset = this.brandLibraryDataService.getAssetByElement(element);

        if (this.isHeavyVideo(asset?.fileSize)) {
            const allowHeavyVideo = await this.promptHeavyVideo();

            if (!allowHeavyVideo) {
                return false;
            }
        }

        return true;
    }

    showHeavyVideoWarningNotification(): void {
        const sizeLimit = this.getHeavyVideoSizeLimit();
        this.uiNotificationService.open(
            `This video is over ${sizeLimit}MB. Please acknowledge that it can't be used for Display Ads.`,
            { type: 'warning', placement: 'top', autoCloseDelay: 5000 }
        );
    }

    getHeavyVideoSizeLimit(): number {
        if (!this.permissionsLoaded) {
            throw new Error('Permissions not loaded');
        }

        if (this.hasImpactVideos25MbPermission) {
            return 25;
        }

        if (this.hasImpactVideos10MbPermission) {
            return 10;
        }

        return 2;
    }

    async promptHeavyVideo(): Promise<boolean> {
        const result = await this.showHeavyVideoWarningDialog();
        return result === 'confirm';
    }

    private async showHeavyVideoWarningDialog(): Promise<UIConfirmDialogResult> {
        const allowHeavyVideo =
            (await firstValueFrom(this.publishOptionsAllowHeavyVideo())) &&
            this.hasAllowHeavyVideosPermission;
        const dialogRef = this.uiDialogService.openComponent(HeavyVideoWarningDialogComponent, {
            headerText: 'Heavy video warning',
            theme: 'default',
            width: '620px',
            data: { allowHeavyVideo }
        });

        await dialogRef.afterViewInit;

        return (dialogRef.subComponentRef.instance as HeavyVideoWarningDialogComponent).initiate();
    }

    // Find out if creative's publish destinations supports heavy video
    private publishOptionsAllowHeavyVideo(): Observable<boolean | undefined> {
        return this.displayCampaignService.campaignsStatusList$.pipe(
            map(campaignPublishStatus => {
                if (!this.creativeId) {
                    return true;
                }
                return campaignPublishStatus
                    .map(({ creatives }) =>
                        creatives.find(creative => creative.creativeId === this.creativeId)
                    )
                    .every(
                        creativeInCampaign =>
                            !creativeInCampaign || creativeInCampaign.publishOptionsSupportHeavyVideo
                    );
            })
        );
    }
}
