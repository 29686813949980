import { diInjectable } from '@di/di';
import { Token } from '@di/di.token';
import type { ICreativeEnvironment } from '@domain/creative/environment';
import { ImageOptimizerUrlBuilder } from '../../image-optimizer';
import type * as WidgetModule from './widget.module';

let widgetModule: typeof WidgetModule | undefined;
window.ImageOptimizerUrlBuilder = { ImageOptimizerUrlBuilder: ImageOptimizerUrlBuilder };

export function registerWidgetDependencies(env: ICreativeEnvironment): void {
    // The file path is assigned to a variable to allow for relative import
    if (!widgetModule) {
        throw new Error('WidgetModule not loaded.');
    }

    const { WidgetRenderer, WidgetAdApi, CreativeApi, TimelineApi } = widgetModule;
    diInjectable(Token.WIDGET_RENDERER, WidgetRenderer).withArgs(env);
    diInjectable(Token.CREATIVE_API, CreativeApi);
    diInjectable(Token.TIMELINE_API, TimelineApi);
    diInjectable(Token.WIDGET_AD_API, WidgetAdApi).withArgs(env);
}

export async function loadWidgetModule(): Promise<void> {
    // The file path is assigned to a variable to allow for relative import
    const path = `./dist/libs/creative/widget.js${window.location.search}`;
    widgetModule = (await import(/* webpackIgnore: true */ path)) as typeof WidgetModule;
}
