import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService as Auth0AuthService, AuthGuard as Auth0Guard } from '@auth0/auth0-angular';
import { EnvironmentService } from '@studio/common/services/environment.service';
import { filter, Observable, switchMap, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private auth0Guard = inject(Auth0Guard);
    private auth0AuthService = inject(Auth0AuthService);
    private environmentService = inject(EnvironmentService);

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (this.environmentService.appEnvironment.stage === 'test') {
            return true;
        }

        return this.isAuthenticatedAuth0(() => this.auth0Guard.canActivate(route, state));
    }

    private isAuthenticatedAuth0(fn: () => Observable<boolean>): Observable<boolean> {
        return this.auth0AuthService.isAuthenticated$.pipe(
            filter(isAuthenticated => isAuthenticated !== null),
            take(1),
            switchMap(fn)
        );
    }
}
