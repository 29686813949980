import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@studio/common/services/environment.service';
import { HeavyAssetMetadata, HeavyAssetResponse } from '@studio/domain/api/heavy-assets.types';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeavyAssetsService {
    private environmentService = inject(EnvironmentService);
    private httpClient = inject(HttpClient);

    checkHeavyAssets(creativeIds: string[]): Observable<HeavyAssetMetadata[] | undefined> {
        const { sapi } = this.environmentService.origins;
        return this.httpClient
            .post<HeavyAssetResponse | undefined>(`${sapi}/creatives/heavy-asset-metadata`, {
                ids: creativeIds
            })
            .pipe(map(response => response?.creativesHeavyAssetMetadata));
    }
}
