<div class="style-wrapper">
    <div>
        Extracts stylistic elements from an input image and uses it to guide the creation of an output
        image based on the prompt
    </div>
    <expandable-prompt
        (promptChange)="onPromptChange($event)"
        [disabled]="isRequestingStyle()"
        [canExpand]="false"
        [type]="ExpandablePromptInputType.StylePrompt" />

    <expandable-prompt
        [disabled]="isRequestingStyle()"
        (promptChange)="onNegativePromptChange($event)" />

    <div class="slider-text">
        <div>AI Control Strength</div>
        <ui-icon
            class="help-icon"
            icon="help"
            uiTooltipPosition="bottom"
            uiTooltip="How closely the output image's style resembles the input image's style." />
    </div>
    <div class="slider-control">
        <studio-ui-slider
            [config]="{ min: 0, max: 10 }"
            [value]="controlStrength || 0"
            (valueChange)="adjustControlStrength($event)">
        </studio-ui-slider>

        <ui-number-input
            type="number"
            [value]="controlStrength"
            (valueChange)="adjustControlStrength($event)" />
    </div>

    <div class="section generate-actions">
        <ui-button
            id="interaction-generate-from-style"
            type="primary"
            text="Generate with style"
            [disabled]="actionsDisabled()"
            [loading]="isRequestingStyle()"
            (click)="style()" />
    </div>
</div>
