<div>Use prompt to search for object and replace with prompt specified object</div>
<expandable-prompt
    [disabled]="isRequestingSearchAndReplace()"
    [canExpand]="false"
    [type]="ExpandablePromptInputType.SearchPrompt"
    [value]="searchPrompt()"
    (promptChange)="onSearchPromptChange($event)" />

<expandable-prompt
    [disabled]="isRequestingSearchAndReplace()"
    [canExpand]="false"
    [type]="ExpandablePromptInputType.ReplacePrompt"
    [value]="replacePrompt()"
    (promptChange)="onReplacePromptChange($event)" />

<div class="btn-wrapper">
    <ui-button
        id="interaction-search-and-replace"
        type="primary"
        text="Replace"
        [disabled]="!!actionsDisabled()"
        [loading]="isRequestingSearchAndReplace()"
        (click)="searchAndReplace()"></ui-button>
</div>
