import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { HotkeyBetterService } from '../../../shared/services/hotkeys/hotkey.better.service';
import { GenAIService } from '../state/gen-ai.service';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'undo-redo-tooltip',
    templateUrl: './undo-redo-tooltip.component.html',
    styleUrls: ['./undo-redo-tooltip.component.scss']
})
export class UndoRedoTooltipComponent implements OnInit, OnDestroy {
    private genAIService = inject(GenAIService);
    private hotkeyBetterService = inject(HotkeyBetterService);

    canRedo = toSignal(this.genAIService.canRedo$, { initialValue: false });
    canUndo = toSignal(this.genAIService.canUndo$, { initialValue: false });

    ngOnInit(): void {
        this.hotkeyBetterService.on('Undo', this.onUndo);
        this.hotkeyBetterService.on('Redo', this.onRedo);
    }

    ngOnDestroy(): void {
        this.hotkeyBetterService.off('Undo', this.onUndo);
        this.hotkeyBetterService.off('Redo', this.onRedo);
    }

    onRedo = (): void => {
        if (this.canRedo()) {
            this.genAIService.redo();
        }
    };

    onUndo = (): void => {
        if (this.canUndo()) {
            this.genAIService.undo();
        }
    };
}
