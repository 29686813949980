import { IColor } from '@domain/color';
import { IElement } from '@domain/creativeset';
import { IBfFeed } from '@domain/feed';
import { IAsyncState } from '@domain/store/async';
import { createReducer, on } from '@ngrx/store';
import {
    CreativeToDirtyCharacterStyling,
    GroupedElements,
    IMatInputTextSelection,
    TranslationPageSnapshot,
    VersionToDirtyProperties
} from '@studio/domain/components/translation-page';
import { cloneDeep } from '@studio/utils/clone';
import { omit } from '@studio/utils/utils';
import {
    addSnapshotToUndoStack,
    createInitialHistorySnapshot,
    getPrevGroupSelectedMatch
} from '../utils/tp.utils';
import * as TranslationPageActions from './translation-page.actions';

export const TRANSLATION_PAGE_FEATURE_KEY = 'translationPage';

export interface TranslationPagePartialState {
    readonly [TRANSLATION_PAGE_FEATURE_KEY]: TranslationPageState;
}

export interface TranslationPageState extends IAsyncState {
    initialized: boolean;
    elements: IElement[];
    selectedElementId: string | undefined;

    groups: GroupedElements[];
    selectedGroupId: string | undefined;

    isShowingAll: boolean;

    dirtyProperties: VersionToDirtyProperties;
    dirtyCharacterStyling: CreativeToDirtyCharacterStyling;

    feedSources: IBfFeed[];

    usedColors: IColor[];

    selectedText:
        | {
              selection: IMatInputTextSelection;
              groupId: string;
              elementId: string;
              versionId: string;
              expanded: boolean;
          }
        | undefined;

    history: {
        undoStack: TranslationPageSnapshot[];
        redoStack: TranslationPageSnapshot[];
    };
}

export const initialState: TranslationPageState = {
    loaded: false,
    initialized: false,

    elements: [],
    selectedElementId: undefined,
    isShowingAll: false,

    groups: [],
    selectedGroupId: undefined,

    dirtyProperties: {},
    dirtyCharacterStyling: {},

    feedSources: [],

    usedColors: [],

    selectedText: undefined,

    history: {
        redoStack: [],
        undoStack: []
    }
};

export const reducer = createReducer<TranslationPageState>(
    initialState,

    on(
        TranslationPageActions.loadTranslationPage,
        TranslationPageActions.saveDirtyVersions,
        (state): TranslationPageState => ({ ...state, loaded: false, error: undefined })
    ),
    on(
        TranslationPageActions.loadTranslationPageSuccess,
        (state, { showAllElements }): TranslationPageState => {
            return {
                ...state,
                initialized: true,
                isShowingAll: showAllElements
            };
        }
    ),
    on(
        TranslationPageActions.loadTranslationPageSuccess,
        TranslationPageActions.updateElementsOnFilterChangeSuccess,
        (state, { elements, groups }): TranslationPageState => {
            const newSelectedGroup = getPrevGroupSelectedMatch(
                state.groups,
                state.selectedGroupId,
                state.selectedElementId,
                groups
            );
            const selectedElementId =
                state.selectedElementId ??
                (newSelectedGroup?.elements.length === 1 ? newSelectedGroup.elements[0].id : undefined);

            return {
                ...state,
                loaded: true,
                elements,
                selectedElementId: selectedElementId,
                groups: groups,
                selectedGroupId: newSelectedGroup?.id,
                dirtyProperties: {}
            };
        }
    ),
    on(
        TranslationPageActions.loadTranslationPageFailure,
        TranslationPageActions.saveDirtyVersionsFailure,
        (state, { error }): TranslationPageState => {
            return { ...state, loaded: true, error };
        }
    ),
    on(TranslationPageActions.loadTranslationPageFailure, (state, { error }): TranslationPageState => {
        return { ...state, loaded: true, error };
    }),

    on(TranslationPageActions.selectElement, (state, { elementId }): TranslationPageState => {
        const groupId = state.groups.find(({ elements }) =>
            elements.some(({ id }) => id === elementId)
        )?.id;
        return {
            ...state,
            selectedGroupId: groupId,
            selectedElementId: elementId
        };
    }),

    on(TranslationPageActions.selectGroup, (state, { groupId }): TranslationPageState => {
        const group = state.groups.find(({ id }) => id === groupId);
        const selectedElementId = group?.elements.length === 1 ? group.elements[0].id : undefined;
        return {
            ...state,
            selectedGroupId: groupId,
            selectedElementId
        };
    }),

    on(TranslationPageActions.selectNext, (state): TranslationPageState => {
        const currentIndex = state.groups.findIndex(({ id }) => id === state.selectedGroupId);
        const newIndex = Math.min(currentIndex + 1, state.groups.length - 1);
        const newSelectedGroup = state.groups[newIndex];
        const newSelectedElementId =
            newSelectedGroup?.elements.length === 1 ? newSelectedGroup.elements[0].id : undefined;

        return {
            ...state,
            selectedGroupId: newSelectedGroup?.id,
            selectedElementId: newSelectedElementId
        };
    }),

    on(TranslationPageActions.selectPrevious, (state): TranslationPageState => {
        const currentIndex = state.groups.findIndex(({ id }) => id === state.selectedGroupId);
        const newIndex = Math.max(currentIndex - 1, 0);
        const newSelectedGroup = state.groups[newIndex];
        const newSelectedElementId =
            newSelectedGroup?.elements.length === 1 ? newSelectedGroup.elements[0].id : undefined;

        return {
            ...state,
            selectedGroupId: newSelectedGroup?.id,
            selectedElementId: newSelectedElementId
        };
    }),

    on(
        TranslationPageActions.modifyDirtyVersionProperties,
        (state, { changes }): TranslationPageState => {
            const newDirtyProperties = cloneDeep(state.dirtyProperties);
            for (const { versionId, versionProperty, action } of changes) {
                newDirtyProperties[versionId] ??= {};
                if (action === 'delete') {
                    delete newDirtyProperties[versionId][versionProperty.id];
                } else {
                    newDirtyProperties[versionId][versionProperty.id] = versionProperty;
                }
            }

            const newUndoStack = addSnapshotToUndoStack(state);

            return {
                ...state,
                dirtyProperties: newDirtyProperties,
                history: {
                    undoStack: newUndoStack,
                    redoStack: []
                }
            };
        }
    ),

    on(
        TranslationPageActions.modifyDirtyCharacterStyles,
        (state, { changes }): TranslationPageState => {
            const newDirtyCharacterStyling = cloneDeep(state.dirtyCharacterStyling);
            for (const { creativeId, changes: styleChagnes } of changes) {
                newDirtyCharacterStyling[creativeId] ??= {};
                for (const [elementId, style] of Object.entries(styleChagnes)) {
                    newDirtyCharacterStyling[creativeId][elementId] ??= {};
                    Object.assign(newDirtyCharacterStyling[creativeId][elementId], style);
                }
            }
            return {
                ...state,
                dirtyCharacterStyling: newDirtyCharacterStyling
            };
        }
    ),

    on(
        TranslationPageActions.upsertDirtyVersionProperty,
        (state, { versionId, versionProperty }): TranslationPageState => {
            return {
                ...state,
                dirtyProperties: {
                    ...state.dirtyProperties,
                    [versionId]: {
                        ...state.dirtyProperties[versionId],
                        [versionProperty.id]: versionProperty
                    }
                }
            };
        }
    ),

    on(
        TranslationPageActions.removeDirtyVersionProperty,
        (state, { versionId, versionPropertyId }): TranslationPageState => {
            return {
                ...state,
                dirtyProperties: {
                    ...state.dirtyProperties,
                    [versionId]: omit(state.dirtyProperties[versionId], versionPropertyId)
                }
            };
        }
    ),

    on(TranslationPageActions.cancelTranslations, (state): TranslationPageState => {
        return {
            ...state,
            dirtyProperties: {}
        };
    }),

    on(TranslationPageActions.cancelStyleEditing, (state): TranslationPageState => {
        return {
            ...state,
            dirtyCharacterStyling: {}
        };
    }),

    on(TranslationPageActions.saveDirtyVersionsSuccess, (state): TranslationPageState => {
        const selectedElementGroup = state.groups.find(({ elements }) =>
            elements.some(({ id }) => id === state.selectedElementId)
        );

        const selectedElementId =
            selectedElementGroup?.elements.length === 1
                ? selectedElementGroup.elements[0].id
                : undefined;

        return {
            ...state,
            loaded: true,
            selectedElementId,
            dirtyProperties: {},
            dirtyCharacterStyling: {}
        };
    }),
    on(
        TranslationPageActions.showAll,
        (state): TranslationPageState => ({ ...state, isShowingAll: true })
    ),
    on(
        TranslationPageActions.hideAll,
        (state): TranslationPageState => ({ ...state, isShowingAll: false })
    ),

    on(
        TranslationPageActions.initBfFeeds,
        (state, { feeds }): TranslationPageState => ({ ...state, feedSources: feeds })
    ),

    on(TranslationPageActions.setUsedColors, (state, { colors }) => ({ ...state, usedColors: colors })),

    on(TranslationPageActions.selectText, (state, { selectedText }) => ({
        ...state,
        selectedText
    })),
    on(TranslationPageActions.undo, state => {
        if (!state.history.undoStack.length) {
            return state;
        }

        let currentSnapshot = state.history.undoStack.at(-1);
        let newUndoStack = state.history.undoStack.slice(0, -1);
        while (currentSnapshot?.type === 'mark') {
            currentSnapshot = newUndoStack.at(-1);
            newUndoStack = newUndoStack.slice(0, -1);
        }

        const newRedoSnapshot = cloneDeep({
            type: currentSnapshot?.type ?? 'update',
            dirtyProperties: state.dirtyProperties,
            dirtyCharacterStyling: state.dirtyCharacterStyling
        });

        return {
            ...state,
            history: {
                undoStack: newUndoStack,
                redoStack: [...state.history.redoStack, newRedoSnapshot]
            },
            ...currentSnapshot
        };
    }),
    on(TranslationPageActions.redo, state => {
        if (!state.history.redoStack.length) {
            return state;
        }

        const currentSnapshot = state.history.redoStack.at(-1);
        const newRedoStack = state.history.redoStack.slice(0, -1);

        const newUndoSnapshot = cloneDeep({
            dirtyProperties: state.dirtyProperties,
            dirtyCharacterStyling: state.dirtyCharacterStyling,
            type: currentSnapshot?.type ?? 'update'
        });

        return {
            ...state,
            history: {
                undoStack: [...state.history.undoStack, newUndoSnapshot],
                redoStack: newRedoStack
            },
            ...currentSnapshot
        };
    }),

    on(TranslationPageActions.changeHistoryPreSave, (state, { versions }) => {
        const lastInitialSnapshotIndex = state.history.undoStack.findLastIndex(
            ({ type }) => type === 'mark'
        );
        const initialSnapshot = createInitialHistorySnapshot(versions);
        let newUndoStack: TranslationPageSnapshot[] = state.history.undoStack.filter(
            ({ dirtyProperties }) => !!Object.keys(dirtyProperties).length
        );

        if (lastInitialSnapshotIndex === -1) {
            newUndoStack = [initialSnapshot, ...newUndoStack];
        } else {
            newUndoStack = [
                ...newUndoStack.slice(0, lastInitialSnapshotIndex),
                initialSnapshot,
                ...newUndoStack.slice(lastInitialSnapshotIndex)
            ];
        }

        newUndoStack.push({
            type: 'mark',
            dirtyCharacterStyling: {},
            dirtyProperties: {}
        });

        return {
            ...state,
            history: {
                redoStack: [],
                undoStack: newUndoStack
            }
        };
    })
);
