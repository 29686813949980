import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UIConfirmDialogResult, UIConfirmDialogService, UINotificationService } from '@bannerflow/ui';
import { IBrandLibraryElement, LibraryWidgetState } from '@domain/brand/brand-library';
import { ElementKind } from '@domain/elements';
import { UserService } from '@studio/common';
import { getLibraryWidgetReferenceOfElement } from '@studio/utils/element.utils';
import { handleError } from '@studio/utils/errors';
import { concatMap, from, Subject, tap, timer } from 'rxjs';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';
import { WidgetService } from '../../../shared/services/widget.service';

@Injectable({ providedIn: 'root' })
export class BrandLibraryElementDeletionService {
    private brandLibraryDataService = inject(BrandLibraryDataService);
    private uiConfirmDialogService = inject(UIConfirmDialogService);
    private uiNotificationService = inject(UINotificationService);
    private userService = inject(UserService);
    private widgetService = inject(WidgetService);
    private router = inject(Router);

    brandLibraryUpdated$ = new Subject<void>();
    deleteConfirmDialogOpen: boolean;

    async delete(...elements: IBrandLibraryElement[]): Promise<UIConfirmDialogResult | undefined> {
        if (elements.length === 0 || this.deleteConfirmDialogOpen) {
            return;
        }

        const filteredElements = await this.filterBannerflowWidgetDeletion(elements);
        if (!filteredElements?.length) {
            return;
        }

        this.deleteConfirmDialogOpen = true;
        const isSameType = filteredElements.every(({ type }) => type === filteredElements[0].type);

        const type =
            filteredElements[0].type === 'bannerflowLibraryWidget'
                ? 'Bannerflow widget'
                : filteredElements[0].type;
        const typeText = isSameType ? type : 'element';

        const text =
            filteredElements.length > 1
                ? `Are you sure you want to delete these ${typeText}s?`
                : `Are you sure you want to delete this ${typeText}?`;

        const response = this.uiConfirmDialogService.confirm({
            headerText: `DELETE FROM LIBRARY`,
            text,
            confirmText: 'Delete',
            cancelText: 'Cancel'
        });

        from(response)
            .pipe(
                concatMap(result => {
                    if (result === 'confirm') {
                        const ids = filteredElements.map(({ id }) => id);
                        return from(this.brandLibraryDataService.deleteElements(ids)).pipe(
                            tap(() => this.brandLibraryUpdated$.next())
                        );
                    }
                    // This hack needs to skip current stack
                    // Because onDocumentClick from MediaLibraryComponent will be executed at once and hide itself
                    return timer(0);
                })
            )
            .subscribe(() => {
                this.deleteConfirmDialogOpen = false;
            });

        return response;
    }

    private async filterBannerflowWidgetDeletion(
        elements: IBrandLibraryElement[]
    ): Promise<IBrandLibraryElement[] | undefined> {
        const bannerflowLibraryElements = elements.filter(
            element =>
                element.type !== ElementKind.BannerflowLibraryWidget &&
                getLibraryWidgetReferenceOfElement(element)
        );

        const hasBannerflowLibraryAccess = await this.userService.hasPermission('BannerflowLibrary');
        if (bannerflowLibraryElements.length && hasBannerflowLibraryAccess) {
            for (const element of bannerflowLibraryElements) {
                const bfLibraryId = getLibraryWidgetReferenceOfElement(element)?.value as string;

                try {
                    const bannerflowLibraryWidget = await this.widgetService.get(bfLibraryId);
                    if (bannerflowLibraryWidget.state === LibraryWidgetState.Active) {
                        this.uiNotificationService.open(
                            'You have to unpublish the widget from the bannerflow library before you can delete it.',
                            {
                                type: 'error',
                                autoCloseDelay: 5000,
                                placement: 'top'
                            }
                        );

                        elements = elements.filter(({ id }) => id !== element.id);
                    }
                } catch (err: unknown) {
                    this.uiNotificationService.open(
                        'Could not fetch store widgets. Please try again.',
                        {
                            type: 'error',
                            autoCloseDelay: 5000
                        }
                    );
                    handleError('Could not fetch store widgets', { contexts: { originalError: err } });
                }
            }
        }

        return elements;
    }

    private collectRouteParams(): Record<string, string> {
        let params: Record<string, string> = {};
        const stack: ActivatedRouteSnapshot[] = [this.router.routerState.snapshot.root];
        while (stack.length > 0) {
            const route = stack.pop();
            if (route === undefined) {
                continue;
            }
            params = { ...params, ...route.params };
            stack.push(...route.children);
        }
        return params;
    }
}
