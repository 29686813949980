import {
    createRichTextFromSegments,
    createRichTextFromString,
    isVersionedText
} from '@creative/elements/rich-text/utils';
import { CreativeDataNode } from '@creative/nodes/base-data-node';
import { createVersionedTextFromText, isTextNode } from '@creative/nodes/helpers';
import { deserializeDesignDocument } from '@creative/serialization/document-serializer';
import {
    deserializeGuidelines,
    deserializeSocialGuide
} from '@creative/serialization/guidelines-serializer';
import { OneOfCustomPropertyDtos, OneOfElementDtos } from '@domain/api/design-api.interop';
import {
    CreativeSetDto,
    DesignDto,
    DynamicContentDto,
    FeedDto,
    FilterDto,
    GenericStateDto,
    ImageElementDto,
    LegacyFeededReference,
    SizeDto,
    TextLikeElementDto,
    TextLikeElementOverrideDto,
    TextStateDto,
    VersionDto,
    VideoElementDto,
    WidgetElementDto,
    WidgetElementOverrideDto
} from '@domain/api/generated/design-api';
import {
    CharacterStyleDto,
    CreativeDto as SapiCreativeDto,
    DocumentDto as SapiDocumentDto,
    FilterDto as SapiFilterDto,
    ImageAssetDataDto as SapiImageAssetDataDto,
    StateDto as SapiStateDto,
    TextStateDto as SapiTextStateDto,
    VideoAssetDataDto as SapiVideoAssetDataDto,
    WidgetElementDto as SapiWidgetElementDto,
    FeedDto as SapiFeedDto
} from '@domain/api/generated/sapi';
import { ImageLibraryAsset } from '@domain/brand/brand-library/image-asset';
import { VideoLibraryAsset } from '@domain/brand/brand-library/video-asset';
import { IWidgetLibraryAsset } from '@domain/brand/brand-library/widget-asset';
import { CreativeSize, IDesign, IElement, IElementProperty } from '@domain/creativeset';
import { ApprovalStatus, ICreative } from '@domain/creativeset/creative/creative';
import { AssetReference } from '@domain/creativeset/element-asset';
import { IVersion, IVersionProperty, IWidgetText } from '@domain/creativeset/version';
import { ElementKind } from '@domain/elements';
import { IFeed } from '@domain/feed';
import { OneOfNodesDto } from '@domain/serialization';
import { ITextVariable, SpanType } from '@domain/text';
import { WidgetUnits } from '@domain/widget';
import { distinctArrayById } from '@studio/utils/array';
import { createElement, createElementProperty } from '@studio/utils/element.utils';
import { uuidv4 } from '@studio/utils/id';
import { isNumber, omit } from '@studio/utils/utils';
import {
    compileDesignApiElements,
    getElementOverride,
    getKindFromDesignApiElement,
    getPoolElement,
    isCustomFeedPropertyDto,
    isCustomFeedPropertyOverrideDto,
    isCustomTextPropertyDto,
    isCustomTextPropertyOverrideDto,
    isDapiEllipseNodeDto,
    isDapiGroupNodeDto,
    isDapiImageNodeDto,
    isDapiRectangleNodeDto,
    isDapiTextLikeNodeDto,
    isDapiVideoNodeDto,
    isDapiWidgetNodeDto,
    isDapiWidgetWithAssetNodeDto,
    isVersionableCustomPropertyDto
} from './helpers';

export function convertPoolElementToElement(element: OneOfElementDtos): IElement {
    return createElement({
        id: element.id,
        name: element.name,
        type: getKindFromDesignApiElementForCreativesetElement(element),
        properties: getPropertiesFromDapiElementDto(element)
    });
}

export function getConvertedCreativesAndAssets(
    creativeSetDto: CreativeSetDto,
    versions: IVersion[],
    elements: IElement[],
    designs: IDesign[],
    creativeSizes: CreativeSize[]
): {
    creatives: ICreative[];
    imageAssets: ImageLibraryAsset[];
    videoAssets: VideoLibraryAsset[];
    widgetAssets: IWidgetLibraryAsset[];
} {
    const imageAssets: ImageLibraryAsset[] = getImageLibraryAssetFromElementDtos(
        creativeSetDto.elementsPool
    );
    const videoAssets: VideoLibraryAsset[] = getVideoLibraryAssetFromElementDtos(
        creativeSetDto.elementsPool
    );

    // sometimes we can have widget element in design, but widget asset is missing in widgets array.
    // In this case we have decided to skip this items as it was originally (647956, 648039, 648041)
    const widgetAssets = creativeSetDto.elementsPool
        .filter(isDapiWidgetWithAssetNodeDto)
        .map(element => element.widgetAsset);

    const creatives = creativeSetDto.creatives.map((creative): ICreative => {
        const creativeSize = creativeSizes.find(({ id }) => id === `${creative.sizeId}`)!;
        const size = creativeSetDto.sizes.find(({ id }) => id === creative.sizeId)!;
        const version = versions.find(({ id }) => id === `${creative.versionId}`)!;
        const apiElements = compileDesignApiElements(creativeSetDto, creative);
        const designDto = size.design;

        imageAssets.push(...getImageLibraryAssetFromElementDtos(apiElements));
        videoAssets.push(...getVideoLibraryAssetFromElementDtos(apiElements));

        for (const apiElement of apiElements) {
            if (isDapiWidgetNodeDto(apiElement)) {
                const rootWidgetElement = elements.find(({ id }) => id === apiElement.id);
                for (const customProperty of apiElement.customProperties) {
                    if (
                        isVersionableCustomPropertyDto(customProperty) &&
                        customProperty.legacy_VersionPropertyId
                    ) {
                        const rootElementProperty = rootWidgetElement?.properties.find(
                            p => p.name === customProperty.name
                        );
                        if (rootElementProperty) {
                            rootElementProperty.versionPropertyId =
                                customProperty.legacy_VersionPropertyId;
                        }
                    }
                }
            }
        }

        const SAPIDesignDocumentDto = designDto
            ? createSapiDocument(apiElements, designDto, size, version)
            : undefined;

        const creativeDataNode = SAPIDesignDocumentDto
            ? deserializeDesignDocument(SAPIDesignDocumentDto)
            : undefined;

        let design: IDesign | undefined;

        if (creativeDataNode && designDto?.legacy_DesignId) {
            design = designs.find(({ id }) => id === designDto.legacy_DesignId);
            if (design) {
                mergeDesignElementCharacterStyles(creativeDataNode, design);
            } else {
                design = {
                    id: `${designDto.legacy_DesignId}`,
                    elements: elements.filter(element => size.elements[element.id]),
                    hasHeavyVideo: designDto.hasHeavyVideo,
                    name: designDto.name,
                    document: creativeDataNode
                };
                designs.push(design);
            }
        }

        return {
            id: `${creative.id}`,
            checksum: creative.checksum,
            approvalStatus: creative.approvalStatus as ApprovalStatus,
            size: creativeSize,
            design,
            version,
            targetUrl: creative.targetUrl
        };
    });

    return {
        creatives,
        imageAssets: distinctArrayById(imageAssets),
        videoAssets: distinctArrayById(videoAssets),
        widgetAssets: distinctArrayById(widgetAssets)
    };
}

export function convertVersion(
    version: VersionDto,
    elements: IElement[],
    creativesetDto: CreativeSetDto
): IVersion {
    const { id: versionId, name, targetUrl, elements: versionElements } = version;
    const migratedVersion: IVersion = {
        id: `${versionId}`,
        localization: {
            id: version.localizationId
        },
        name,
        targetUrl: targetUrl ?? '',
        properties: []
    };

    for (const elementId of Object.keys(versionElements)) {
        const poolElement = getPoolElement(creativesetDto, elementId);
        if (!poolElement) {
            continue;
        }

        const element = elements.find(({ id }) => id === poolElement.id)!;

        if (isDapiTextLikeNodeDto(poolElement)) {
            const versionElement = getElementOverride(poolElement, { version });
            const versionProperty = getTextLikeVersionProperty(versionElement, element);
            migratedVersion.properties.push(versionProperty);
            const existingElementProperty = element.properties.find(
                p => p.versionPropertyId === versionProperty.id
            );
            if (!existingElementProperty) {
                element.properties.push(
                    createElementProperty({
                        id: poolElement.legacy_TextElementPropertyId,
                        name: 'content',
                        versionPropertyId: versionProperty.id,
                        value: versionProperty.id ? undefined : '' // Comparison tool expects empty string due to that being persisted for SAPI, but we don't need to set value if version exists
                    })
                );
            }
        } else if (isDapiWidgetNodeDto(poolElement)) {
            const versionElement = getElementOverride(poolElement, { version });

            if (!versionElement.customProperties) {
                continue;
            }

            const widgetVersionProperties = getWidgetVersionProperties(versionElement);
            widgetVersionProperties.forEach(vp => migratedVersion.properties.push(vp));
        }
    }

    return migratedVersion;
}

export function getPropertiesFromDapiElementDto(element: OneOfElementDtos): IElementProperty[] {
    if (isDapiWidgetNodeDto(element)) {
        return getWidgetElementProperties(element);
    }

    if (isDapiVideoNodeDto(element)) {
        return getVideoElementProperties(element);
    }

    if (isDapiImageNodeDto(element)) {
        return getImageElementProperties(element);
    }

    if (isDapiTextLikeNodeDto(element)) {
        return getTextElementDynamicProperties(element);
    }

    return [];
}

export function getWidgetElementProperties(element: WidgetElementDto): IElementProperty[] {
    const properties: IElementProperty[] = [];

    const blobReferenceLegacyProperty = element.legacy_WidgetProperties?.find(
        ({ name }) => name === AssetReference.WidgetContentUrl
    );
    if (blobReferenceLegacyProperty) {
        properties.push(
            createElementProperty({
                clientId: uuidv4(),
                id: blobReferenceLegacyProperty.id,
                unit: 'string',
                name: AssetReference.WidgetContentUrl,
                value: element.widgetContentBlobReference
            })
        );
    }

    const widgetReferenceLegacyProperty = element.legacy_WidgetProperties?.find(
        ({ name }) => name === AssetReference.Widget
    );
    if (widgetReferenceLegacyProperty) {
        const value = element.widgetAsset?.id;
        properties.push(
            createElementProperty({
                clientId: uuidv4(),
                id: widgetReferenceLegacyProperty.id,
                unit: 'string',
                name: AssetReference.Widget,
                value: value
            })
        );
    }

    for (const customProperty of element.customProperties) {
        const customLegacyProperty = element.legacy_WidgetProperties?.find(
            ({ name }) => name === customProperty.name
        );
        if (customLegacyProperty) {
            // element custom properties label could vary from design document custom properties and element properties are prioritized
            const elementPropertyLabel = element.legacy_WidgetProperties?.find(
                e => e.name === customProperty.name
            )?.label;

            properties.push(
                createElementProperty({
                    ...customProperty,
                    clientId: uuidv4(),
                    id: customLegacyProperty.id,
                    unit: getUnitFromDesignApiCustomProperty(customProperty),
                    versionPropertyId: isVersionableCustomPropertyDto(customProperty)
                        ? customProperty.legacy_VersionPropertyId
                        : undefined,
                    label: elementPropertyLabel
                })
            );
        }
    }

    return properties;
}

export function getVideoElementProperties(element: VideoElementDto): IElementProperty[] {
    if (element.legacy_VideoElementPropertyId) {
        return [
            createElementProperty({
                clientId: uuidv4(),
                id: element.legacy_VideoElementPropertyId,
                unit: 'id',
                name: AssetReference.Video,
                value: element.videoAsset?.id.toString()
            })
        ];
    } else if (element.feed?.legacyFeededReference) {
        return [getLegacyFeedElementProperty(element.feed?.legacyFeededReference)];
    }

    return [];
}

function getLegacyFeedElementProperty(legacyFeededReference: LegacyFeededReference): IElementProperty {
    return createElementProperty({
        clientId: legacyFeededReference.clientId,
        id: legacyFeededReference.id,
        unit: legacyFeededReference.unit,
        name: legacyFeededReference.name,
        value: legacyFeededReference.value
    });
}

function getDynamicElementProperty(dynamicContent: DynamicContentDto): IElementProperty {
    return createElementProperty({
        clientId: uuidv4(),
        id: dynamicContent.id,
        unit: 'object',
        name: 'dynamicContent',
        label: dynamicContent.label,
        value: dynamicContent.value
    });
}

export function getTextElementDynamicProperties(element: TextLikeElementDto): IElementProperty[] {
    if (element.dynamicContent) {
        return [getDynamicElementProperty(element.dynamicContent)];
    }

    return [];
}

export function getImageElementProperties(element: ImageElementDto): IElementProperty[] {
    let elementProperties: IElementProperty[] = [];

    if (element.dynamicContent) {
        elementProperties = [getDynamicElementProperty(element.dynamicContent)];
    }

    if (element.legacy_ImageElementPropertyId) {
        return [
            ...elementProperties,
            createElementProperty({
                clientId: uuidv4(),
                id: element.legacy_ImageElementPropertyId,
                unit: 'id',
                name: AssetReference.Image,
                value: element.imageAsset?.id.toString()
            })
        ];
    } else if (element.feed?.legacyFeededReference) {
        return [getLegacyFeedElementProperty(element.feed?.legacyFeededReference)];
    }

    if (element.dynamicContent) {
        return [...elementProperties, getDynamicElementProperty(element.dynamicContent)];
    }

    return elementProperties;
}

export function getTextLikeVersionProperty(
    textLikeElement: TextLikeElementDto | TextLikeElementOverrideDto,
    element: IElement
): IVersionProperty {
    const existingProperty = element?.properties.find(({ name }) => name === 'content');

    const versionPropertyId =
        existingProperty?.versionPropertyId ?? textLikeElement.legacy_VersionPropertyId;

    if (!versionPropertyId) {
        throw new Error('VersionPropertyId not found');
    }

    const versionProperty: IVersionProperty = {
        id: versionPropertyId,
        name: 'content',
        value: createVersionedTextFromText(
            createRichTextFromSegments(textLikeElement.textSegments ?? [])
        )
    };

    return versionProperty;
}

export function getWidgetVersionProperties(
    widgetElement: WidgetElementOverrideDto
): IVersionProperty[] {
    const versionProperties: IVersionProperty[] = [];
    const customProperties = widgetElement.customProperties || [];

    for (const customProperty of customProperties) {
        if (customProperty.value === undefined) {
            continue;
        }

        if (isCustomTextPropertyOverrideDto(customProperty)) {
            const versionPropertyId = customProperty.legacy_VersionPropertyId;
            if (!versionPropertyId) {
                throw new Error('VersionPropertyId not found');
            }

            let propertyName: string;
            let textValue: IWidgetText;
            if (customProperty.legacy_VersionPropertyName === 'widgetText') {
                propertyName = 'widgetText';
                textValue = {
                    text: customProperty.value.value ?? ''
                };
                // looks like we still have corrupted data for widgetText, COBE is aware of it, and they will fix it soon
                // for now let's have this dirty fix, we styles exists then property type will be content
                if (textValue['styles'] !== undefined) {
                    delete textValue['styles'];
                }
            } else {
                propertyName = 'content';
                const versionedText = createVersionedTextFromText(
                    createRichTextFromString(customProperty.value.value ?? '')
                );
                // we have two variants if styles in SAPI when there are no stylesIds set
                // first one is empty array, second one is styles array splitted by tokens with empty styleIds
                // to have consistent data and save some memory we will remove styles if there are no styleIds
                if (versionedText.styles.length) {
                    const hasStyleIds = versionedText.styles.some(
                        style => Object.keys(style.styleIds).length
                    );
                    if (!hasStyleIds) {
                        versionedText.styles = [];
                    }
                }
                textValue = versionedText;
            }

            const versionProperty: IVersionProperty = {
                id: versionPropertyId,
                name: propertyName,
                value: textValue
            };

            versionProperties.push(versionProperty);
        } else if (isCustomFeedPropertyOverrideDto(customProperty)) {
            const versionPropertyId = customProperty.legacy_VersionPropertyId;
            if (!versionPropertyId) {
                throw new Error('VersionPropertyId not found');
            }
            if (!customProperty.value.value) {
                continue;
            }

            const feedValue: IFeed = {
                id: customProperty.value.value.id,
                path: customProperty.value.value.path,
                step: customProperty.value.value.step,
                fallback: customProperty.value.value.fallback,
                type: customProperty.value.value.type
            };

            const versionProperty: IVersionProperty = {
                id: versionPropertyId,
                name: 'feed',
                value: feedValue
            };

            versionProperties.push(versionProperty);
        }
    }
    return versionProperties;
}

export function populateCharacterStylesByTextSegment(
    textElement: TextLikeElementDto,
    version: IVersion,
    documentId: string
): CharacterStyleDto[] {
    const segments = textElement.textSegments;
    const versionProperty = version.properties.find(
        ({ id }) => id === textElement.legacy_VersionPropertyId
    );

    const characterStyles: CharacterStyleDto[] = [];

    if (!versionProperty || !isVersionedText(versionProperty)) {
        return [];
    }

    for (let i = 0; i < segments.length; i++) {
        // We have no other way to match a segment to a span on vp property than by index.
        // Assuming that all spans are present in vp prop & as segment no matter if it has a style or not
        const versionedStyle = versionProperty.value.styles[i];
        if (versionedStyle) {
            const segment = segments[i];

            // Apply character styles to node
            const styleId = segment.legacy_StyleId;

            if (styleId) {
                versionedStyle.styleIds[`${documentId}`] = styleId;
                const characterStyle: CharacterStyleDto = { id: styleId, value: segment.value };
                characterStyles.push(characterStyle);
            }

            versionedStyle.variable = segment.variable as ITextVariable;
            if (segment.type === SpanType.Variable) {
                versionedStyle.type = SpanType.Variable;
            }
        }
    }

    return characterStyles;
}

export function createSapiDocument(
    elementDtos: OneOfElementDtos[],
    designDto: DesignDto,
    size: SizeDto,
    version: IVersion
): SapiDocumentDto {
    const imageAssets = getImageAssetsDataFromElementDtos(elementDtos);
    const videoAssets = getVideoAssetsDataFromElementDtos(elementDtos);

    const elements = elementDtos.map(element => convertToStudioElement(element, size, version));

    const creativeDto: SapiCreativeDto = {
        id: `${size.design?.legacy_DocumentId}`,
        width: size.width,
        height: size.height,
        fill: designDto.fill,
        startTime: designDto.startTime,
        stopTime: designDto.stopTime,
        gifExport: designDto.gifExport,
        guidelines: deserializeGuidelines(designDto.guidelines),
        socialGuide: deserializeSocialGuide(designDto.socialGuide),
        loops: designDto.loops,
        audio: designDto.audio,
        preloadImage: designDto.preloadImage,
        elements
    };

    return {
        head: {
            asset: {
                images: imageAssets,
                videos: videoAssets
            },
            elements: elementDtos.map(element => ({
                id: element.id,
                type: getKindFromDesignApiElement(element)
            }))
        },
        body: {
            creative: creativeDto
        }
    };
}

export function getVideoAssetsDataFromElementDtos(
    elementDtos: OneOfElementDtos[]
): SapiVideoAssetDataDto[] {
    const videoAssets: SapiVideoAssetDataDto[] = [];

    for (const element of elementDtos) {
        if (isDapiVideoNodeDto(element) && element.videoAsset) {
            const videoAsset = element.videoAsset;
            videoAssets.push({
                id: videoAsset.id.toString(),
                name: videoAsset.name,
                fileSize: videoAsset.fileSize,
                height: videoAsset.height,
                url: videoAsset.url,
                width: videoAsset.width
            });
        }
    }

    return videoAssets;
}

export function getImageAssetsDataFromElementDtos(
    elementDtos: OneOfElementDtos[]
): SapiImageAssetDataDto[] {
    const imageAssets: SapiImageAssetDataDto[] = [];

    for (const element of elementDtos) {
        if (isDapiImageNodeDto(element) && element.imageAsset) {
            const imageAsset = element.imageAsset;
            imageAssets.push({
                id: imageAsset.id.toString(),
                height: imageAsset.original.height,
                src: imageAsset.original.url,
                width: imageAsset.original.width,
                name: imageAsset.name
            });
        }
    }

    return imageAssets;
}

export function getImageLibraryAssetFromElementDtos(
    elementDtos: OneOfElementDtos[]
): ImageLibraryAsset[] {
    const imageAssets: ImageLibraryAsset[] = [];

    for (const element of elementDtos) {
        if (isDapiImageNodeDto(element) && element.imageAsset) {
            const imageAsset = element.imageAsset;
            imageAssets.push({
                ...imageAsset,
                id: `${imageAsset.id}`,
                height: imageAsset.original.height,
                url: imageAsset.original.url,
                width: imageAsset.original.width
            });
        }
    }

    return imageAssets;
}

export function getVideoLibraryAssetFromElementDtos(
    elementDtos: OneOfElementDtos[]
): VideoLibraryAsset[] {
    const videoAssets: VideoLibraryAsset[] = [];

    for (const element of elementDtos) {
        if (isDapiVideoNodeDto(element) && element.videoAsset) {
            const videoAsset = element.videoAsset;
            videoAssets.push({
                ...videoAsset,
                id: `${videoAsset.id}`
            });
        }
    }

    return videoAssets;
}

export function getKindFromDesignApiElementForCreativesetElement(
    element: OneOfElementDtos
): ElementKind {
    if (isDapiWidgetNodeDto(element)) {
        return element.type as ElementKind;
    }

    return getKindFromDesignApiElement(element);
}

export function getUnitFromDesignApiCustomProperty(
    customProperty: WidgetElementDto['customProperties'][number]
): WidgetUnits {
    switch (customProperty.$type) {
        case 'CustomPropertyBooleanDto':
            return 'boolean';
        case 'CustomPropertyColorDto':
            return 'color';
        case 'CustomPropertyFeedDto':
            return 'feed';
        case 'CustomPropertyFontDto':
            return 'font';
        case 'CustomPropertyImageDto':
            return 'image';
        case 'CustomPropertyNumberDto':
            return 'number';
        case 'CustomPropertySelectDto':
            return 'select';
        case 'CustomPropertyTextDto':
            return 'text';
        default:
            throw new Error(`Unknown custom property type: ${customProperty}`);
    }
}

export function convertToStudioElement(
    elementDto: OneOfElementDtos,
    size: SizeDto,
    version: IVersion
): OneOfNodesDto {
    if (isDapiImageNodeDto(elementDto)) {
        const states = mapDapiGenericStateToSapiState(elementDto.states);
        const filters = mapDapiFilterToSapiFilter(elementDto.filters);
        const feed = mapDapiFeedToSapiFeed(elementDto.feed);

        return {
            ...omit(elementDto, '$type'),
            imageAssetId: elementDto.imageAsset?.id.toString(),
            __imageKind: true,
            feed,
            filters,
            states
        };
    }

    if (isDapiVideoNodeDto(elementDto)) {
        const states = mapDapiGenericStateToSapiState(elementDto.states);
        const filters = mapDapiFilterToSapiFilter(elementDto.filters);
        const feed = mapDapiFeedToSapiFeed(elementDto.feed);

        return {
            ...omit(elementDto, '$type'),
            videoAssetId: elementDto.videoAsset?.id.toString(),
            __videoKind: true,
            feed,
            states,
            filters
        };
    }

    if (isDapiTextLikeNodeDto(elementDto)) {
        const characterStyles = populateCharacterStylesByTextSegment(
            elementDto,
            version,
            `${size.design?.legacy_DocumentId}`
        );

        const isButton = elementDto.isButton;
        const states = mapDapiTextStateToSapiTextState(elementDto.states);
        const filters = mapDapiFilterToSapiFilter(elementDto.filters);

        if (isButton) {
            return {
                ...omit(elementDto, '$type', 'dynamicContent'),
                __buttonKind: true,
                characterStyles,
                filters,
                states
            };
        }

        return { ...omit(elementDto, '$type'), __textKind: true, characterStyles, filters, states };
    }

    if (isDapiWidgetNodeDto(elementDto)) {
        const customProperties = elementDto.customProperties.map(
            mapDapiWidgetCustomPropertyToSapiCustomProperty
        );

        const states = mapDapiGenericStateToSapiState(elementDto.states);
        const filters = mapDapiFilterToSapiFilter(elementDto.filters);

        return { ...omit(elementDto, '$type'), customProperties, __widgetKind: true, filters, states };
    }

    if (isDapiGroupNodeDto(elementDto)) {
        return { ...omit(elementDto, '$type'), __groupKind: true };
    }

    if (isDapiRectangleNodeDto(elementDto)) {
        const states = mapDapiGenericStateToSapiState(elementDto.states);
        const filters = mapDapiFilterToSapiFilter(elementDto.filters);
        return { ...omit(elementDto, '$type'), __rectangleKind: true, filters, states };
    }

    if (isDapiEllipseNodeDto(elementDto)) {
        const states = mapDapiGenericStateToSapiState(elementDto.states);
        const filters = mapDapiFilterToSapiFilter(elementDto.filters);

        return { ...omit(elementDto, '$type'), __ellipseKind: true, states, filters };
    }

    throw new Error('Unknown element type');
}

function mapDapiFeedToSapiFeed(feedDto?: FeedDto): SapiFeedDto | undefined {
    if (!feedDto) {
        return undefined;
    }

    return {
        id: feedDto.id,
        fallback: feedDto.fallback,
        path: feedDto.path,
        step: feedDto.step,
        type: feedDto.type
    };
}

function mapDapiWidgetCustomPropertyToSapiCustomProperty(
    customProperty: OneOfCustomPropertyDtos
): SapiWidgetElementDto['customProperties'][number] {
    const versionPropertyId =
        isCustomTextPropertyDto(customProperty) || isCustomFeedPropertyDto(customProperty)
            ? customProperty.legacy_VersionPropertyId
            : undefined;

    return {
        name: customProperty.name,
        label: customProperty.label,
        unit: getUnitFromDesignApiCustomProperty(customProperty),
        value: customProperty.value,
        versionPropertyId: versionPropertyId
    } as SapiWidgetElementDto['customProperties'][number];
}

function mapDapiGenericStateToSapiState(dapiGenericStates: GenericStateDto[]): SapiStateDto[] {
    return dapiGenericStates.map(state => ({
        ...state,
        filters: state.filters ? mapDapiFilterToSapiFilter(state.filters) : undefined
    }));
}

function mapDapiTextStateToSapiTextState(dapiGenericStates: TextStateDto[]): SapiTextStateDto[] {
    return dapiGenericStates.map(state => ({
        ...state,
        filters: state.filters ? mapDapiFilterToSapiFilter(state.filters) : undefined
    }));
}

function mapDapiFilterToSapiFilter(dapiFilterDto: FilterDto): SapiFilterDto {
    const filters: SapiFilterDto = {};

    if (isNumber(dapiFilterDto.blur)) {
        filters.blur = { value: dapiFilterDto.blur };
    }
    if (isNumber(dapiFilterDto.contrast)) {
        filters.contrast = { value: dapiFilterDto.contrast };
    }
    if (isNumber(dapiFilterDto.grayscale)) {
        filters.grayscale = { value: dapiFilterDto.grayscale };
    }
    if (isNumber(dapiFilterDto.invert)) {
        filters.invert = { value: dapiFilterDto.invert };
    }
    if (isNumber(dapiFilterDto.saturate)) {
        filters.saturate = { value: dapiFilterDto.saturate };
    }
    if (isNumber(dapiFilterDto.sepia)) {
        filters.sepia = { value: dapiFilterDto.sepia };
    }

    return filters;
}

function mergeDesignElementCharacterStyles(
    creativeDataNode: CreativeDataNode,
    existingDesign: IDesign
): void {
    for (const creativeElement of creativeDataNode.elements) {
        if (!isTextNode(creativeElement)) {
            continue;
        }
        const existingElement = existingDesign.document.findNodeById_m(creativeElement.id);
        if (isTextNode(existingElement)) {
            existingElement.characterStyles = new Map([
                ...existingElement.characterStyles,
                ...creativeElement.characterStyles
            ]);
        }
    }
}
