import { ICampaignStatus, ListDisplayCampaignsItem, PublishStatus } from '@studio/domain/campaigns';
import { ICreative } from '@domain/creativeset/creative';

export function isCreativePublishable(
    creative: ICreative,
    connectedCampaigns: ListDisplayCampaignsItem[],
    campaigns: ICampaignStatus[]
): boolean {
    const filteredCampaigns = getFilteredCampaigns(creative, connectedCampaigns, campaigns);

    if (!isCreativeConnectedToCampaigns(creative, connectedCampaigns)) {
        return false;
    }

    if (areAllFilteredCampaignsInBadStatus(filteredCampaigns)) {
        return false;
    }

    if (hasFailedCampaign(filteredCampaigns, creative.id)) {
        return true;
    }

    if (hasChecksumMismatch(filteredCampaigns, creative)) {
        return true;
    }

    return false;
}

function getFilteredCampaigns(
    creative: ICreative,
    connectedCampaigns: ListDisplayCampaignsItem[],
    campaigns: ICampaignStatus[]
): ICampaignStatus[] {
    return campaigns.filter(({ id, creatives }) => {
        const creativeHasConnectedCampaign = connectedCampaigns
            .find(({ creativeId }) => creativeId === creative.id)
            ?.connectedCampaigns.includes(id); // creative -> campaign

        const campaignHasCreative = creatives.some(({ creativeId }) => creativeId === creative.id); // campaign -> creative

        return creativeHasConnectedCampaign && campaignHasCreative;
    });
}

function isCreativeConnectedToCampaigns(
    creative: ICreative,
    connectedCampaigns: ListDisplayCampaignsItem[]
): boolean {
    return !!connectedCampaigns.find(({ creativeId }) => creativeId === creative.id)?.connectedCampaigns
        .length;
}

function areAllFilteredCampaignsInBadStatus(filteredCampaigns: ICampaignStatus[]): boolean {
    return filteredCampaigns.every(
        ({ status }) => status === PublishStatus.NotPublished || status === PublishStatus.Publishing
    );
}

function hasFailedCampaign(filteredCampaigns: ICampaignStatus[], creativeId: string): boolean {
    return filteredCampaigns.some(
        ({ creatives }) => creatives.find(({ creativeId: id }) => id === creativeId)?.failed
    );
}

function hasChecksumMismatch(filteredCampaigns: ICampaignStatus[], creative: ICreative): boolean {
    for (const campaign of filteredCampaigns) {
        const creativeInCampaign = campaign.creatives.find(
            ({ creativeId }) => creativeId === creative.id
        );
        if (
            creative.checksum &&
            creativeInCampaign?.checksum &&
            creative.checksum !== creativeInCampaign.checksum
        ) {
            return true;
        }
    }
    return false;
}

export function isCreativeInCampaign(
    creative: ICreative,
    listDisplayCampaigns: ListDisplayCampaignsItem[],
    campaigns: ICampaignStatus[]
): boolean {
    const connectedCampaignsToCreative = listDisplayCampaigns.find(
        ({ creativeId }) => creativeId === creative.id
    );
    if (!connectedCampaignsToCreative?.connectedCampaigns?.length) {
        return false;
    }

    for (const campaignId of connectedCampaignsToCreative.connectedCampaigns) {
        const campaign = campaigns.find(({ id }) => id === campaignId);
        if (!campaign) {
            continue;
        }
        const creativeInCampaign = campaign.creatives.find(
            ({ creativeId }) => creativeId === creative.id
        );
        if (!creativeInCampaign) {
            continue;
        }
        return true;
    }
    return false;
}

export function filterCampaignsConnectedTo(
    creatives: ICreative[],
    listDisplayCampaigns: ListDisplayCampaignsItem[],
    campaigns: ICampaignStatus[]
): ICampaignStatus[] {
    const campaignsConnectedToCreatives: ICampaignStatus[] = [];
    for (const campaign of campaigns) {
        const campaignConnectedToAnyCreative = listDisplayCampaigns.some(
            ({ connectedCampaigns, creativeId }) =>
                connectedCampaigns.includes(campaign.id) &&
                creatives.some(({ id }) => creativeId === id)
        );
        if (!campaignConnectedToAnyCreative) {
            continue;
        }
        campaignsConnectedToCreatives.push(campaign);
    }
    return campaignsConnectedToCreatives;
}
