import { Params } from '@angular/router';
import { IBrandSize } from '@domain/brand';
import { CreativeSize } from '@domain/creativeset';
import { ApprovalStatus } from '@domain/creativeset/creative';
import { ISizeCollection } from '@studio/domain/components/size-list.types';
import { ISelectableSizeCollections } from '@studio/domain/state';

interface IMVFilters {
    sizes: string[];
    statuses: ApprovalStatus[];
}

export function getFiltersFromQueryParams(queryParams: Params): IMVFilters {
    // get filters from current query parameters. e.g.: &size=132,32&status=in%20progress
    const sizesParam = queryParams['size'] || '';
    const statusesParam = queryParams['status'] || '';
    const sizes = sizesParam.split(',').filter((size: string) => size.trim() !== '');
    const statuses = statusesParam.split(',').filter((status: ApprovalStatus) => status.trim() !== '');

    return {
        sizes,
        statuses
    };
}

export function getSizesForSizeCollection(
    sizeCollection: ISizeCollection,
    sizeFormats: IBrandSize[],
    sizes: CreativeSize[]
): CreativeSize[] {
    const sizesInCollection: CreativeSize[] = [];

    for (const sizeFormatId of sizeCollection.sizeFormatIds ?? []) {
        const sizeFormat = sizeFormats.find(({ id }) => id === sizeFormatId);
        if (!sizeFormat) {
            continue;
        }

        for (const size of sizes) {
            // size.name may be `undefined` and sizeFormat.name may be `null`
            const isSameName = (!size.name && !sizeFormat.name) || size.name === sizeFormat.name;
            if (
                !sizesInCollection.includes(size) &&
                isSameName &&
                size.width === sizeFormat.width &&
                size.height === sizeFormat.height
            ) {
                sizesInCollection.push(size);
            }
        }
    }

    return sizesInCollection;
}

export function getNewSelectedCollections(
    selectedSizes: string[],
    selectableCollections: ISelectableSizeCollections[],
    selectedCollections: string[]
): string[] {
    const newSelectedCollections: string[] = [];

    for (const selectedCollectionId of selectedCollections) {
        const collection = selectableCollections.find(({ id }) => id === selectedCollectionId);
        if (!collection) {
            continue;
        }
        const fullySelected = collection.sizeIds.every(sizeId => selectedSizes.includes(sizeId));
        if (fullySelected) {
            newSelectedCollections.push(selectedCollectionId);
        }
    }

    return newSelectedCollections;
}

export function getSelectedSizesFromSelectedCollections(
    selectableCollections: ISelectableSizeCollections[],
    selectedCollections: string[]
): string[] {
    const selectedSizes = selectableCollections.flatMap(selectableCollection => {
        if (!selectedCollections.includes(selectableCollection.id)) {
            return [];
        }
        return selectableCollection.sizeIds;
    });

    const newSelectedSizes = Array.from(new Set([...selectedSizes]));

    return newSelectedSizes;
}
