export interface IFeed {
    id: string;
    path: string;
    step: IFeedStep;
    fallback: string;
    type: FeedType;
}

export type FeedType = 'text' | 'number' | 'image' | 'video';

export interface IFeedStep {
    /**
     * Defaults to 1.
     */
    size: number;
    /**
     * Defaults to 1.
     */
    start: number;
    occurrence: 'loop' | 'none';
}

export interface IFeedMeta {}

export interface IFeedDataKeyValueItem {
    [key: string]: IFeedDataValue;
}

export interface IFeedData {
    data: IFeedDataKeyValueItem[];
    intervalInSeconds: number; // Interval duration represented in seconds
}

export interface IFeedDataValue {
    value?: string | number;
    targetUrl?: string;
}

export interface IFeedDataDto {
    data: IFeedDataKeyValueItemDto[];
    intervalInSeconds: number; // Interval duration represented in seconds
}

export interface IFeedDataKeyValueItemDto {
    [key: string]: IFeedDataValueDto;
}

export interface IFeedDataValueDto {
    value: string | number | null;
    targetUrl: null;
}

export const FeededReference = {
    Image: 'feededImageReference',
    Video: 'feededVideoReference'
} as const;

export type FeededReferenceValue = (typeof FeededReference)[keyof typeof FeededReference];

export interface IBfFeed {
    id: string;
    name: string;
    isBroken?: boolean;
    errorMessage?: string;
    children?: IBfFeed[];
}

export interface IDynamicPropertyData {
    id: string;
    value?: string;
}

export type DynamicPropertyData =
    | IDynamicPropertyData
    | Required<IDynamicPropertyData>
    | Omit<IDynamicPropertyData, 'value'>;

export interface IDynamicProperties {
    id: string;
    values: DynamicPropertyData[];
}
