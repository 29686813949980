<div class="erase-wrapper">
    <div>Use the brush to remove unwanted objects from the image.</div>
    <div class="section brush-size">
        <div class="brush-size-label">Brush</div>

        @let brushSize = brushSizeSignal();
        <studio-ui-slider
            [config]="{ min: 1, max: 100 }"
            [value]="brushSize"
            [disabled]="isRequestingErase()"
            (valueChange)="setEraseBrushSize($event)" />

        <ui-number-input
            unitLabel="px"
            [keyboardEmit]="true"
            [max]="100"
            [min]="1"
            [value]="brushSize"
            [disabled]="isRequestingErase()"
            (valueChange)="setEraseBrushSize($event)" />
    </div>

    <div class="section erase-actions">
        <ui-button
            id="interaction-erase"
            type="primary"
            text="Erase"
            [disabled]="actionsDisabled()"
            [loading]="isRequestingErase()"
            (click)="erase()" />
    </div>
</div>
