import { ErrorMessage } from './error-message.enum';

interface ErrorContext {
    enabled?: boolean;
    level?: string;
    contexts?: { [key: string]: any };
}

export type ErrorWithContext = Error & ErrorContext;

const defaultErrorContext: ErrorContext = { enabled: true, level: 'error' };

export class WidgetError extends Error implements ErrorWithContext {
    name = 'WidgetError';
    contexts: ErrorContext['contexts'];

    constructor(id: string, contexts?: ErrorContext['contexts']) {
        const errorMessage = contexts?.originalError?.message ?? '';
        super(`WidgetError: "${id}" ${errorMessage}`);
        this.contexts = contexts;
    }
}

export class VideoError extends Error implements ErrorWithContext {
    name = 'VideoError';
    constructor(message: string, contexts?: ErrorContext['contexts']) {
        const videoSource = contexts?.src ?? '';
        super(`VideoError: ${message} ${videoSource}`);
    }
}

export function getVideoErrorMessage(code?: number): string {
    switch (code) {
        case MediaError.MEDIA_ERR_ABORTED:
            return ErrorMessage.MediaErrorAborted;
        case MediaError.MEDIA_ERR_NETWORK:
            return ErrorMessage.MediaErrorNetwork;
        case MediaError.MEDIA_ERR_DECODE:
            return ErrorMessage.MediaErrorDecode;
        case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
            return ErrorMessage.MediaErrorSourceNotSupported;
        default:
            return '';
    }
}

function isErrorContext(value: any): value is ErrorContext {
    return !!(value && value.contexts);
}

export function handleError(message: string, context?: ErrorContext | unknown): void {
    let errorContext: ErrorContext;

    if (!isErrorContext(context)) {
        errorContext = { ...defaultErrorContext, ...{ contexts: { context } } };
    } else {
        errorContext = { ...defaultErrorContext, ...context };
    }

    if (errorContext.level === 'error') {
        const unwrappedContext = errorContext.contexts ? Object.values(errorContext.contexts) : [];

        // Studio Client global-error-handler
        const tracker = (globalThis as any)?.globalErrorHandler;

        if (errorContext.enabled && tracker) {
            const firstError = unwrappedContext.find(error => error instanceof Error);
            tracker.handleError(firstError ?? message);
        } else {
            console.error(message, ...unwrappedContext);
        }
    } else {
        console.warn(message, errorContext.contexts);
    }
}
