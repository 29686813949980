import { IPosition } from '@domain/dimension';
import { fromEvent, Observable, race, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { distance2D, eventToPosition } from './geom';

export const LEFT_MOUSE_DOWN = 0;
export const MIDDLE_MOUSE_DOWN = 1;
export const RIGHT_MOUSE_DOWN = 2;

export interface IMouseValue {
    event: MouseEvent;
    /** Mouseposition { x, y } relative to the host */
    mousePosition: IPosition;
    button?: number;
}

export interface IMouseDown extends IMouseValue {
    button: number;
}

export interface IMouseWheelSpeed {
    x: number;
    y: number;
}

export interface IMouseWheelValue {
    event: WheelEvent;
    /** Mouseposition { x, y } relative to the host */
    mousePosition: IPosition;
    speed: IMouseWheelSpeed;
}

export interface IMouseDownMove extends IMouseValue {
    /**
     * Mouse offset since "mousedown"
     */
    mouseDelta: IPosition;

    /**
     * Mouse offset since last event
     */
    mouseChange: IPosition;

    mouseDownMoved: boolean;

    /**
     * Event from original mouseDown
     */
    mouseDownEvent?: MouseEvent;
}

interface IMouseObservableOption {
    offset?: { x?: number; y?: number };
    mouseDownMoveTreshold?: number | Partial<IPosition>;
}

export class MouseObservable {
    mouseDown$: Observable<IMouseDown>;
    mouseMove$: Observable<IMouseValue>;
    mouseDownMove$: Observable<IMouseDownMove>;
    doubleClick$: Observable<IMouseValue>;
    mouseUp$: Observable<IMouseDownMove>;
    mouseWheel$: Observable<IMouseWheelValue>;
    documentMove$: Observable<MouseEvent>;
    mouseEnter$: Observable<MouseEvent>;
    mouseLeave$: Observable<MouseEvent>;

    private mouseDownStart: { x: number; y: number } = { x: 0, y: 0 };
    private documentUp$: Observable<IMouseDownMove>;
    private mouseDownMoved: boolean;
    private unsubscribe$ = new Subject<void>();
    private lastMouseDelta: IPosition = { x: 0, y: 0 };

    constructor(
        private host: HTMLElement,
        private options: IMouseObservableOption = {}
    ) {
        this.mouseDown$ = fromEvent<MouseEvent>(this.host, 'mousedown').pipe(
            filter(({ button }) =>
                [RIGHT_MOUSE_DOWN, LEFT_MOUSE_DOWN, MIDDLE_MOUSE_DOWN].includes(button)
            ),
            map(event => {
                const mousePosition = this.getMousePosition(event);
                return { event, mousePosition, button: event.button };
            }),
            tap(({ event }) => {
                this.mouseDownStart = eventToPosition(event);
                this.mouseDownMoved = false;
            }),
            takeUntil(this.unsubscribe$)
        );

        this.doubleClick$ = fromEvent<MouseEvent>(this.host, 'dblclick').pipe(
            takeUntil(this.unsubscribe$),
            map(event => {
                const mousePosition = this.getMousePosition(event);
                return { event, mousePosition };
            })
        );

        this.mouseMove$ = fromEvent<MouseEvent>(this.host, 'mousemove').pipe(
            map(event => {
                const mousePosition = this.getMousePosition(event);
                return { event, mousePosition };
            }),
            takeUntil(this.unsubscribe$)
        );

        const mouseUp$ = fromEvent<MouseEvent>(this.host, 'mouseup').pipe(
            map(event => {
                event.preventDefault();
                const mouseDelta = distance2D(this.mouseDownStart, eventToPosition(event));
                const mousePosition = this.getMousePosition(event);
                const mouseChange = distance2D(this.lastMouseDelta, mouseDelta);
                this.lastMouseDelta = { x: 0, y: 0 };

                return {
                    event,
                    mouseDelta,
                    mouseChange,
                    mousePosition,
                    mouseDownMoved: this.mouseDownMoved
                };
            }),
            takeUntil(this.unsubscribe$)
        );

        this.documentUp$ = fromEvent<MouseEvent>(document, 'mouseup').pipe(
            map(event => {
                event.preventDefault();
                const mouseDelta = distance2D(this.mouseDownStart, eventToPosition(event));
                const mouseChange = distance2D(this.lastMouseDelta, mouseDelta);
                const mousePosition = this.getMousePosition(event);
                this.lastMouseDelta = { x: 0, y: 0 };

                return {
                    event,
                    mouseDelta,
                    mouseChange,
                    mousePosition,
                    mouseDownMoved: this.mouseDownMoved
                };
            }),
            takeUntil(this.unsubscribe$)
        );

        this.mouseUp$ = this.mouseDown$.pipe(
            switchMap(() => race(mouseUp$, this.documentUp$).pipe(take(1))),
            takeUntil(this.unsubscribe$)
        );

        this.documentMove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
            takeUntil(this.unsubscribe$)
        );

        this.mouseDownMove$ = this.mouseDown$.pipe(
            switchMap(mouseValue =>
                this.documentMove$.pipe(
                    throttleTime(1),
                    map((event): IMouseDownMove => {
                        event.preventDefault();
                        const mouseDownEvent = mouseValue.event;
                        const mouseDelta = distance2D(
                            eventToPosition(mouseDownEvent),
                            eventToPosition(event)
                        );
                        const mouseChange = distance2D(this.lastMouseDelta, mouseDelta);
                        const mousePosition = this.getMousePosition(event);
                        this.mouseDownMoved = this.mouseDownMoved || this.mouseHasMoved(mouseDelta);
                        return {
                            event,
                            mouseDelta,
                            mousePosition,
                            mouseChange,
                            mouseDownMoved: this.mouseDownMoved,
                            mouseDownEvent
                        };
                    }),
                    tap(({ mouseDelta }) => {
                        this.lastMouseDelta = { ...mouseDelta };
                    }),
                    takeUntil(race(this.documentUp$, this.mouseUp$, this.unsubscribe$))
                )
            ),
            filter(() => this.mouseDownMoved),
            takeUntil(this.unsubscribe$)
        );

        this.mouseWheel$ = fromEvent<WheelEvent>(this.host, 'wheel').pipe(
            map(event => {
                const mousePosition = this.getMousePosition(event);
                return {
                    event,
                    mousePosition,
                    speed: {
                        x: event.deltaX,
                        y: event.deltaY
                    }
                };
            }),
            takeUntil(this.unsubscribe$)
        );

        this.mouseEnter$ = fromEvent<MouseEvent>(this.host, 'mouseenter');
        this.mouseLeave$ = fromEvent<MouseEvent>(this.host, 'mouseleave');
    }

    private mouseHasMoved(mouseDelta: IPosition): boolean {
        const treshold = this.options.mouseDownMoveTreshold || 0;
        const x = Math.abs(mouseDelta.x);
        const y = Math.abs(mouseDelta.y);

        if (typeof treshold === 'number') {
            return x > treshold || y > treshold;
        } else {
            // Equal (empty or not) => One of the axis must exceed limit
            if (treshold.x === treshold.y) {
                const t = treshold.x || 0;
                return x > t || y > t;
            }
            // Only x have to exceed limit
            else if (typeof treshold.x === 'number') {
                return x > treshold.x;
            }
            // Only y have to exceed limit
            else if (typeof treshold.y === 'number') {
                return y > treshold.y;
            }
        }
        // Should never end up here
        return false;
    }

    private getMousePosition(event: MouseEvent): IPosition {
        const offsetX = this.options.offset?.x || 0;
        const offsetY = this.options.offset?.y || 0;
        return {
            x: event.clientX - offsetX,
            y: event.clientY - offsetY
        };
    }

    setOffsets(offset: Partial<IPosition>): void {
        this.options.offset = {
            x: offset.x || 0,
            y: offset.y || 0
        };
    }

    destroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

function normalizeSpeed(coordinate: number): number {
    return coordinate % 190 === 0 ? coordinate / 190 : coordinate / 19;
}

export function getMousewheelSpeed(event: WheelEvent): IMouseWheelSpeed {
    if (event.deltaMode === WheelEvent.DOM_DELTA_PIXEL) {
        return {
            x: normalizeSpeed(event.deltaX),
            y: normalizeSpeed(event.deltaY)
        };
    } else if (event.deltaMode === WheelEvent.DOM_DELTA_LINE) {
        return {
            x: normalizeSpeed(event.deltaX) * 35,
            y: normalizeSpeed(event.deltaY) * 35
        };
    }

    return {
        x: event.deltaX,
        y: event.deltaY
    };
}
