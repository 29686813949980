import { ITime } from '../../animation';
import { ImageLibraryAsset } from '../../brand/brand-library';
import { IDesign } from '../design';
import { CreativeSize } from '../size';
import { IVersion } from '../version';
import { ICreativeWeight } from './weight';

export interface ICreative {
    id: string;
    design?: IDesign;
    size: CreativeSize;
    version: Readonly<IVersion>;
    targetUrl?: string;
    checksum?: string;
    creativeWeights?: ICreativeWeight;
    approvalStatus: ApprovalStatus;
}

interface IReferenceObject {
    id: string;
}

/**
 * @deprecated Is being replaced by CreativeDtoV2
 */
export interface ICreativeDto {
    id: string;
    design?: IReferenceObject;
    size: IReferenceObject;
    version: IReferenceObject;
    targetUrl?: string;
    checksum?: string;
    creativeWeights?: ICreativeWeight;
    approvalStatus?: ApprovalStatus;
}

export interface ICreativesetUpdateResponse {
    designs: IDesign[];
    versions: IVersion[];
    images: ImageLibraryAsset[];
}

export type CreativeUpdate = Pick<ICreative, 'id' | 'targetUrl' | 'checksum'>;

export type CreativeCreate = Required<Pick<ICreative, 'id' | 'checksum'>>;

export enum ApprovalStatus {
    None = '',
    NoStatus = 'No status',
    NotApproved = 'Not approved',
    InProgress = 'In progress',
    ForReview = 'For review',
    Approved = 'Approved'
}

export enum CreativeType {
    Animated
}

export type PreloadImageFormat = 'jpg' | 'gif' | 'png';

export interface IPreloadImage {
    frames: number[];
    format: PreloadImageFormat;
    quality: number;
}

export interface IGifExport {
    frames: ITime[];
    show: boolean;
}
