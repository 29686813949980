export interface IUser {
    isEmployee?: boolean;
    permissions?: UserPermission[];
    role?: Role;
}

export enum Role {
    None = 0,
    User = 1,
    Admin = 2,
    Owner = 3,
    AccountAdmin = 4,
    ExternalUser = 5,
    TextEditor = 6,
    Designer = 7,
    TextEditorExtended = 8,
    UserWithoutAccessOthersContent = 9,
    Publisher = 10,
    Analyzer = 11,
    TextEditorWithAnalyzer = 12,
    PublisherExtended = 13
}

/**
 * Permissions defined in AccountManager on an account level
 */
export type UserPermission =
    | 'AccessOthersContent'
    | 'Actions'
    | 'AdvancedFormats'
    | 'AllowHeavyVideos'
    | 'Analytics'
    | 'AnalyticsDemoData'
    | 'Analyzer'
    | 'Approve'
    | 'BannerflowLibrary'
    | 'BannersetDoneMark'
    | 'BannerSetDuplicate'
    | 'Comments'
    | 'ConversionTracking'
    | 'CreativesetDoneMark'
    | 'CreativesetDuplicate'
    | 'Creator'
    | 'CustomAssets'
    | 'CustomBannerScript'
    | 'CustomCreativecript'
    | 'DeleteComments'
    | 'DirectPublishing'
    | 'DownloadButton'
    | 'DuplicateTo'
    | 'Editor'
    | 'EditorKeyFrames'
    | 'EmailMarketing'
    | 'ExternalTranslations'
    | 'Facebook'
    | 'FallbackSharpness'
    | 'Feeds'
    | 'FeedsAutomated'
    | 'FeedsLive'
    | 'ImpactVideos10Mb'
    | 'ImpactVideos25Mb'
    | 'LandingPage'
    | 'Lock'
    | 'Marketplace'
    | 'MediaBuyer'
    | 'MediaBuyerPublish'
    | 'MessageSystem'
    | 'MultipleBrands'
    | 'MultipleUsers'
    | 'OpenApi'
    | 'Publisher'
    | 'RequirePasswordForNewBrands'
    | 'Scheduling'
    | 'Settings'
    | 'Share'
    | 'SocialCampaignManager'
    | 'SocialDynamicAdvertising'
    | 'SocialMedia'
    | 'Store'
    | 'Studio'
    | 'StudioAutoTranslate'
    | 'StudioGenAiImage'
    | 'StudioTranslationPage'
    | 'StudioVideoLibrary'
    | 'TextEditor'
    | 'ThemeEnterprise'
    | 'TrackingPixels'
    | 'VideoConverter';
