import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IFontFamily } from '@domain/font-families';
import { Store } from '@ngrx/store';
import { ExternalFontFamilyDto } from '@studio/domain/api/font-manager.types';
import * as FontFamiliesActions from './font-families.actions';
import * as FontFamiliesSelectors from './font-families.selectors';

@Injectable({
    providedIn: 'root'
})
export class FontFamiliesService {
    private store = inject(Store);

    creativeSetFontFamilies$ = this.store.pipe(FontFamiliesSelectors.getCreativesetFontFamilies);
    externalFonts$ = this.store.select(FontFamiliesSelectors.getExternalFonts);
    fontFamilies$ = this.store.pipe(FontFamiliesSelectors.getFontFamiliesWhenLoaded);
    importedExternalFonts$ = this.store.select(FontFamiliesSelectors.getImportedExternalFonts);
    nonDeletedBrandFontFamilies$ = this.store.pipe(
        FontFamiliesSelectors.getNonDeletedBrandFontFamilies
    );
    visibleFontFamilies$ = this.store.pipe(FontFamiliesSelectors.getVisibleBrandFontFamilies);

    loaded$ = this.store.select(FontFamiliesSelectors.getFontFamiliesLoaded);
    error$ = this.store.select(FontFamiliesSelectors.getFontFamiliesError);

    fontManagerState$ = this.store.select(FontFamiliesSelectors.getFontManagerState);

    failedToLoadFontStyleIds = this.store.select(FontFamiliesSelectors.getFailedToLoadFontStyleIds);

    private fontManagerState = toSignal(this.fontManagerState$, { initialValue: 'loading' });
    isFontManagerDown = computed(() => this.fontManagerState() === 'failed');

    loadFontFamilies(): void {
        this.store.dispatch(FontFamiliesActions.loadFontFamiliesOfBrand());
    }

    setFontFamiliesOfCreativeSet(fontFamilies: IFontFamily[]): void {
        this.store.dispatch(FontFamiliesActions.setFontFamiliesOfCreativeSet({ fontFamilies }));
    }

    addFontFamilies(fontFamilies: IFontFamily[]): void {
        this.store.dispatch(FontFamiliesActions.addFontFamilies({ fontFamilies }));
    }

    searchExternalFonts(searchTerm: string): void {
        this.store.dispatch(FontFamiliesActions.searchExternalFonts({ searchTerm }));
    }

    resetExternalFonts(): void {
        this.store.dispatch(FontFamiliesActions.resetExternalFonts());
    }

    importExternalFonts(externalFontFamilies: ExternalFontFamilyDto[]): void {
        this.store.dispatch(FontFamiliesActions.importExternalFonts({ externalFontFamilies }));
    }

    loadFontByStyleId(fontStyleId: string): void {
        this.store.dispatch(FontFamiliesActions.FontByStyleId.load({ fontStyleId }));
    }
}
