import { inject, Injectable } from '@angular/core';
import { EnvironmentService } from '@studio/common/services/environment.service';

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerService {
    private environmentService = inject(EnvironmentService);

    constructor() {
        const gtmId = this.environmentService.appEnvironment.gtmId;
        if (!gtmId) {
            return;
        }

        (function (w, d, l, i): void {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            const f = d.getElementsByTagName('script')[0];
            const j = d.createElement('script');
            const dl = l !== 'dataLayer' ? `&l=${l}` : '';
            j.async = true;
            j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
            f.parentNode!.insertBefore(j, f);
        })(window, document, 'dataLayer', gtmId);
    }
}
