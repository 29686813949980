import { inject, Injectable } from '@angular/core';
import { ApprovalStatus } from '@domain/creativeset/creative';
import { IVersion } from '@domain/creativeset/version';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { CreativesetDataService } from '@studio/common';
import { ISelectableSizeCollections } from '@studio/domain/state';
import { sortByFormatSize } from '@studio/utils/utils';
import { map, merge } from 'rxjs';
import * as VersionSelectors from '../../versions/state/versions.selectors';
import * as FiltersActions from './filters.actions';
import * as FiltersSelectors from './filters.selectors';

@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    private creativesetDataService = inject(CreativesetDataService);
    private store = inject(Store);

    filtersState$ = this.store.select(FiltersSelectors.getFiltersState);

    sizes$ = this.store.select(FiltersSelectors.getSizesFilter);
    statuses$ = this.store.select(FiltersSelectors.getStatusesFilter);
    allSizes$ = this.store.select(FiltersSelectors.getAllSizes);
    selectedSizes$ = this.store.select(FiltersSelectors.getSelectedSizes);

    filterableSizes$ = this.sizes$.pipe(
        concatLatestFrom(() =>
            this.creativesetDataService.sizes$.pipe(map(sizes => sortByFormatSize(sizes)))
        ),
        map(([selectedSizes, sizes]) => {
            return sizes.map(size => ({
                ...size,
                selected: selectedSizes.includes(size.id)
            }));
        })
    );

    filterableSizeCollections$ = this.store.select(FiltersSelectors.getFilterableSizeCollections);

    selectedCreativeSizes$ = this.sizes$.pipe(
        concatLatestFrom(() => this.creativesetDataService.sizes$),
        map(([sizesIds, sizes]) => sizes.filter(size => sizesIds.includes(size.id)))
    );

    hasActiveFilters$ = this.store.select(FiltersSelectors.getHasActiveFilters);
    hasSizesFilter$ = this.store.select(FiltersSelectors.getHasSizesFilter);
    hasStatusesFilter$ = this.store.select(FiltersSelectors.getHasStatusesFilter);

    isFilteringAllSizes$ = this.store.select(FiltersSelectors.getIsFilteringAllSizes);
    isFilteringAnySize$ = this.store.select(FiltersSelectors.getIsFilteringAnySize);

    toggleAllTooltipText$ = this.store.select(FiltersSelectors.getToggleAllTooltipText);

    selectedVersionIds$ = this.store.select(FiltersSelectors.getSelectedVersionsIds);
    isShowingMultipleVersions$ = this.store.select(VersionSelectors.getIsShowingMultipleVersions);
    isShowingAllVersions$ = this.store.select(VersionSelectors.getIsShowingAllVersions);

    selectableCollections$ = this.store.select(FiltersSelectors.getSelectableCollections);
    selectedCollections$ = this.store.select(FiltersSelectors.getSelectedCollections);

    filteredCreatives$ = merge(this.selectedSizes$, this.selectedVersionIds$).pipe(
        concatLatestFrom(() => [
            this.creativesetDataService.creativeset$,
            this.selectedSizes$,
            this.selectedVersionIds$
        ]),
        map(([_, creativeset, selectedSizes, selectedVersionIds]) => {
            return creativeset.creatives.filter(creative => {
                return (
                    selectedSizes.includes(creative.size.id) &&
                    selectedVersionIds.includes(creative.version.id)
                );
            });
        })
    );

    initFilters(sizeIds: string[]): void {
        this.store.dispatch(FiltersActions.init({ sizeIds }));
    }

    setAllSizes(sizeIds: string[]): void {
        this.store.dispatch(FiltersActions.setAllSizes({ sizeIds }));
    }

    // Sizes
    addFilteredSizes(sizeIds: string[]): void {
        this.store.dispatch(FiltersActions.addSizesToFilter({ sizeIds }));
    }

    removeFilteredSizes(sizeIds: string[]): void {
        this.store.dispatch(FiltersActions.removeSizesFromFilter({ sizeIds }));
    }

    setSizeFilter(sizeIds: string[]): void {
        this.store.dispatch(FiltersActions.setSizesFilter({ sizeIds }));
    }

    clearSizesFilter(): void {
        this.store.dispatch(FiltersActions.clearAllSizesFilter());
    }

    toggleSizesFilter(): void {
        this.store.dispatch(FiltersActions.toggleAllSizesFilter());
    }

    toggleSelectedSizes(): void {
        this.store.dispatch(FiltersActions.toggleSelectedSizes());
    }

    selectSize(sizeId: string): void {
        this.store.dispatch(FiltersActions.sizeActions.selectSize({ sizeId }));
    }

    deselectSize(sizeId: string): void {
        this.store.dispatch(FiltersActions.sizeActions.deselectSize({ sizeId }));
    }

    // Status
    addFilteredStatuses(statuses: ApprovalStatus[]): void {
        this.store.dispatch(FiltersActions.addStatusesToFilter({ statuses }));
    }

    removeFilteredStatuses(statuses: ApprovalStatus | ApprovalStatus[]): void {
        this.store.dispatch(
            FiltersActions.removeStatusesFromFilter({
                statuses: Array.isArray(statuses) ? statuses : [statuses]
            })
        );
    }

    clearAllStatuses(): void {
        this.store.dispatch(FiltersActions.clearAllStatusesFilter());
    }

    // Version

    selectVersionById(versionId: string, updateOldSelection = false): void {
        this.store.dispatch(
            FiltersActions.setVersionsFilter({ versionIds: [versionId], updateOldSelection })
        );
    }

    selectVersion(version: IVersion, multiSelect = false): void {
        if (multiSelect) {
            this.store.dispatch(FiltersActions.addVersionsToFilter({ versionIds: [version.id] }));
        } else {
            this.selectVersionById(version.id);
        }
    }

    deselectVersion(version: IVersion): void {
        this.store.dispatch(FiltersActions.removeVersionsFromFilter({ versionIds: [version.id] }));
    }

    selectAllVersions(): void {
        this.store.dispatch(FiltersActions.selectAllVersions());
    }

    selectPreviousVersionSelection(): void {
        this.store.dispatch(FiltersActions.selectPreviousVersionSelection());
    }

    updatePreviousVersionSelection(): void {
        this.store.dispatch(FiltersActions.updatePreviousVersionSelection());
    }

    // Context menu actions
    hideOthers(): void {
        this.store.dispatch(FiltersActions.hideOtherSizes());
    }

    hideSelected(): void {
        this.store.dispatch(FiltersActions.hideSelectedSizes());
    }

    // select collections
    setSelectedCollections(selectedCollections: ISelectableSizeCollections[]): void {
        this.store.dispatch(
            FiltersActions.sizeCollectionActions.setSelectedCollections({ selectedCollections })
        );
    }

    selectCollection(collectionId: string): void {
        this.store.dispatch(FiltersActions.sizeCollectionActions.selectCollection({ collectionId }));
    }

    deselectCollection(collectionId: string): void {
        this.store.dispatch(FiltersActions.sizeCollectionActions.deselectCollection({ collectionId }));
    }

    selectAllCollections(): void {
        this.store.dispatch(FiltersActions.sizeCollectionActions.selectAllCollections());
    }

    deselectAllCollections(): void {
        this.store.dispatch(FiltersActions.sizeCollectionActions.deselectAllCollections());
    }
}
