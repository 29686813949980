import { LibraryKind } from '@domain/media-library';
import { IFontValidationState } from '@studio/domain/font-validation';
import { IVideoValidationState } from '@studio/domain/video-validation';

export interface IManageViewSettings {
    isPlaying: boolean;
    showcase?: IShowcaseSettins;
}

interface IShowcaseSettins {
    email?: string;
}

export type IManageViewSettingValue = IManageViewSettings[keyof IManageViewSettings];

export interface ITranslationPageSettings {
    layout: 'left' | 'right';
    tabs: { [creativesetId: string]: 'content' | 'styling' };
    showAllElements: boolean;
}

export interface IDesignViewSettings {
    isLibraryPinned: boolean;
    libraryKind: LibraryKind;
    selectedFeedId: string | 'none';
    dimOutsideCanvas: boolean;
    snapping: boolean;
    outlineVisible: boolean;
    guidelineVisible: boolean;
    guidelineColor: string;
    displayGrid: boolean;
    grid?: { width: number; height: number };
    gridColor: string;
    timelineHeight: number;
    animation: IDesignViewAnimationSettings;
}

export type IDesignViewSettingValue = IDesignViewSettings[keyof IDesignViewSettings];

export interface IDesignViewAnimationSettings {
    usePixelValues: { [key: string]: boolean };
    useDefaultAnimations: boolean;
}

export type KeyOfAnimationSettings = IDesignViewAnimationSettings[keyof IDesignViewAnimationSettings];

export interface IIgnoredWarnings {
    fontValidationWarnings: IFontValidationState[];
    videoValidationWarnings: IVideoValidationState[];
}

export type IIgnoredWarningValue = IIgnoredWarnings[keyof IIgnoredWarnings];

export type LastLocation = 'ManageView' | 'TranslationPage' | 'DesignView' | 'none';

export interface ISharedSettings {
    lastLocation: LastLocation;
}

export type ISharedSettingValue = ISharedSettings[keyof ISharedSettings];
