import { Provider } from '@angular/core';
import { provideAuth0 } from '@auth0/auth0-angular';
import { IAppEnvironment } from '@domain/environment';

const STUDIO_SCOPE =
    'email offline_access profile openid studio design fontmanager bannerflowlibrary campaignservice commentservice videoconverter listservice bannerlingoservice';

export function provideAuth(
    auth0Config: IAppEnvironment['auth0'],
    origins: IAppEnvironment['origins']
): Provider[] {
    const NO_AUTH_ORIGINS = [origins.studioBlobStorage];

    const allowedOrigins = Object.values(origins)
        .filter(origin => !NO_AUTH_ORIGINS.includes(origin))
        .map(origin => `${origin}/*`);

    return [
        provideAuth0({
            domain: auth0Config.domain,
            clientId: auth0Config.clientId,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/${window.location.search}`,
                audience: 'https://bannerflow.com/resources/',
                scope: STUDIO_SCOPE
            },
            httpInterceptor: {
                allowedList: allowedOrigins
            },
            useRefreshTokens: true
        })
    ];
}
