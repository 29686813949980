import { OneOfStateDtos as DapiOneOfStatesDto, OneOfStateDtos } from '@domain/api/design-api.interop';
import { serializableStateProperties } from '@domain/property';
import { OneOfStatesDto } from '@domain/serialization';
import { IState } from '@domain/state';
import { decimal } from '@studio/utils/utils';
import { parseColor } from '../color.utils';
import { isFormulaValue } from '../rendering/formula.utils';
import {
    convertBorderToDto,
    convertFiltersToDto,
    convertRadiusToDto,
    convertShadowsToDto,
    deserializeBorder,
    deserializeDapiFilter,
    deserializeFilter,
    deserializeRadius,
    deserializeShadows,
    serializeDapiFilter
} from './property-serializer';
import { serializeNumberOrFormula } from './serialization.utils';
import { GenericStateDto, TextStateDto } from '@domain/api/generated/design-api';

export function convertStateToDto(state: IState): OneOfStatesDto {
    const stateDto: OneOfStatesDto = { id: state.id! };
    for (const property of serializableStateProperties) {
        if (state[property] === undefined) {
            continue;
        }
        switch (property) {
            case 'border':
                if (state[property]) {
                    stateDto[property] = convertBorderToDto(state[property]);
                }
                break;
            case 'fill':
            case 'textColor':
                stateDto[property] = state[property].toString();
                break;
            case 'filters':
                stateDto[property] = convertFiltersToDto(state[property]);
                break;
            case 'mirrorX':
            case 'mirrorY':
                stateDto[property] = state[property];
                break;
            case 'name':
                stateDto[property] = state[property];
                break;
            case 'opacity':
            case 'originX':
            case 'originY':
            case 'scaleX':
            case 'scaleY':
                stateDto[property] = serializeNumberOrFormula(state[property], property);
                break;
            case 'radius':
                stateDto[property] = convertRadiusToDto(state[property]);
                break;
            case 'ratio':
                stateDto[property] = decimal(state[property]);
                break;
            case 'rotationX':
            case 'rotationY':
            case 'rotationZ':
            case 'x':
            case 'y':
                stateDto[property] = serializeNumberOrFormula(state[property], property);
                break;
            case 'shadows':
                stateDto[property] = convertShadowsToDto(state[property]);
                break;
        }
    }

    return stateDto;
}

export function deserializeState(stateDto: OneOfStatesDto): IState {
    const state: IState = { id: stateDto.id };

    for (const property of serializableStateProperties) {
        if (stateDto[property] === undefined) {
            continue;
        }

        switch (property) {
            case 'border':
                if (stateDto[property]) {
                    state[property] = deserializeBorder(stateDto[property]);
                }
                break;
            case 'fill':
            case 'textColor':
                state[property] = parseColor(stateDto[property]);
                break;
            case 'filters':
                state[property] = deserializeFilter(stateDto[property]);
                break;
            case 'mirrorX':
            case 'mirrorY':
                state[property] = stateDto[property];
                break;
            case 'name':
                state[property] = stateDto[property];
                break;
            case 'opacity':
            case 'originX':
            case 'originY':
            case 'scaleX':
            case 'scaleY':
            case 'ratio':
                state[property] = stateDto[property];
                break;
            case 'radius':
                state[property] = deserializeRadius(stateDto[property]);
                break;
            case 'rotationX':
            case 'rotationY':
            case 'rotationZ':
            case 'x':
            case 'y':
                state[property] = isFormulaValue(stateDto[property])
                    ? stateDto[property]
                    : Number(stateDto[property]);
                break;
            case 'shadows':
                state[property] = deserializeShadows(stateDto[property]);
                break;
        }
    }

    return state;
}
export function deserializeDapiState(stateDto: DapiOneOfStatesDto): IState {
    const state: IState = { id: stateDto.id };

    for (const property of serializableStateProperties) {
        if (stateDto[property] === undefined) {
            continue;
        }

        switch (property) {
            case 'border':
                if (stateDto[property]) {
                    state[property] = deserializeBorder(stateDto[property]);
                }
                break;
            case 'fill':
            case 'textColor':
                state[property] = parseColor(stateDto[property]);
                break;
            case 'filters':
                state[property] = deserializeDapiFilter(stateDto[property]);
                break;
            case 'mirrorX':
            case 'mirrorY':
                state[property] = stateDto[property];
                break;
            case 'name':
                state[property] = stateDto[property];
                break;
            case 'opacity':
            case 'originX':
            case 'originY':
            case 'scaleX':
            case 'scaleY':
            case 'ratio':
                state[property] = stateDto[property];
                break;
            case 'radius':
                state[property] = deserializeRadius(stateDto[property]);
                break;
            case 'rotationX':
            case 'rotationY':
            case 'rotationZ':
            case 'x':
            case 'y':
                state[property] = isFormulaValue(stateDto[property])
                    ? stateDto[property]
                    : Number(stateDto[property]);
                break;
            case 'shadows':
                state[property] = deserializeShadows(stateDto[property]);
                break;
        }
    }

    return state;
}

export function convertGenericStateToDapiDto(state: IState): GenericStateDto {
    const stateDto: OneOfStateDtos = { $type: 'GenericStateDto', id: state.id! };
    for (const property of serializableStateProperties) {
        if (state[property] === undefined) {
            continue;
        }
        switch (property) {
            case 'border':
                if (state[property]) {
                    stateDto[property] = convertBorderToDto(state[property]);
                }
                break;
            case 'fill':
                stateDto[property] = state[property].toString();
                break;
            case 'filters':
                stateDto[property] = serializeDapiFilter(state[property]);
                break;
            case 'mirrorX':
            case 'mirrorY':
                stateDto[property] = state[property];
                break;
            case 'name':
                stateDto[property] = state[property];
                break;
            case 'opacity':
            case 'originX':
            case 'originY':
            case 'scaleX':
            case 'scaleY':
                stateDto[property] = serializeNumberOrFormula(state[property], property);
                break;
            case 'radius':
                stateDto[property] = convertRadiusToDto(state[property]);
                break;
            case 'ratio':
                stateDto[property] = decimal(state[property]);
                break;
            case 'rotationX':
            case 'rotationY':
            case 'rotationZ':
            case 'x':
            case 'y':
                stateDto[property] = serializeNumberOrFormula(state[property], property).toString();
                break;
            case 'shadows':
                stateDto[property] = convertShadowsToDto(state[property]);
                break;
        }
    }

    return stateDto;
}

export function convertTextStateToDapiDto(state: IState): TextStateDto {
    const stateDto: OneOfStateDtos = { $type: 'TextStateDto', id: state.id! };
    for (const property of serializableStateProperties) {
        if (state[property] === undefined) {
            continue;
        }

        if (property === 'textColor') {
            stateDto[property] = state[property].toString();
        }
    }

    return { ...convertGenericStateToDapiDto(state), ...stateDto };
}
