import { inject, Injectable } from '@angular/core';
import { getLibraryKindFromElementKind, isWidgetElement } from '@creative/nodes';
import { ElementKind } from '@domain/elements';
import { LibraryKind } from '@domain/media-library';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { UserSettingsService } from '@studio/common/user-settings';
import { of, switchMap, tap } from 'rxjs';
import { isEffectLibraryElement } from '../../../pages/design-view/media-library/media-library.helpers';
import { BrandLibraryDataService } from '../brand-library.data.service';
import * as MediaLibraryActions from './media-library.actions';
import { MediaLibraryService } from './media-library.service';

@Injectable()
export class MediaLibraryEffects {
    private actions$ = inject(Actions);
    private mediaLibraryService = inject(MediaLibraryService);
    private userSettingsService = inject(UserSettingsService);
    private brandLibraryService = inject(BrandLibraryDataService);

    toggleMediaLibrary$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MediaLibraryActions.toggle),
            concatLatestFrom(() => [this.mediaLibraryService.isOpen$, this.mediaLibraryService.kind$]),
            switchMap(([action, isOpen, currentKind]) => {
                if (isOpen && currentKind === action.kind) {
                    return of(
                        MediaLibraryActions.close({
                            force: false,
                            isEditingElement: action.isEditingElement
                        })
                    );
                } else {
                    return of(MediaLibraryActions.open({ kind: action.kind }));
                }
            })
        );
    });

    closeMediaLibrary$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MediaLibraryActions.close),
            switchMap(action => {
                return of(
                    MediaLibraryActions.closeSuccess({
                        force: action.force,
                        isEditingBrandLibraryElementName: action.isEditingElement
                    })
                );
            })
        );
    });

    init$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MediaLibraryActions.init),
            concatLatestFrom(() => [
                this.userSettingsService.isLibraryPinned$,
                this.userSettingsService.selectedFeedId$,
                this.userSettingsService.libraryKind$
            ]),
            switchMap(([_, storedIsPinned, storedSelectedFeedId, storedKind]) => {
                const isPinned = storedIsPinned;
                const selectedFeedId: string = storedSelectedFeedId;
                const kind = isPinned ? storedKind : LibraryKind.Selection;
                return of(
                    MediaLibraryActions.initSuccess({
                        kind,
                        selectedFeedId,
                        isPinned
                    })
                );
            })
        );
    });

    togglePinned$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MediaLibraryActions.togglePinned),
            concatLatestFrom(() => this.mediaLibraryService.mediaLibraryState$),
            switchMap(([_, state]) => {
                const newToggleState = !state.isPinned;
                if (newToggleState) {
                    this.userSettingsService.setDesignViewSetting('libraryKind', state.kind);
                    this.userSettingsService.setDesignViewSetting(
                        'selectedFeedId',
                        state.selectedFeedId
                    );
                    return of(MediaLibraryActions.pin());
                } else {
                    return of(MediaLibraryActions.unpin());
                }
            })
        );
    });

    filterByElement$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MediaLibraryActions.filterLibraryByElement),
            switchMap(({ node }) => {
                const brandLibraryElement = this.brandLibraryService.getElementByDataNode(node);

                if (!brandLibraryElement) {
                    return of(
                        MediaLibraryActions.search({
                            searchQuery: undefined
                        })
                    );
                }

                const kind = isWidgetElement(brandLibraryElement)
                    ? ElementKind.Widget
                    : brandLibraryElement.type;
                const libraryKind = isEffectLibraryElement(brandLibraryElement)
                    ? LibraryKind.Effects
                    : getLibraryKindFromElementKind(kind);

                this.mediaLibraryService.openMediaLibrary(libraryKind);

                return of(
                    MediaLibraryActions.search({
                        searchQuery: brandLibraryElement.name
                    })
                );
            })
        );
    });

    onPin$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(MediaLibraryActions.pin),
                tap(() => this.userSettingsService.setDesignViewSetting('isLibraryPinned', true))
            );
        },
        { dispatch: false }
    );

    onUnpin$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(MediaLibraryActions.unpin),
                tap(() => this.userSettingsService.setDesignViewSetting('isLibraryPinned', false))
            );
        },
        { dispatch: false }
    );

    onOpen$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(MediaLibraryActions.open),
                tap(({ kind }) => this.userSettingsService.setDesignViewSetting('libraryKind', kind))
            );
        },
        { dispatch: false }
    );

    onSetSelectedFeedId$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(MediaLibraryActions.setSelectedFeedId),
                tap(({ selectedFeedId }) =>
                    this.userSettingsService.setDesignViewSetting('selectedFeedId', selectedFeedId)
                )
            );
        },
        { dispatch: false }
    );
}
