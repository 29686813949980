import { inject, Injectable } from '@angular/core';
import { getSizeIdsFromCreatives, getVersionIdsFromCreatives } from '@creative/utils';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CreativesetDataService } from '@studio/common/creativeSet/creativeset.data.service';
import {
    AllowedOperations,
    ICreativesetShowcase,
    ICreativesetShowcaseCreateData
} from '@studio/domain/showcase';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TileSelectService } from '../../../pages/manage-view/services/tile-select.service';
import { QRCodeService } from '../../services/qr-code.service';
import { creativeSetShowcaseActions } from './creativeset-showcase.actions';
import * as CreativesetShowcaseSelectors from './creativeset-showcase.selectors';

@Injectable({
    providedIn: 'root'
})
export class CreativeSetShowcaseService {
    private actions = inject(Actions);
    private creativesetDataService = inject(CreativesetDataService);
    private qrCodeService = inject(QRCodeService);
    private store = inject(Store);
    private tileSelectService = inject(TileSelectService);

    loading$ = this.store.select(CreativesetShowcaseSelectors.getShowcasesLoading);
    error$ = this.store.select(CreativesetShowcaseSelectors.getShowcasesError);
    showcases$ = this.store.select(CreativesetShowcaseSelectors.getShowcases);
    createdShowcase$ = this.store.select(CreativesetShowcaseSelectors.getCreatedShowcase);
    translators$ = this.store.select(CreativesetShowcaseSelectors.getShowcasesTranslators);
    users$ = this.store.select(CreativesetShowcaseSelectors.getShowcasesUsers);
    showcaseToken$ = this.store.select(CreativesetShowcaseSelectors.getShowcaseToken);
    allowedOperations$ = this.store.select(CreativesetShowcaseSelectors.getAllowedOperations);

    showcaseDeleted$ = this.actions.pipe(
        ofType(creativeSetShowcaseActions.deleteCreativeSetShowcaseSuccess)
    );
    showcaseCreativesetResponse$ = this.actions.pipe(
        ofType(creativeSetShowcaseActions.loadShowcaseCreativeSetSuccess)
    );
    translationCompleted$ = this.actions.pipe(
        ofType(creativeSetShowcaseActions.completeTranslationSuccess)
    );

    temporaryShowcaseLinkLoading$ = this.store.select(
        CreativesetShowcaseSelectors.getTemporaryShowcaseLinkLoading
    );
    temporaryShowcaseLink$ = this.store
        .select(CreativesetShowcaseSelectors.getTemporaryShowcaseLink)
        .pipe(filter(link => !!link)); // used for the QR code modal

    private allowedOperations: AllowedOperations[];

    constructor() {
        this.allowedOperations$.subscribe(allowedOperations => {
            this.allowedOperations = allowedOperations;
        });
    }

    loadShowcaseCreativeset(key: string): void {
        this.store.dispatch(creativeSetShowcaseActions.loadShowcaseCreativeSet({ key }));
    }

    loadShowcasesOfCreativeset(): void {
        this.store.dispatch(
            creativeSetShowcaseActions.loadCreativeSetShowcases({
                creativesetId: this.creativesetDataService.creativeset.id
            })
        );
    }

    createShowcaseLink(showcase: ICreativesetShowcaseCreateData): void {
        this.store.dispatch(creativeSetShowcaseActions.createCreativeSetShowcase({ showcase }));
    }

    createTemporaryShowcaseLink(versions: string[] = [], sizes: string[] = []): void {
        const showcase: ICreativesetShowcaseCreateData = {
            allowedOperations: [],
            creativesetId: this.creativesetDataService.creativeset.id,
            invitationMessage: '',
            invitations: [],
            sizeIds: sizes,
            versionIds: versions,
            isTemporary: true
        };
        this.createShowcaseLink(showcase);
    }

    updateShowcaseLink(changes: Partial<ICreativesetShowcase>, showcaseKey: string): void {
        this.store.dispatch(
            creativeSetShowcaseActions.updateCreativeSetShowcase({ changes, showcaseKey })
        );
    }

    deleteShowcaseLink(showcaseKey: string): void {
        this.store.dispatch(creativeSetShowcaseActions.deleteCreativeSetShowcase({ showcaseKey }));
    }

    resetCreatedShowcase(): void {
        this.store.dispatch(creativeSetShowcaseActions.resetCreatedShowcase());
    }

    completeTranslation(creativesetId: string, showcaseKey: string): void {
        this.store.dispatch(
            creativeSetShowcaseActions.completeTranslation({ creativesetId, showcaseKey })
        );
    }

    operationsAllowed(operations: AllowedOperations[]): boolean {
        return operations.every(operation => this.allowedOperations.includes(operation));
    }

    async openQRLinkModal(): Promise<void> {
        const selectedCreatives = await firstValueFrom(this.tileSelectService.selection$);
        const selectedVersions = getVersionIdsFromCreatives(selectedCreatives);
        const selectedSizes = getSizeIdsFromCreatives(selectedCreatives);

        this.createTemporaryShowcaseLink(selectedVersions, selectedSizes);

        const link = await firstValueFrom(this.temporaryShowcaseLink$);

        this.qrCodeService.openQRCodeModal(link!);
    }

    openShowcaseLinkAsQR(): void {
        const link = window.location.href;
        this.qrCodeService.openQRCodeModal(link, true);
    }
}
