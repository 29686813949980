import { inject, Injectable } from '@angular/core';
import { ImageLibraryAsset } from '@domain/brand/brand-library/image-asset';
import { IImageElementAsset } from '@domain/creativeset/element-asset';
import { Store } from '@ngrx/store';
import { GenAIOption, SaveType } from '@studio/domain/components/ai-studio.types';
import { BrushHistory } from '@studio/domain/components/gen-ai/inpaint.types';
import { OutpaintSettings } from '@studio/domain/components/gen-ai/outpaint.types';
import { from, switchMap } from 'rxjs';
import * as GenAIActions from './gen-ai.actions';
import * as GenAISelectors from './gen-ai.selectors';

@Injectable({
    providedIn: 'root'
})
export class GenAIService {
    private store = inject(Store);

    loaded$ = this.store.select(GenAISelectors.getLoaded);
    error$ = this.store.select(GenAISelectors.getError);

    currentImage$ = this.store.select(GenAISelectors.getCurrentImage);
    currentImageAsBase64$ = this.store
        .select(GenAISelectors.getCurrentImageAsBase64)
        .pipe(switchMap(_ => _));

    previousImage$ = this.store.select(GenAISelectors.getPreviousImage);

    currentImageSize$ = this.store
        .select(GenAISelectors.getCurrentImageSize)
        .pipe(switchMap(promise => from(promise)));

    zoomLevel$ = this.store.select(GenAISelectors.getZoomLevel);

    generativeFillBrushSize$ = this.store.select(GenAISelectors.getGenerativeFillBrushSize);
    generativeFillBrushHistory$ = this.store.select(GenAISelectors.getGenerativeFillBrushHistory);
    generativeFillImageMask$ = this.store
        .select(GenAISelectors.getGenerativeFillImageMask)
        .pipe(switchMap(_ => _));

    eraseBrushSize$ = this.store.select(GenAISelectors.getEraseBrushSize);
    eraseBrushHistory$ = this.store.select(GenAISelectors.getEraseBrushHistory);
    eraseImageMask$ = this.store.select(GenAISelectors.getEraseImageMask).pipe(switchMap(_ => _));

    imageUrl$ = this.store.select(GenAISelectors.getImageUrl);

    backgroundRemoved$ = this.store.select(GenAISelectors.getBackgroundRemoved);
    showOriginalBackground$ = this.store.select(GenAISelectors.getShowOriginalBackground);

    currentImageAsset$ = this.store.select(GenAISelectors.getCurrentImageAsset);
    currentOptionSelected$ = this.store.select(GenAISelectors.getCurrentOptionSelected);

    openedElementId$ = this.store.select(GenAISelectors.getOpenedElementId);
    openedElementParentFolderId$ = this.store.select(GenAISelectors.getOpenedElementParentFolderId);

    assetName$ = this.store.select(GenAISelectors.getAssetName);

    outpaintSettings$ = this.store.select(GenAISelectors.getOutpaintSettings);

    // CurrentRequest
    isRequestingAnything$ = this.store.select(GenAISelectors.getIsRequestingAnything);
    isRequestingBackgroundRemoval$ = this.store.select(GenAISelectors.getIsRequestingBackgroundRemoval);
    isRequestingSaveToBrandLibrary$ = this.store.select(
        GenAISelectors.getIsRequestingSaveToBrandLibrary
    );
    isRequestingOpenInAIStudio$ = this.store.select(GenAISelectors.getIsRequestingOpenInAIStudio);
    isRequestingGeneration$ = this.store.select(GenAISelectors.getIsRequestingGeneration);
    isRequestingInpaint$ = this.store.select(GenAISelectors.getIsRequestingInpaint);
    isRequestingErase$ = this.store.select(GenAISelectors.getIsRequestingErase);
    isRequestingSearchAndReplace$ = this.store.select(GenAISelectors.getIsRequestingSearchAndReplace);
    isRequestingOutpaint$ = this.store.select(GenAISelectors.getIsRequestingOutpaint);
    isRequestingSketch$ = this.store.select(GenAISelectors.getIsRequestingSketch);
    isRequestingStructure$ = this.store.select(GenAISelectors.getIsRequestingStructure);
    isRequestingStyle$ = this.store.select(GenAISelectors.getIsRequestingStyle);
    isRequestingUpscale$ = this.store.select(GenAISelectors.getIsRequestingUpscale);
    isRequestingBrandLibrarySave$ = this.store.select(GenAISelectors.getIsRequestingBrandLibrarySave);
    isRequestingBrandLibraryDuplicate$ = this.store.select(
        GenAISelectors.getIsRequestingBrandLibraryDuplicate
    );
    isRequestingOnCanvasSaveAndReplace$ = this.store.select(
        GenAISelectors.getIsRequestingOnCanvasSaveAndReplace
    );
    isRequestingOnCanvasSaveAsDuplicate$ = this.store.select(
        GenAISelectors.getIsRequestingOnCanvasSaveAsDuplicate
    );

    // History
    history$ = this.store.select(GenAISelectors.getHistory);
    hasUnsavedChanges$ = this.store.select(GenAISelectors.getHasUnsavedChanges);

    canUndo$ = this.store.select(GenAISelectors.getHistoryCanUndo);
    canRedo$ = this.store.select(GenAISelectors.getHistoryCanRedo);

    saveOnCanvasPayload$ = this.store.select(GenAISelectors.getSaveOnCanvasPayload);

    openElementInAIStudio(
        elementId: string,
        elementName: string | undefined,
        elementParentFolderId?: string,
        asset?: ImageLibraryAsset | IImageElementAsset
    ): void {
        this.store.dispatch(
            GenAIActions.openElementInAIStudio({ elementId, elementName, asset, elementParentFolderId })
        );
    }

    resetState(): void {
        this.store.dispatch(GenAIActions.resetState());
    }

    generateImage(prompt: string): void {
        this.store.dispatch(GenAIActions.generateImage({ prompt }));
    }

    inpaint(prompt: string, negativePrompt?: string): void {
        this.store.dispatch(GenAIActions.inpaint({ prompt, negativePrompt }));
    }

    erase(): void {
        this.store.dispatch(GenAIActions.erase());
    }

    searchAndReplace(searchPrompt: string, replacePrompt: string): void {
        this.store.dispatch(GenAIActions.searchAndReplace({ searchPrompt, replacePrompt }));
    }

    outpaint(
        settings: OutpaintSettings,
        prompt: string | undefined,
        negativePrompt: string | undefined
    ): void {
        this.store.dispatch(GenAIActions.outpaint({ settings, prompt, negativePrompt }));
    }

    sketch(prompt: string, negativePrompt?: string, controlStrength?: number): void {
        this.store.dispatch(GenAIActions.sketch({ prompt, negativePrompt, controlStrength }));
    }

    structure(prompt: string, negativePrompt?: string, controlStrength?: number): void {
        this.store.dispatch(GenAIActions.structure({ prompt, negativePrompt, controlStrength }));
    }

    style(prompt: string, fidelity: number, negativePrompt?: string): void {
        this.store.dispatch(GenAIActions.style({ prompt, negativePrompt, fidelity }));
    }

    upscale(): void {
        this.store.dispatch(GenAIActions.upscale());
    }

    removeBackground(): void {
        this.store.dispatch(GenAIActions.removeBackground());
    }

    zoomIn(): void {
        this.store.dispatch(GenAIActions.zoomIn());
    }

    zoomOut(): void {
        this.store.dispatch(GenAIActions.zoomOut());
    }

    setZoom(zoomLevel: number): void {
        this.store.dispatch(GenAIActions.setZoomLevel({ zoomLevel }));
    }

    toggleOriginalBackground(show: boolean): void {
        this.store.dispatch(GenAIActions.toggleOriginalBackground({ show }));
    }

    saveGeneratedImageToBrandLibrary(fileName: string, parentFolderId: string | undefined): void {
        this.store.dispatch(
            GenAIActions.saveGeneratedImageToBrandLibrary({ fileName, parentFolderId })
        );
    }

    openInAiStudio(fileName: string, parentFolderId: string | undefined): void {
        this.store.dispatch(GenAIActions.openInAiStudio({ fileName, parentFolderId }));
    }

    selectOption(option: GenAIOption): void {
        this.store.dispatch(GenAIActions.changeSelectedOption({ option }));
    }

    setGenerativeFillBrushSize(brushSize: number): void {
        this.store.dispatch(GenAIActions.setGenerativeFillBrushSize({ brushSize }));
    }

    setGenerativeFillBrushHistory(brushHistory: BrushHistory): void {
        this.store.dispatch(GenAIActions.setGenerativeFillBrushHistory({ brushHistory }));
    }

    setGenerativeFillImageMask(generativeFillImageMaskLink: string): void {
        this.store.dispatch(GenAIActions.setGenerativeFillImageMask({ generativeFillImageMaskLink }));
    }

    setEraseBrushSize(brushSize: number): void {
        this.store.dispatch(GenAIActions.setEraseBrushSize({ brushSize }));
    }

    setEraseBrushHistory(brushHistory: BrushHistory): void {
        this.store.dispatch(GenAIActions.setEraseBrushHistory({ brushHistory }));
    }

    setEraseImageMask(eraseImageMaskLink: string): void {
        this.store.dispatch(GenAIActions.setEraseImageMask({ eraseImageMaskLink }));
    }

    closeAIStudio(): void {
        this.store.dispatch(GenAIActions.closeAIStudio());
    }

    outpaintSettingsUpdated(outpaintSettings: OutpaintSettings): void {
        this.store.dispatch(GenAIActions.setOutpaintSettigns({ options: outpaintSettings }));
    }

    saveToBrandLibrary(replace: boolean): void {
        this.store.dispatch(GenAIActions.saveToBrandLibrary({ replace }));
    }

    saveOnCanvas(saveType: SaveType, replaceInAllDesigns?: boolean): void {
        this.store.dispatch(GenAIActions.saveOnCanvas({ saveType, replaceInAllDesigns }));
    }

    updateAssetName(newName: string): void {
        this.store.dispatch(GenAIActions.updateAssetName({ newName }));
    }

    redo(): void {
        this.store.dispatch(GenAIActions.redo());
    }

    undo(): void {
        this.store.dispatch(GenAIActions.undo());
    }
}
