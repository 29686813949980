import { CreativeUpdate, ICreative } from '@domain/creativeset/creative/creative';
import { IDesign, ISerializedDesign } from '@domain/creativeset/design';
import { IElement } from '@domain/creativeset/element';
import { CreativeSize } from '@domain/creativeset/size';
import { ISerializedVersion, IVersion } from '@domain/creativeset/version';
import { ICreativeDataNode } from '@domain/nodes';

export interface ICreateDesignInput {
    id?: string;
    name: string;
    document: ICreativeDataNode;
    elements: IElement[];
    hasHeavyVideo: boolean;
}

export interface ICreateDesignInputType {
    size: CreativeSize;
    design: ICreateDesignInput;
    versions: IVersion[];
}

export interface IUpdateDesignInputType {
    size: CreativeSize;
    design: IDesign;
    versions: IVersion[];
}

export type OneOfDesignInputTypes = ICreateDesignInputType | IUpdateDesignInputType;

export interface IPreparedDesigns {
    dirtyCreatives: ICreative[];
    creatives: CreativeUpdate[];
    designs: ISerializedDesign[];
    versions: ISerializedVersion[];
}
