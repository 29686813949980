import { IVersionedText, IVersionProperty } from '@domain/creativeset/version';
import { validateAndFixVersionedFeed } from './versionedFeed-semantic-validation';
import { IFeed } from '@domain/feed';
import { validateAndFixVersionedText } from './versionedText-semantic-validation';

export function fixFeedVersionProperties(
    versionProperty: IVersionProperty<IFeed>,
    sentinelService?: { trackPageAction: (message: string, data: unknown) => void }
): void {
    try {
        const fixedValue = validateAndFixVersionedFeed(versionProperty.value);
        versionProperty.value = fixedValue;
    } catch (error) {
        if (error instanceof Error) {
            sentinelService?.trackPageAction('VersionedFeedSemanticValidationFail', {
                versionPropertyId: versionProperty.id,
                value: JSON.stringify(versionProperty.value),
                errorMessage: error.message
            });
        }
    }
}

export function fixTextVersionProperty(
    versionProperty: IVersionProperty<IVersionedText>,
    sentinelService?: { trackPageAction: (message: string, data: unknown) => void }
): void {
    try {
        const fixedValue = validateAndFixVersionedText(versionProperty.value);
        versionProperty.value = fixedValue;
    } catch (error) {
        if (error instanceof Error) {
            sentinelService?.trackPageAction('VersionedTextSemanticValidationFail', {
                versionPropertyId: versionProperty.id,
                value: JSON.stringify(versionProperty.value),
                errorMessage: error.message
            });
        }
    }
}
