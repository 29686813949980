import { CreativeSetFontFamily, IFontFamily, IFontFamilyStyle } from '@domain/font-families';
import { OneOfElementDataNodes, OneOfTextDataNodes } from '@domain/nodes';
import { IWidgetElementDataNode } from '@domain/widget';
import { cloneDeep } from '@studio/utils/clone';
import { isTextNode, isWidgetNode } from './nodes/helpers';
import { deserializeCreativeSetFont } from '@data/deserialization/font-families';
import { IFontStyle } from '@domain/font';

export function getFontByStyleId(
    fontFamilies: IFontFamily[] | Readonly<IFontFamily[]>,
    fontStyleId: string
): IFontFamily | undefined {
    for (const fontFamily of fontFamilies) {
        if (fontFamily.fontStyles.find(({ id }) => id === fontStyleId)) {
            return fontFamily;
        }
    }
    throw new Error(`Could not find font style with id '${fontStyleId}'.`);
}

export function getFontStyleById(
    fontFamilies: IFontFamily[] | Readonly<IFontFamily[]>,
    fontStyleId: string
): IFontFamilyStyle | undefined {
    for (const fontFamily of fontFamilies) {
        for (const fontStyle of fontFamily.fontStyles) {
            if (fontStyle.id === fontStyleId) {
                return fontStyle;
            }
        }
    }
    throw new Error(`Could not find font style with id '${fontStyleId}'.`);
}

export function tryGetFontByStyleId(
    fontFamilies: IFontFamily[] | Readonly<IFontFamily[]>,
    fontStyleId: string
): IFontFamily | undefined {
    for (const fontFamily of fontFamilies) {
        if (fontFamily.fontStyles.find(({ id }) => id === fontStyleId)) {
            return fontFamily;
        }
    }
}

export function tryGetFontStyleById(
    fontFamilies: IFontFamily[] | Readonly<IFontFamily[]>,
    fontStyleId: string
): IFontFamilyStyle | undefined {
    for (const fontFamily of fontFamilies) {
        for (const fontStyle of fontFamily.fontStyles) {
            if (fontStyle.id === fontStyleId) {
                return fontStyle;
            }
        }
    }
}

export function getActiveFontsOnThisBrand(
    fontFamilies: IFontFamily[] | Readonly<IFontFamily[]>,
    brandId: string
): IFontFamily[] {
    return fontFamilies
        .filter(
            fontFamily =>
                !fontFamily.deletedAt &&
                (!fontFamily.visibleBrandIds || fontFamily.visibleBrandIds.includes(brandId))
        )
        .map(fontFamily => ({
            ...fontFamily,
            fontStyles: fontFamily.fontStyles.filter(fontStyle => !fontStyle.deletedAt && fontStyle)
        }));
}

export function removeDeletedFonts(fontFamilies: IFontFamily[]): IFontFamily[] {
    const filteredFontFamilies: IFontFamily[] = [];
    for (const fontFamily of fontFamilies) {
        if (fontFamily.deletedAt) {
            continue;
        }
        filteredFontFamilies.push({
            ...fontFamily,
            fontStyles: fontFamily.fontStyles.filter(fonStyle => !fonStyle.deletedAt)
        });
    }
    return filteredFontFamilies;
}

export function mergeFontFamilies(oldData: IFontFamily[], newData: IFontFamily[]): IFontFamily[] {
    const output = cloneDeep(oldData || []);
    for (const fontFamily of newData) {
        const isDuplicated = fontFamily.fontStyles.every(({ id }) =>
            output.some(({ fontStyles }) => fontStyles.some(fontStyle => fontStyle.id === id))
        );

        if (isDuplicated) {
            continue;
        }
        const oldFontFamily = output.find(
            ({ fontStyles, id }) =>
                id === fontFamily.id ||
                fontStyles.some(({ id: fontStyleId }) =>
                    fontFamily.fontStyles.find(fontStyle => fontStyle.id === fontStyleId)
                )
        );
        if (oldFontFamily) {
            // Merge fontStyles with known fontFamily
            oldFontFamily.fontStyles = [
                ...oldFontFamily.fontStyles,
                ...fontFamily.fontStyles.filter(({ id }) =>
                    oldFontFamily.fontStyles.every(fontStyle => fontStyle.id !== id)
                )
            ];
            continue;
        }
        output.push({
            ...fontFamily
        });
    }
    return output;
}

/** Returns unique font style IDs from element nodes, including character styles. Optionally includes widget font IDs. */
export function getFontStyleIdsFromElements(
    nodes: OneOfElementDataNodes[],
    includeWidgetFonts = false
): string[] {
    const fontIds = new Set<string>();

    for (const node of nodes) {
        if (isTextNode(node)) {
            getTextNodeFontIds(node).forEach(id => fontIds.add(id));
        } else if (includeWidgetFonts && isWidgetNode(node)) {
            getWidgetCustomPropertyFontIds(node).forEach(id => fontIds.add(id));
        }
    }

    return Array.from(fontIds).filter(id => id.trim().length > 0);
}

function getTextNodeFontIds(node: OneOfTextDataNodes): string[] {
    const fontIds: string[] = [];

    if (node.font) {
        fontIds.push(node.font.id);
    }

    if (node.__fontStyleId) {
        fontIds.push(node.__fontStyleId);
    }

    node.characterStyles.forEach(charStyle => {
        const charFontStyleId = charStyle.font;
        if (typeof charFontStyleId === 'string') {
            fontIds.push(charFontStyleId);
        } else if (charFontStyleId?.id) {
            fontIds.push(charFontStyleId.id);
        }
    });

    return fontIds;
}

function getWidgetCustomPropertyFontIds(node: IWidgetElementDataNode): string[] {
    const fontIds: string[] = [];

    node.customProperties?.forEach(prop => {
        if (prop.unit === 'font' && prop.value) {
            let fontId: string | undefined;
            const value = prop.value;

            if (typeof value === 'string') {
                fontId = value;
            } else if (typeof value === 'object' && 'id' in value) {
                fontId = value.id;
            }

            if (fontId) {
                fontIds.push(fontId);
            }
        }
    });

    return fontIds;
}

export function sortFontFamilies(fontFamilies: IFontFamily[]): IFontFamily[] {
    return fontFamilies.sort((a, b) => a.name.localeCompare(b.name));
}

export function getTextNodeFontFromCreativeSetFonts(
    fonts: CreativeSetFontFamily[],
    fontStyleId: string
): IFontStyle | undefined {
    const mappedFonts = deserializeCreativeSetFont(fonts);

    const fontFamilyStyle = mappedFonts
        .flatMap(fontFamily => fontFamily.fontStyles)
        .find(fontStyle => fontStyle.id === fontStyleId);

    if (!fontFamilyStyle) {
        return;
    }

    const fontStyle: IFontStyle = {
        id: fontFamilyStyle.id,
        weight: fontFamilyStyle.weight,
        style: fontFamilyStyle.italic ? 'italic' : 'normal',
        src: fontFamilyStyle.fontUrl,
        fontFamilyId: fontFamilyStyle.fontFamilyId || ''
    };

    return fontStyle;
}
