import { Injectable } from '@angular/core';
import { CreativeSize } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative';
import { IVersion } from '@domain/creativeset/version';
import { concatLatestFrom } from '@ngrx/operators';
import { CreativesetDataService } from '@studio/common';
import { mapSizeIdsToSizes, sortByFormatSize } from '@studio/utils/utils';
import { BehaviorSubject, Observable, Subject, firstValueFrom, map } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FiltersState } from '../../../shared/filters/state/filters.reducer';
import { FiltersService } from '../../../shared/filters/state/filters.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { filterCreatives } from '../utils/filter-creatives.utils';

@Injectable({ providedIn: 'root' })
export class TileSelectService {
    private _selection$ = new BehaviorSubject<ICreative[]>([]);
    selection$ = this._selection$.asObservable();

    isAllSelected$: Observable<boolean>;

    private _currentlyViewedSize$ = new Subject<string>();
    currentlyViewedSize$ = this._currentlyViewedSize$.asObservable();

    private _creativeListScrolled$ = new Subject<Event>();
    creativeListScrolled$ = this._creativeListScrolled$.asObservable().pipe(debounceTime(500));

    private selectedCreatives: ICreative[] = [];
    sortedGroupCreatives: GroupedSizeCreatives[] = [];
    sortedCreatives: ICreative[] = [];

    private sizes$: Observable<CreativeSize[]>;

    private selectAllCreatives$ = new Subject<void>();

    constructor(
        private creativesetDataService: CreativesetDataService,
        private filtersService: FiltersService,
        private versionsService: VersionsService
    ) {
        this.isAllSelected$ = this.filtersService.filtersState$.pipe(
            concatLatestFrom(() => this.versionsService.selectedVersions$),
            map(([filtersState, selectedVersions]) => {
                const creatives = this.getSelectableCreatives(filtersState, selectedVersions);
                return creatives.length === this.selectedCreatives.length;
            })
        );

        this.selectAllCreatives$
            .pipe(
                concatLatestFrom(() => [
                    this.filtersService.filtersState$,
                    this.versionsService.selectedVersions$
                ])
            )
            .subscribe(([_, filtersState, selectedVersions]) => {
                const creatives = this.getSelectableCreatives(filtersState, selectedVersions);
                this.set(...creatives);
            });
    }

    async setCurrentlyViewedSize(currentSizeId: string): Promise<void> {
        if (currentSizeId.length === 0) {
            this.sizes$ = this.filtersService.selectedSizes$.pipe(
                map(sizes =>
                    sortByFormatSize(
                        mapSizeIdsToSizes(sizes, [...this.creativesetDataService.creativeset.sizes]),
                        'size'
                    )
                )
            );
            const allActiveSizes = await firstValueFrom(this.sizes$);
            currentSizeId = allActiveSizes[0].id;
        }
        const currentSize = this.creativesetDataService.creativeset.sizes.find(
            size => size.id === currentSizeId
        );

        if (!currentSize) {
            console.error('Size not found', currentSizeId);
            return;
        }
        this._currentlyViewedSize$.next(currentSize.id);
    }

    deselectAllCreatives(): void {
        this.clear();
    }

    selectAllCreatives(): void {
        this.selectAllCreatives$.next();
    }

    add(...creatives: ICreative[]): void {
        let selectionChanged = false;
        creatives.forEach(creative => {
            if (
                !this.selectedCreatives.length ||
                this.selectedCreatives.every(sc => sc.id !== creative.id)
            ) {
                this.selectedCreatives.push(creative);
                selectionChanged = true;
            }
        });
        if (!selectionChanged) {
            return;
        }
        this.emitSelection();
    }

    set(...creatives: ICreative[]): void {
        this.clear(true);
        this.add(...creatives);
    }

    remove(...creatives: ICreative[]): void {
        const ln = this.selectedCreatives.length;
        this.selectedCreatives = this.selectedCreatives.filter(
            sc => !creatives.some(creative => creative.id === sc.id)
        );

        if (ln === this.selectedCreatives.length) {
            return;
        }
        this.emitSelection();
    }

    clear(skipEmission?: boolean): void {
        this.selectedCreatives = [];
        if (!skipEmission) {
            this.emitSelection();
        }
    }

    private isSelected(creative: ICreative): boolean {
        return this.selectedCreatives.some(sc => sc.id === creative.id);
    }

    select($event: MouseEvent, creative: ICreative, forceCmd = false): void {
        if ($event.button === 2) {
            if (this.selectedCreatives.length <= 1 || !this.isSelected(creative)) {
                this.set(creative);
            } else {
                this.add(creative);
            }
        } else if (forceCmd || $event.ctrlKey || $event.metaKey) {
            this.cmdSelect(creative);
        } else if ($event.shiftKey) {
            this.cmdSelect(creative);
            this.shiftSelect(creative);
        } else {
            this.set(creative);
        }
    }

    private emitSelection(): void {
        this._selection$.next([...this.selectedCreatives]);
    }

    private shiftSelect(creative: ICreative): void {
        const creatives = this.sortedGroupCreatives.length
            ? this.sortedGroupCreatives.reduce(
                  (acc: ICreative[], curr) => [...acc, ...curr.creatives],
                  []
              )
            : this.sortedCreatives;

        const lastSelectedIndex = creatives.indexOf(this.getSelected()[0]);
        const currentIndex = creatives.indexOf(creative);
        const creativesToSelect: ICreative[] = [];
        if (lastSelectedIndex >= 0 && currentIndex >= 0 && lastSelectedIndex !== currentIndex) {
            for (
                let i = Math.min(lastSelectedIndex, currentIndex);
                i < Math.max(lastSelectedIndex, currentIndex);
                i++
            ) {
                creativesToSelect.push(creatives[i]);
            }
        }

        this.add(...creativesToSelect);
        this.emitSelection();
    }

    private cmdSelect(creative: ICreative): void {
        if (this.isSelected(creative)) {
            this.remove(creative);
        } else {
            this.add(creative);
        }
    }

    getSelected(): ICreative[] {
        return this.selectedCreatives;
    }

    private getSelectableCreatives(
        { sizes, statuses }: FiltersState,
        selectedVersions: IVersion[]
    ): ICreative[] {
        let selectableCreatives = [...this.creativesetDataService.creativeset.creatives];
        if (selectedVersions.length > 0) {
            selectableCreatives = selectableCreatives.filter(creative =>
                selectedVersions.some(version => version.id === creative.version.id)
            );
        }
        return filterCreatives(selectableCreatives, sizes, statuses);
    }

    creativeListScrolled(event: Event): void {
        this._creativeListScrolled$.next(event);
    }
}

export type GroupedSizeCreatives = {
    size: CreativeSize;
    creatives: ICreative[];
    collapsed?: boolean;
    id: string;
};
