import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CreativesetDataService } from '@studio/common';
import { EnvironmentService } from '@studio/common/services/environment.service';
import { merge, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { EditCreativeService } from '../../../pages/manage-view/services/edit-creative.service';
import { VersionsService } from '../../versions/state/versions.service';
import * as AnimationControlActions from './animation-control.actions';

@Injectable()
export class AnimationControlsEffects {
    private actions$ = inject(Actions);
    private creativesetDataService = inject(CreativesetDataService);
    private editCreativeService = inject(EditCreativeService);
    private environmentService = inject(EnvironmentService);
    private versionsService = inject(VersionsService);

    pauseAnimations$ = createEffect(() => {
        return this.versionsService.selectedVersions$.pipe(
            filter(() => !this.environmentService.inShowcaseMode),
            switchMap(() => of(AnimationControlActions.controlActions.stopAnimations()))
        );
    });

    disableAnimations$ = createEffect(() => {
        return merge(
            this.actions$.pipe(ofType(AnimationControlActions.initActions.initDisabledAnimationsState)),
            this.editCreativeService.activation.change$,
            this.editCreativeService.deactivation.change$,
            this.editCreativeService.deletion.change$
        ).pipe(
            map(() => this.areAllCreativesWithoutDesign()),
            switchMap(isDisabled =>
                of(AnimationControlActions.controlActions.disableAnimations({ isDisabled }))
            )
        );
    });

    private areAllCreativesWithoutDesign(): boolean {
        return [...this.creativesetDataService.creativeset.creatives].every(
            creative => !creative.design
        );
    }
}
