import { IVideoSettings, VideoSizeMode } from '@domain/video';
import { parseColor } from '../../color.utils';

export const DEFAULT_VIDEO_QUALITY = 95;

export const DEFAULT_VIDEO_SETTINGS: IVideoSettings = {
    optimization: {
        enabled: false,
        quality: DEFAULT_VIDEO_QUALITY
    },
    streaming: {
        enabled: false
    },
    autoplay: true,
    loop: false,
    restartWithCreative: true,
    startTime: 0,
    endTime: 0,
    playbackRate: 1,
    stopWithCreative: true,
    sizeMode: VideoSizeMode.Fit,
    volume: 0.5,
    playbackButton: {
        enabled: false,
        size: 50,
        color: parseColor('rgba(255, 255, 255, 0.25)')
    }
};
