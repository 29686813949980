import { InjectionToken, Provider } from '@angular/core';
import { IAppEnvironment } from '@domain/environment';

export const APP_ENV = new InjectionToken<IAppEnvironment>('EnvironmentToken');

export function provideEnvironment(environment: IAppEnvironment): Provider[] {
    return [
        {
            provide: APP_ENV,
            useValue: environment
        }
    ];
}
