import { inject, Injectable } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { Logger } from '@bannerflow/sentinel-logger';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as BrandActions from '../brand/';
import { AuthService } from '../services/auth.service';
import * as UserActions from './user.actions';
import { UserDataService } from './user.data.service';

@Injectable()
export class UserEffects {
    private actions$ = inject(Actions);
    private authSerivce = inject(AuthService);
    private logger = new Logger(UserEffects.name);
    private sentinelService = inject(SentinelService);
    private userDataService = inject(UserDataService);

    loadUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BrandActions.loadActions.loadBrandSuccess),
            map(action => action.brand.accountSlug),
            withLatestFrom(this.authSerivce.getAuthUser()),
            switchMap(([accountSlug, authUser]) =>
                this.userDataService.getUser(accountSlug).pipe(
                    map(user => {
                        this.sentinelService.setUser(authUser.userId, accountSlug);
                        this.logger.debug(user.permissions);
                        return UserActions.loadActions.loadUserSuccess({ user });
                    }),
                    catchError(error => of(UserActions.loadActions.loadUserFailure({ error })))
                )
            )
        );
    });
}
