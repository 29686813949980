import { convertElementKindToUnionType } from '@domain/api/api-type-conversions';
import {
    UpdateCreativeDtoV2,
    UpdateDesignDto,
    UpdateElementDto,
    UpdateElementPropertyDto,
    PersistentFontFamilyDto,
    UpdateVersionDto
} from '@domain/api/generated/sapi';
import { ICreative } from '@domain/creativeset/creative';
import { IDesign, IElement, IElementProperty } from '@domain/creativeset';
import { IFontFamily } from '@domain/font-families';
import { IVersion } from '@domain/creativeset/version';
import { omit } from '@studio/utils/utils';
import { convertCreativeToDocumentDto } from './document-serializer';
import { serializeVersionProperties } from './versions/version-serializer';

export function toUpdateCreativeDtoV2s(creatives: ICreative[]): UpdateCreativeDtoV2[] {
    return creatives.map(
        ({ id, checksum }) =>
            ({
                id,
                checksum: checksum!
            }) satisfies UpdateCreativeDtoV2
    );
}
export function toUpdateElementDtos(elements: IElement[]): UpdateElementDto[] {
    return elements.map(
        element =>
            ({
                id: element.id,
                name: element.name,
                type: convertElementKindToUnionType(element.type),
                properties: toUpdateElementPropDtos(element.properties)
            }) satisfies UpdateElementDto
    );
}

export function toUpdateElementPropDtos(properties: IElementProperty[]): UpdateElementPropertyDto[] {
    return properties.map(property => {
        const updateProperty = {
            id: property.id,
            name: property.name,
            value: property.value,
            unit: property.unit,
            versionPropertyId: property.versionPropertyId,
            clientId: property.clientId,
            label: property.label
        } satisfies UpdateElementPropertyDto;

        // Don't include the id prop with empty string
        return updateProperty.id ? updateProperty : omit(updateProperty, 'id');
    });
}

export function toUpdateVersionDtos(versions: IVersion[]): UpdateVersionDto[] {
    return versions.map(
        ({ id, properties }) =>
            ({
                id,
                properties: serializeVersionProperties(properties, false)
            }) satisfies UpdateVersionDto
    );
}

export function toUpdateDesignDtos(designs: IDesign[]): UpdateDesignDto[] {
    return designs.map(
        ({ id, document }) =>
            ({
                id,
                document: convertCreativeToDocumentDto(document, true)
            }) satisfies UpdateDesignDto
    );
}

export function toUpdateFontFamilyDtos(fontFamilies: IFontFamily[]): PersistentFontFamilyDto[] {
    return fontFamilies.map(
        ({ name, fontStyles }) =>
            ({
                name,
                fontStyles: fontStyles.map(style => ({
                    id: style.id,
                    name: style.name,
                    fontUrl: style.fontUrl
                }))
            }) satisfies PersistentFontFamilyDto
    );
}
