import { Injectable, inject } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { Logger } from '@bannerflow/sentinel-logger';
import { ILogger } from '@domain/logger';
import {
    StudioActivityEvent,
    StudioCaptureEvent,
    StudioConsoleEvent,
    StudioEvent,
    StudioTrackerEvent
} from '@studio/domain/monitoring/event-logger.interface';
import { ActivityLoggerService } from '../activity-logger.service';

@Injectable({ providedIn: 'root' })
export class EventLoggerService {
    private fallbackLogger = new Logger();
    private loggedEvents = new Set<string>();
    private sentinelService = inject(SentinelService);
    private activityService = inject(ActivityLoggerService);

    public log(event: StudioEvent, logger?: ILogger): void {
        if (event.unique) {
            const uniqueIdentifier = `${event.constructor.name}-${event?.uniqueIdentifier?.() || ''}`;
            if (this.loggedEvents.has(uniqueIdentifier)) {
                return;
            }
            this.loggedEvents.add(uniqueIdentifier);
        }

        if (isActivityEvent(event)) {
            this.handleActivityEvent(event);
        }

        if (isTrackingEvent(event)) {
            this.handleTrackingEvent(event);
        }

        if (isCaptureEvent(event)) {
            this.handleCaptureEvent(event);
        }

        if (isConsoleEvent(event)) {
            this.handleConsoleEvent(event, logger);
        }
    }

    private handleTrackingEvent(event: StudioTrackerEvent): void {
        if (event.start === true) {
            if (event.data) {
                this.sentinelService.startTrace(event.message, event.data);
            } else {
                this.sentinelService.startTrace(event.message);
            }
        } else if (event.start === false) {
            if (event.data) {
                this.sentinelService.finishTrace(event.message, event.data);
            } else {
                this.sentinelService.finishTrace(event.message);
            }
        } else {
            this.sentinelService.trackPageAction(event.message, event.data);
        }
    }

    private handleConsoleEvent(event: StudioConsoleEvent, logger?: ILogger): void {
        const loggerInstance = logger ?? this.fallbackLogger;
        const logLevel = event.level ?? 'log';
        loggerInstance[logLevel](event.description);
    }

    private handleCaptureEvent(event: StudioCaptureEvent): void {
        this.sentinelService.error(event, {
            ...event.data,
            severity: event.severity,
            description: event.description
        });
    }

    private handleActivityEvent(event: StudioActivityEvent): void {
        this.activityService.log(event.description, event.data);
    }
}

function isConsoleEvent(event: StudioEvent): event is StudioConsoleEvent {
    return !!event.log;
}

function isActivityEvent(event: StudioEvent): event is StudioActivityEvent {
    return !!event.activity;
}

function isTrackingEvent(event: StudioEvent): event is StudioTrackerEvent {
    return !!event.track;
}

function isCaptureEvent(event: StudioEvent): event is StudioCaptureEvent {
    return !!event.capture;
}
